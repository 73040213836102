import { SERVICE } from "../constants";

export const getServiceName = () => {
  const hash = window.location.hash;
  const serviceName = hash.split('/')[1]?.toLowerCase();
  
  if(Object.values(SERVICE).includes(serviceName)) {
    return serviceName;
  }

  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match = [];
  while ((match = regex.exec(hash))) {
    if(match[1] === 'service') {
      return match[2];
    }
  }
  return ''
}
