import { useDispatch } from 'react-redux';
import { getRedirectionResult } from '../actions';

export const useRedirectionalResult = () => {
  const dispatch = useDispatch();
  return (headers, payload) => {
    return dispatch(getRedirectionResult(headers, payload))
      .then((res) => {
        return { isLoading: false, error: null, response: res.payload.data.data };
      })
      .catch((err) => {
        return { isLoading: false, error: err, response: {} };
      });
  };
};
