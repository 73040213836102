import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import * as spinnerLottieImage from '../../assets/lottie/lf20_N3BXE6.json';

import PropTypes from 'prop-types';
import { LoaderWrapper } from './style';

export const Loader = (props) => {
  const spinnerConfig = {
    loop: true,
    autoplay: true,
    animationData: spinnerLottieImage.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const loaderData = useSelector((state) => state.loaderData);
  // const { options = spinnerConfig, width = 120, height = 120, theme = 'light' } = props;
  return (
    loaderData.isLoaderVisible && (
      <LoaderWrapper bg={'#191919'}>
        <div>
          <Lottie options={loaderData.options} width={loaderData.width} height={loaderData.width} />
        </div>
      </LoaderWrapper>
    )
  );
};

Loader.propTypes = {
  options: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  theme: PropTypes.string,
};
