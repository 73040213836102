/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import '../../styles/_scrollbar.scss';
import { Cross } from '../Icons';

export const BottomPopupModal = (props) => {
  const { component, onClose, backDropStatic, height, width, innerClass, crossBtn, preventBack=false, className } = props;
  const [isActive, setIsActive] = useState(true);
  
  const onCloseModal = (isBrowserBack) => {
    console.log({isBrowserBack})
    if(!isBrowserBack && !preventBack) {
      window.history.back();
    } else {
      setIsActive(false);
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(()=>{
    if(preventBack) {
      return;
    }
    window.history.pushState({source: 'modalOpen'}, null, window.location.href)
    const handlePopstate = (e) => {
      e.preventDefault();
      console.log('am i popped')
      onCloseModal(true);
    }
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    }
  }, [])


  useEffect(() => {
    document.body.setAttribute('style', 'height: 100vh; overflow-y: hidden;');

    const newPayments = document.getElementById('newPayments');
    const listScreen = document.getElementById('listScreen');

    if(newPayments) {
      newPayments.setAttribute('style', 'height: 100%; overflow: hidden;');
    }
    if(listScreen) {
      listScreen.setAttribute('style', 'height: 100%; overflow: hidden;');
    }
    return () => {
      document.body.setAttribute('style', '');
     if (newPayments) {
       newPayments.setAttribute('style', '');
     }
     if (listScreen) {
       listScreen.setAttribute('style', '');
     } 
    }
  }, []);

  return (
    <div
      onClick={backDropStatic ? () => {} : () => onCloseModal(false)}
      className={`bottom_modal ${isActive ? '' : 'bottom_modal__deactive'} ${className}`}
    >
      <div
        className={`bottom_modal__inner scroll-class ${innerClass || ''}`}
        style={{ width: `${width}` }}
        onClick={e => e.stopPropagation()}
      >
        {crossBtn && (
          <div onClick={() => onCloseModal(false)} className="bottom_modal__crossbutton">
            <Cross height="16" width="16" />
          </div>
        )}
        <div className="bottom_modal__inner__content" style={{ height: `${height}px` }}>
          {component}
        </div>
      </div>
    </div>
  );
};

BottomPopupModal.propTypes = {
  component: PropTypes.any,
  onClose: PropTypes.func,
  backDropStatic: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.string,
  innerClass: PropTypes.string,
  crossBtn: PropTypes.bool,
  preventBack: PropTypes.bool,
};
