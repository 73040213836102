import DoubleTick from '../assets/icons/double-checked.png';
import YellowTick from '../assets/icons/yellow_tick.png';
import GreenDoubleTick from '../assets/icons/green_double_tick.svg';
import WynkLogo from '../assets/music-screens/Wynklogo-white.svg';
import { svg } from '../Pages/NewPayments/static/assets';

export const PLATFORM = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'webos',
  MWEBOS: 'mwebos',
  TIZENOS: 'tizenos',
  LGOS: 'lgos',
  ROKUOS: 'rokuos',
  TVOS: 'tvos',
};

export const MOBILE_STATUS = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  CANCEL: 'CANCEL',
};

export const SERVICE = {
  AIRTEL_TV: 'airteltv',
  MUSIC: 'music',
  RAJ_TV: 'rajtv',
  ENTERR10: 'enterr10',
  IQ_VIDEO: 'iqvideo',
};

export const SOURCE = {
  ALTERNATE_BILLING_CHOICE_PAGE: 'Alternate Billing Choice Page',
  PAYMENT_OPTION_PAGE: 'Payment Options Page',
  SUBSCRIPTION_PAGE: 'Subscription Details Page',
  MANAGE_SUBSCRIPTION_PAGE: 'Manage Subscription Details Page',
  SETTING_PAGE: 'Setting Page',
  SUCCESS_PAGE: 'Success Page',
  FAILURE_PAGE: 'Failure Page',
  API_ERROR_SCREEN: 'Api Error Screen',
  WYNK_ACTIVE_SUBSCRIPTION_PAGE: 'Wynk Active Subscription Page',
};

export const EVENT = {
  PAGE_LOAD: 'page_load',
  ERROR: 'error',
  ERROR_RELOAD: 'error_reload',
  ERROR_EXIT: 'error_exit',
  PAYMENT_PAGE_LOAD: 'payment_page_load',
  SELECT_PLAN: 'select_plan',
  OFFER_SELECTION: 'offer_selection',
  CONTINUE_TO_PAY: 'continue_to_pay',
  PAGE_BACK: 'page_back',
  PRIVACY_POLICY: 'privacy_policy',
  TOS: 'terms_of_service',
  PAY_BUTTON: 'pay_button_click',
  APPLY_OFFER: 'apply_offer',
  PAYMENT_METHOD: 'payment_method',
  AUTO_RENEW: 'auto_renew',
  SELECT_RENEW: 'select_renew',
  PAYMENT_SUCCESS: 'payment_success',
  EXTEND_NOW: 'extend_now',
  FMF_CONTINUE_TO_PLANS: 'fmf_continue_to_plans',
  UPGRADE_NOW: 'upgrade_now',
  VPA_ENTER: 'vpa_enter',
  DETAILS: 'view_details_click',
  CANCEL_PLAN: 'cancel_subs_click',
  MANAGE_SUBS: 'manage_subs',
  CONFIRM_CANCEL_CLICK: 'confirm_cancellation',
  CLOSE_CANCEL_CLICK: 'close_cancel_click',
  ACTIVE_PLAN_OVERFLOW_CLICK: 'active_plan_overflow_click',
  PRE_CANCELLATION_MODAL_CLOSE: 'pre_cancel_modal_close',
  CANCELLATION_DROP_BENEFITS: 'cancellation_drop_benefits',
  CANCELLATION_DROP_DETAILS: 'cancellation_drop_details',
  CANCELLATION_DROP_REASONS: 'cancellation_drop_reasons',
  CANCELLATION_CONTINUE_TO_REASONS: 'cancellation_continue_to_reasons',
  CANCELLATION_CONTINUE_TO_BENEFITS: 'cancellation_continue_to_benefits',
  CANCELLATION_GOT_IT: 'cancellation_got_it',
  POPUP_OPEN: 'popup_open',
  CONFIRM_CANCEL_OK_CLICK: 'confirm_cancel_ok_click',
  FAILURE_CANCEL_OK_CLICK: 'failure_cancel_ok_click',
  FAILURE_RETRY: 'failure_retry_click',
  ERROR_TRY_AGAIN: 'error_try_again',
  COMBINE_API_FAILURE: 'combine_api_failure',
  FIND_UPI_CLICK: 'find_upi_click',
  UCB_SHEET_DISPLAY: 'ucb_sheet_display',
  PAYMENT_PAGE_DISPLAY: 'payment_page_display',
  SLIDER_CLICK: 'slider_click',
  SLIDER_SWIPE: 'slider_swipe',

  // promo code

  ADD_PROMO_CODE: 'PC_Add_button',
  CANCEL_PROMO_CODE: 'PC_cancel',
  ENTER_PROMO_CODE: 'PC_code_enter',
  APPLY_PROMO_CODE: 'PC_apply_click',
  PROMO_SUCCESS: 'PC_code_success',
  PROMO_ERROR: 'PC_code_error',
  REMOVE_PC: 'PC_remove_code',
  REMOVE_PC_SUCCESS: 'PC_remove_success',
  REMOVE_PC_ERROR: 'PC_remove_error',
  NOT_ELIGIBLE: 'PC_code_error',

  // UPI

  INVALID_VPA: 'Invalid_VPA',
  VALID_VPA: 'Valid_VPA',
  PAYMENT_API_FAIL: 'Payment_API_Fail',
  PAYMENT_API_SUCCESS: 'Payment_API_Success',
  PAYMENT_API_INITIATED: 'Payment_API_Initiated',
  PAYMENT_API_FAIL_CTA_CLICK: 'PaymentAPI_Fail_CTA_Click',
  ELIGIBLE_LINK: 'EligibleUsers_toLink',
  ELIGIBLE_REDIRECTION: 'EligibleUsers_toAuto',
  SEE_ALL_UPI: 'see_all_upi',

  // Wallet

  WALLET_OPEN: 'linked_wallet_select',
  WALLET_CLOSE: 'linked_wallet_deselect',
  WALLET_NUMBER_CLICK: 'PhoneNo_field_click',
  WALLET_REDIRECTION: 'wallet_redirection',
  WALLET_OTP_ENTER: 'OTP_field_click',
  WALLET_OTP_SUBMIT: 'OTP_submit',
  PHONE_NUMBER_CANCEL: 'number_cancel',
  WALLET_LINK_SUCCESS: 'wallet_link_success',
  WALLET_LINK_FAILED: 'wallet_link_failed',
  OTP_CANCEL: 'otp_cancel',
  ADD_MONEY_CLICK: 'AddMoney_click',
  ADD_MONEY_SUCCESS: 'AddMoney_success',
  ADD_MONEY_FAIL: 'AddMoney_failure',
  DIRECT_DEBIT: 'Pay_click',
  INFO_FETCH: 'InfoFetch',
  IOS_PROMO_CODE_CLICK: 'iOS_PC_click',
  PHONEPE_CLICK: 'PP_click',
  PHONEPE_OPEN: 'pp_click_open',
  PHONEPE_CLOSE: 'pp_click_close',
  PHONEPE_NUMBER_CLICK: 'PP_PhoneNo_click',
  PHONEPE_OTP_ENTER: 'PP_OTP_enter',
  PHONEPE_OTP_SUBMIT: 'PP_OTP_submit',

  // NET BANKING
  NB_SEE_ALL: 'see_all_net_banking',

  // autopay
  AUTOPAY_CLICKED: 'autopay_toggle_button_clicked',

  // de-link
  DELINK_CLICK: 'Delink_click',
  DELINK_CONFIRM: 'Delink_confirm',
  DELINK_CANCEL: 'Delink_cancel',
  DELINK_SUCCESS: 'Delink_Success',
  DELINK_FAILURE: 'Delink_failure',

  VERIFY_BIN: 'Verify_Bin',

  DOWNLOAD_INVOICE_CLICKED: 'DOWNLOAD_INVOICE_CLICKED',
  INVOICE_DOWNLOAD_SUCCESS: 'INVOICE_DOWNLOAD_SUCCESS',
  INVOICE_DOWNLOAD_FAILED: 'INVOICE_DOWNLOAD_FAILED',

  GPB_SUBS_MANAGEMENT_REDIRECTION: 'GPB_SUBS_MANAGEMENT_REDIRECTION',
  GPB_SUBS_MANAGEMENT_REDIRECTION_FAILURE: 'GPB_SUBS_MANAGEMENT_REDIRECTION_FAILURE',

  GO_BACK_CTA_CLICK: 'GO_BACK_CTA_CLICK',
  GET_PREMIUM_CTA_CLICK: 'GET_PREMIUM_CTA_CLICK',
  BACK_FROM_PLANS_POPUP: 'BACK_FROM_PLANS_POPUP',
};

export const PLAN_TYPE = {
  FREE: 'FREE',
  PAID: 'ONE_TIME_SUBSCRIPTION',
  SUBSCRIPTION: 'SUBSCRIPTION',
  FREE_TRIAL: 'FREE_TRIAL',
};

export const OFFER_TYPE = {
  WYNK_HT: 'wynk_ht',
  WYNK_MUSIC: 'wynk_music',
};

export const OFFER_CONFIGS = {
  [OFFER_TYPE.WYNK_HT]: {
    title: 'Select Your Hellotunes Pack',
    subtitle: 'Unlimited Hellotunes. No other Premium Benefits.',
  },
  [OFFER_TYPE.WYNK_MUSIC]: {
    title: 'Select your Premium Plan',
    subtitle: '',
  },
};

export const PACK_TYPE = {
  CHANNEL: 'channel',
  BUNDLE: 'bundle',
};

export const PLAN_STATE = {
  ACTIVE: 'ACTIVE',
  DEPRECATED: 'DEPRECATED',
};

export const LARGESCREEN = 'largescreen';

export const DOCUMENT_INFO = {
  [SERVICE.RAJ_TV]: {
    title: 'Raj Digital TV',
    favicon: '/rajtv.ico',
    logo: '/rajtv.ico',
  },
  [SERVICE.MUSIC]: {
    title: 'Wynk Music',
    favicon: '/wynk.ico',
    logo: WynkLogo,
  },
  [SERVICE.AIRTEL_TV]: {
    title: 'Airtel Xstream',
    favicon: '/airtel.ico',
    logo: svg.xstream_logo,
  },
  [SERVICE.ENTERR10]: {
    title: 'Enterr10',
    favicon: '/enterr10.ico',
    logo: '/enterr10.ico',
  },
  [SERVICE.IQ_VIDEO]: {
    title: 'IQVIDEO',
    favicon: '/airtel.ico',
    logo: svg.xstream_logo,
  },
};

export const TICK_ICON = {
  [SERVICE.RAJ_TV]: GreenDoubleTick,
  [SERVICE.ENTERR10]: GreenDoubleTick,
  [SERVICE.IQ_VIDEO]: GreenDoubleTick,
  default: DoubleTick,
};

export const INGRESS_INTENT = {
  RENEW: 'renew',
  NON_RENEW: 'non-renew',
  FMF_XTRM_RENEWAL: 'FMF_XTRM_RENEWAL',
  FMF_MANDATE: 'FMF_MANDATE',
};

export const WYNK_PAY_BUTTON = {
  RENEW_NOW: 'Renew Now',
  GET_PREMIUM: 'Get Premium',
  CONTINUE: 'Continue',
  FREE_TRIAL: 'Start your free trial',
};
// export const DEFAULT_API_VERSION = 'v4'; // to be used for penny drop
export const DEFAULT_API_VERSION = 'v3';

export const NEW_EVENTS = {
  // promocode
  PC_APPLY_BUTTON_CLICK: 'PC_Apply_Button_Click',
  PC_REMOVE_BUTTON_CLICK: 'PC_Remove_Button_Click',
  PC_APPLY_PAGE_OPENS: 'PC_Apply_Popup_Page_Opens',
  PC_ENTER_PROMO_CODE: 'PC_Enters_Code',
  PC_APPLY_CODE_SUCCESS: 'PC_Apply_Code_Success',
  PC_APPLY_CODE_FAILURE: 'PC_Apply_Code_Failure',
  PC_APPLY_CODE_ERROR: 'PC_Apply_Code_Error',
  PC_REMOVE_CODE_SUCCESS: 'PC_Remove_Code_Success',
  PC_REMOVE_CODE_FAILURE: 'PC_Remove_Code_Failure',
  PC_CROSS_CLOSE_POPUP: 'PC_Cross_Close_Popup_Page',

  // autoRenew
  MORE_BUTTON_CLICK: 'More_Button_Click',
  INFO_POPUP_CROSS_CLICK: 'Info_Popup_Cross_Clicked',
  INFO_POPUP_OK_CLICK: 'Info_Popup_Ok_Clicked',
};

export const PAYMENT_METHODS = {
  CARDS: 'CC/DC',
  NB: 'NET_BANKING',
  UPI: 'UPI',
};

export const PAYMENT_FLOW = {
  MANDATE: 'mandate',
  AUTO_RENEW: 'autorenew',
  TRIAL_OPTED: 'trialopted',
};

export const BANNER_TYPES = {
  FMF_XTRM_RENEWAL: 'FMF_XTRM_RENEWAL',
  FMF_MANDATE: 'FMF_MANDATE',
  FREE_TRIAL_BANNER: 'FREE_TRIAL_BANNER',
};

export const PAYMENT_FLOW_URL_VALUES = {
  MANDATE: 'mandate',
  AUTO_RENEW: 'autoRenew',
  TRIAL_OPTED: 'trialOpted',
};

export const SUBSCRIPTION_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  INAPP: 'INAPP',
};

export const PACKAGE_NAME = {
  MUSIC: 'com.bsbportal.music',
};
