import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.bg};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 22222;
`;
