/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import '../../styles/_scrollbar.scss';
import Warning from '../../assets/icons/warning.png';
import {ConfirmPopupWrapper} from './style';
import {subscription_theme} from '../../styles/theme';

export const ConfirmPopup = (props) => {
  const { onClose, backDropStatic, height, onCancel,
    width, heading, subheading, okText, cancelText, onOkay,
    hideCancel, hideOkay, okClass, cancelClass, service,
   } = props;
  const [isActive, setIsActive] = useState(true);
  const onCloseModal = () => {
    setIsActive(false);
    if (onClose) {
      onClose();
    }
  };

  const onOkayModal = () => {
    setIsActive(false);
    if (onOkay) {
      onOkay();
    }
  };

  const onCancelModal = () => {
    setIsActive(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div
      onClick={backDropStatic ? () => {} : onCloseModal}
      className={`bottom_modal ${isActive ? '' : 'bottom_modal__deactive'}`}
    >
      <div className="bottom_modal__inner scroll-class" style={{ width: `${width}` }}>
        <div className="bottom_modal__inner__content" style={{ height: `${height}px` }}>
        <ConfirmPopupWrapper subscription_theme={subscription_theme[service] || subscription_theme.default}>
          <div className="confirm_popup_wrapper__header">
            <div className="confirm_popup_wrapper__header__logo">
              <img src={Warning} />
            </div>
          </div>
          <div className="confirm_popup_wrapper__content">
            {heading && <p className="label">
              {heading}
            </p>}
            {subheading && <p className="description">
              {subheading}
            </p>}
          </div>
          <div className="confirm_popup_wrapper__footer">
            {!hideOkay && <button className={okClass || 'forward'} onClick={onOkayModal}>
              {okText || 'Yes'}
            </button>}
            {!hideCancel && <button className={cancelClass || 'cancel'} onClick={onCancelModal}>
              {cancelText || 'No'}
            </button>}
          </div>
        </ConfirmPopupWrapper>
        </div>
      </div>
    </div>
  );
};

ConfirmPopup.propTypes = {
  onClose: PropTypes.func,
  backDropStatic: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOkay: PropTypes.func,
  onCancel: PropTypes.func,
  hideCancel: PropTypes.bool,
  hideOkay: PropTypes.bool,
  okClass: PropTypes.string,
  cancelClass: PropTypes.string,
  service: PropTypes.string,
};
