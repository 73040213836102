import React from 'react';
import { useActivityTracker, useQueryParams } from '../../hooks';
import { THEME_DARK } from '../../Pages/Failure/constants';
import ExclaimationIconRed from '../../assets/common/Frame_3022.png';
import ExclaimationIconGrey from '../../assets/failure-screens/exclaimation_grey.png';
import './style.js';
import { ErrorScreenWrapper } from './style.js';
import { darkTheme, lightTheme } from '../../Pages/Subscription/music/Styles/theme';
import { EVENT, SOURCE } from '../../constants';
import PropTypes from 'prop-types';

export const ErrorScreen = (props) => {
  const { updateActivity } = useActivityTracker();
  const query = useQueryParams();
  let theme = query.theme;
  theme = theme ? theme.toLowerCase() : theme;
  const {eventPayload} = props;

  const onTryAgain = () => {
    updateActivity({
      event: EVENT.ERROR_TRY_AGAIN,
      source: SOURCE.API_ERROR_SCREEN,
      ...eventPayload
    });
    window.location.reload();
  }

  return (
    <ErrorScreenWrapper theme={theme === 'dark' ? darkTheme : lightTheme} colorPalette={props.colorPalette}>
      <div className="active-plan-card-wrapper">
        <div className="active-plan-header">
          <img src={theme === THEME_DARK ? ExclaimationIconGrey : ExclaimationIconRed} alt="icon" />
          <div className="description">
            <div className="title">Unauthenticated User</div>
            <div className="subtitle">
              <span>Something Went wrong</span>
            </div>
          </div>
        </div>
        <div className="active-plan-footer">
          <div onClick={onTryAgain} className="footer-content">{"Let's Try Again"}</div>
        </div>
      </div>
    </ErrorScreenWrapper>
  );
};

ErrorScreen.propTypes = {
  eventPayload: PropTypes.object,
  colorPalette: PropTypes.object,
}
