import env from '../envConfig';
import { getServiceName } from '.';
import { SERVICE } from '../constants';
export const getClientEnvConfig = () => {
  const service = getServiceName();
  const { REACT_APP_MY_ENV } = process.env
  console.log('getclientConfig', service, process.env.NODE_ENV, REACT_APP_MY_ENV);
  if (service === SERVICE.AIRTEL_TV || service === SERVICE.MUSIC) {
    return env.wynk;
  }
  if (
    (service === SERVICE.RAJ_TV || service === SERVICE.ENTERR10) &&
    (REACT_APP_MY_ENV === 'production' || REACT_APP_MY_ENV === 'preprod')
  ) {
    return env[service];
  }
  return env.iqvideo;
};

export const getFEBaseUrl = () => {
  const env = getClientEnvConfig();
  return env?.FE_BASE_URL;
};

export const getSubscriptionBaseUrl = () => {
  const env = getClientEnvConfig();
  console.log(env);
  return env?.SUBSCRIPTION_BASE_URL;
};

export const getPaymentBaseUrl = () => {
  const env = getClientEnvConfig();
  return env?.PAYMENT_BASE_URL;
};

export const getActivityBaseUrl = () => {
  const env = getClientEnvConfig();
  return env?.ACTIVITY_TRACK;

}

export const getHomeUrl = () => {
  const env = getClientEnvConfig();
  return env?.HOME_URL;
}
