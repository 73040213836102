/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {ButtonWrapper} from './style';

export const Button = (props) => {
  const { text, onClick, buttonCss, txtCss, icon, rowReverse } = props;
  return (
    <ButtonWrapper subscription_theme={props.theme}>
      <div onClick={onClick} className={`btn ${buttonCss} ${rowReverse ? 'flex-row-reverse' : ''}`}>
        {text && <span className={`txt ${txtCss}`}>{text}</span>}
        {icon}
      </div>
    </ButtonWrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.any,
  buttonCss: PropTypes.string,
  txtCss: PropTypes.string,
  rowReverse: PropTypes.string,
  theme: PropTypes.string,
};
