/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '../../../../common/Carousel';
import { Icons } from '../../../../common';
import { dateInParts } from '../../../../common/utils/DateUtils';
import { getPackDurationDisplayData } from '../../../../utils/getDailyPackdata';
import DoubleTick from '../../../../assets/icons/double-checked.png';
import YellowTick from '../../../../assets/icons/yellow_tick.png';
import { PLAN_TYPE, SERVICE } from '../../../../constants';
// import './index.scss';
import { subscription_theme } from '../../../../styles/theme';
import { ChannelPackWrapper } from './style';
import { TICK_ICON } from '../../../../constants';

export const ChannelSuccess = (props) => {
  const { Chevron } = Icons;
  const { subscriptionStatus, onSuccess, service } = props;
  const bannerCarousel = {
    settings: {
      dots: true,
      slidesPerRow: 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: 'linear',
      infinite: true,
      speed: 1000,
      swipeToSlide: true,
    },
    images: subscriptionStatus.packDetails.benefits.rails.slice(0, 3),
  };
  const freePlan = subscriptionStatus.packDetails.type === PLAN_TYPE.FREE;

  const getDateString = (date) => {
    const dateObj = dateInParts(date, { day: '2-digit', month: 'long', year: 'numeric' });
    return `${dateObj.month.value} ${dateObj.day.value}, ${dateObj.year.value}`;
  };

  return (
    <React.Fragment>
      <ChannelPackWrapper subs_theme={subscription_theme[service] || subscription_theme.default}>
        <div className="channel_pack_wrapper">
          <div className="channel_pack_wrapper__header">
            <Carousel settings={bannerCarousel.settings} imageData={bannerCarousel.images} />
            <div className="bottom_gradient"></div>
          </div>
          <div className="channel_pack_wrapper__content">
            <div className="logo_box">
              <div className="circular-logo">
                <img
                  className="logo-image"
                  src={
                    subscriptionStatus.packDetails.combo
                      ? subscriptionStatus.packDetails.benefits.logo
                      : subscriptionStatus.packDetails.benefits.icon
                  }
                />
                <img className="double-tick" src={TICK_ICON[service] || TICK_ICON.default} />
              </div>
            </div>
            <p className="channel_pack_wrapper__content__heading">
              {subscriptionStatus.packDetails.title}
            </p>
            <p className={`channel_pack_wrapper__content__heading2 ${freePlan ? 'free' : ''}`}>
              Congratulations!
              {freePlan &&
                ` Your ${
                  getPackDurationDisplayData(subscriptionStatus.packDetails).textLowerCase
                } free trial is now active.`}
            </p>
            <p className={`channel_pack_wrapper__content__subs ${freePlan ? 'free' : ''}`}>
              {!freePlan &&
                `You can now watch all the premium content from ${
                  (subscriptionStatus.packDetails.title === 'Raj Digital TV' || service === SERVICE.ENTERR10 )
                    ? subscriptionStatus.packDetails.title
                    : subscriptionStatus.packDetails.title + ' on Xstream.'
                }`}
              {freePlan &&
                `You will be subscribed to the
                ${
                  getPackDurationDisplayData(subscriptionStatus.packDetails.paidPack).textLowerCase
                } plan after your free trial ends.
                  You can cancel your subscription or upgrade plan anytime from ‘Manage Subscriptions’.`}
            </p>
            <p className={`validity ${freePlan ? 'free' : ''}`}>
              Valid till {getDateString(subscriptionStatus.validity)}
            </p>
            <p className="transaction">Transaction ID {subscriptionStatus.tid}</p>
            <button className="footer_button" onClick={onSuccess}>
              CONTINUE WATCHING
              <Chevron
                color={
                  subscription_theme[service]
                    ? subscription_theme[service].btnActiveTxt
                    : subscription_theme.default.btnActiveTxt
                }
              />
            </button>
          </div>
        </div>
      </ChannelPackWrapper>
    </React.Fragment>
  );
};

ChannelSuccess.propTypes = {
  subscriptionStatus: PropTypes.object,
  onSuccess: PropTypes.func,
  service: PropTypes.string,
};
