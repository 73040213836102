import { useSelector, useDispatch } from 'react-redux';
import { storeToastData } from '../actions';

export const useToastDataUpdate = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toastData.info);
  return (obj) => {
    toast.push(obj);
    dispatch(storeToastData(toast));
  };
};
