export const PAYMENT_MACROS = {
  MSISDN: '#msisdn',
  AMOUNT: '#amount',
}

export const MUSIC_SOURCE = 'wynk'
export const LOB_NAME = 'PREPAID'
export const AIRTEL_PARTNER = 'AIRTEL_PARTNER'
export const WYNK_HOMNE_DEEPLINK = 'http://www.wynk.in/music/index.html'
export const WYNK_HOME_CTA_TEXT = 'Play Music On Wynk Music'
export const DATA_PACK_TEXT = 'Data Pack For Music Lovers'
