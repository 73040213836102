import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const CustomImage = (props) => {
  const { fallbackSrc, alt, classStr } = props;
  const [src, setSrc] = useState(props.src);
  const [errored, setErrored] = useState(false);

  const onError = () => {
    if (!errored) {
      setErrored(true);
      setSrc(fallbackSrc);
    }
  };
  return <img className={classStr} onError={onError} src={src} alt={alt} />;
};

CustomImage.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  alt: PropTypes.string,
  classStr: PropTypes.string,
};
