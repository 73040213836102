/* eslint-disable max-len */

import styled from 'styled-components';
import {rg_variables} from '../../../../../styles/variables';

export const BaseTimer = styled.div.attrs({
  className: "base-timer"
})`
  position: relative;
  width: 176px;
  height: 176px;
  .base-timer__svg {
    transform: scaleX(-1);
  }

  .base-timer__circle {
    fill: none;
    stroke: none;
  }

  .base-timer__path-elapsed {
    stroke-width: 10px;
    stroke: #333333;
  }

  .base-timer__path-remaining {
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }

  .base-timer__path-remaining.arc {
    color: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.theme_red};
  }

  .base-timer__label {
    position: absolute;
    width: 176px;
    height: 176px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #EEEEEE;
    flex-direction: column;
  }
`;
