import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {BaseTimer} from './style';
import {subscription_theme} from '../../../../../styles/theme';

const FULL_DASH_ARRAY = 283;
const RESET_DASH_ARRAY = `-57 ${FULL_DASH_ARRAY}`;

function formatTime(time) {
  const minutes = Math.floor(time / 60);
  let seconds = time % 60;

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

export const CircularTimer = (props) => {
  const { timerSeconds, callback, service } = props;
  const TIME_LIMIT = timerSeconds || 30; //in seconds
  let timePassed = -1;
  let timeLeft = TIME_LIMIT;
  let timerInterval = null;
  const [circleDasharray, setCircleDashArray] = useState('283');
  const [timerLabel, setTimerLabel] = useState(formatTime(timeLeft));
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  const startTimer = () => {
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;
      setTimerLabel(formatTime(timeLeft));
      calculateCircleDasharray();
      if (timeLeft === 1) {
        clearInterval(timerInterval);
        if (callback) {
          callback();
        }
      }
    }, 1000);
  };

  const calculateTimeFraction = () => {
    const rawTimeFraction = timeLeft / TIME_LIMIT;
    return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
  };

  const calculateCircleDasharray = () => {
    const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    setCircleDashArray(circleDasharray);
  };

  return (
    <>
      <div id="app">
        <BaseTimer subscription_theme={subscription_theme[service] || subscription_theme.default}>
          <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g className="base-timer__circle">
              <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
              <path
                id="base-timer-path-remaining"
                strokeDasharray={circleDasharray}
                className="base-timer__path-remaining arc"
                d="
                   M 50, 50
                   m -45, 0
                   a 45,45 0 1,0 90,0
                   a 45,45 0 1,0 -90,0
                   "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" className="base-timer__label">
            <span>{timerLabel}</span>
            <span style={{ fontSize: '14px' }}>min</span>
          </span>
        </BaseTimer>
      </div>
    </>
  );
};

CircularTimer.propTypes = {
  timerSeconds: PropTypes.number.isRequired,
  callback: PropTypes.func,
  service: PropTypes.string,
};
