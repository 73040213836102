export const defaultUserSession = {
  id: '',
  platform: '',
};

export const defaultToastData = {
  info: [],
};

export const defaultLoaderState = {
  isLoaderVisible: false,
  width: 120,
  height: 120,
  theme: 'light',
};

export const defaultUserDetails = { };
