import { PLATFORM } from '../constants';

export const useMusicBackToMobile = () => {
  return (platform) => {
    try {
      if (platform.toLowerCase() === PLATFORM.ANDROID) {
        window.app.exit();
      } else if (platform.toLowerCase() === PLATFORM.IOS) {
        window.webkit.messageHandlers.exit.postMessage('ios');
      } else if (
        platform.toLowerCase() === PLATFORM.WEB ||
        platform.toLowerCase() === PLATFORM.MWEBOS
      ) {
        window.history.back();
      } else {
        return;
      }
    } catch (e) {
      return;
    }
  };
};
