import { useDispatch } from 'react-redux';
import { getSubscriptionStatus } from '../actions';

export const useSubscriptionStatus = () => {
  const dispatch = useDispatch();
  return (sessionId) => {
    return dispatch(getSubscriptionStatus(sessionId))
      .then((res) => {
        return { isLoading: false, error: null, subscription: res.payload.data };
      })
      .catch((err) => {
        return { isLoading: false, error: err, subscription: {} };
      });
  };
};
