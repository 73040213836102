import { useDispatch, useSelector } from 'react-redux';
import { postActivity } from '../Pages/Subscription/common/actions';
import { browserName, osName } from 'react-device-detect';
import { useQueryParams, useSendEventToApp } from '../hooks';
import { PAYMENT_FLOW } from '../constants';

export const useActivityTracker = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const OS = osName;
  const browser = browserName;
  const deviceType = window.navigator.platform;
  const pageUrl = window.location.href;
  const dispatch = useDispatch();
  const timestamp = new Date().getTime();
  const appName = 'Partner Channel';
  const queryParams = useQueryParams();
  const sendEventToApp = useSendEventToApp();
  const did = queryParams.did || undefined;
  const uid = queryParams.uid || undefined;
  const paymentFlow = queryParams.paymentFlow || '';

  const { hasFreeTrial } = useSelector((state) => state.freeTrialFlow);
  const { externalTransactionToken } = useSelector((state) => state.AlternateBillingDetails);
  const isTrialOptedFLow = paymentFlow?.toLowerCase() === PAYMENT_FLOW.TRIAL_OPTED;

  let { circle } = userDetails;
  if (!circle) {
    circle = queryParams.circle || undefined;
  }
  const commonData = {
    OS,
    browser,
    deviceType,
    timestamp,
    pageUrl,
    appName,
    did,
    uid,
    circle,
    isTrial: hasFreeTrial || isTrialOptedFLow,
    externalTransactionToken,
  };

  const updateEvent = async (updatedActivity, sendtoAppOnly) => {
    sendEventToApp(updatedActivity);
    if (sendtoAppOnly) return;
    const res = await dispatch(postActivity(updatedActivity));
  };

  const updateActivity = (data, sendtoAppOnly) => {
    const timestamp = new Date().getTime();
    const updatedActivity = { ...commonData, ...data, timestamp };
    updateEvent(updatedActivity, sendtoAppOnly);
  };

  return { updateActivity };
};
