import { useDispatch } from 'react-redux';
import { getSubscription } from '../actions';

export const useSubscription = () => {
  const dispatch = useDispatch();
  return (sessionId) => {
    return dispatch(getSubscription(sessionId))
      .then((res) => {
        return { isLoading: false, error: null, subscriptionData: res.payload.data.data };
      })
      .catch((err) => {
        return { isLoading: false, error: err, subscriptionData: [] };
      });
  };
};
