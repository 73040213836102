/* eslint-disable max-len */
import { usePayViaGoogleMusic } from './usePayViaGoogleMusic';
import { useHistory } from 'react-router-dom';
import { PLATFORM, BUILD_NUMBER, CURRENCY, DEFAULT_COUNTRY_CODE } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { postMusicPaymentDataRequirement } from '../Pages/Subscription/common/actions';
import { useReplaceMacros } from '../Pages/Subscription/common/hooks';
import { useToastDataUpdate } from './useUpdateToast';
import {
  API_ENTITY,
  LOB_NAME,
  MUSIC_SOURCE,
  AIRTEL_PARTNER,
  WYNK_HOME_CTA_TEXT,
  WYNK_HOMNE_DEEPLINK,
  DATA_PACK_TEXT,
} from '../Pages/Subscription/common/constants';
import { getFEBaseUrl, getPaymentsUrl } from '../utils';
import { usePayViaGooglePlay } from './usePayViaGooglePlay';

export const useMusicContinueToPay = () => {
  const dispatch = useDispatch();
  const payViaGoogleMusic = usePayViaGoogleMusic();
  const updateToast = useToastDataUpdate();
  const history = useHistory();
  const replaceMacros = useReplaceMacros();
  const payViaGooglePlay = usePayViaGooglePlay();
  const userDetails = useSelector((state) => state.userDetails);
  const { countryCode } = userDetails;

  const getNumberOfDays = (str) => {
    switch (str.toUpperCase()) {
      case 'YEARLY':
        return 365;
      case 'QUARTERLY':
        return 180;
      case 'MONTHLY':
        return 30;
    }
  };

  return ({
    selectedPlan,
    buildNo,
    uid,
    deviceId,
    hash,
    sid,
    platform,
    service,
    tms,
    paymentMeta,
    circle,
    openWebView,
    cancelUrl,
    paymentFlow,
    ingressIntent,
  }) => {
    if (paymentMeta?.mode === AIRTEL_PARTNER) {
      const payload = {
        order: {
          lobName: LOB_NAME,
          paymentAmount: selectedPlan.total,
          benefitAmount: selectedPlan.total,
          serviceInstance: paymentMeta.msisdn,
        },
        billSummary: {
          transactionText: `Prepaid Recharge | ${paymentMeta.msisdn}`,
          primaryHeading: '',
          detailSection: [
            {
              key: selectedPlan.title,
              value: `${CURRENCY[selectedPlan.currency].symbol}${selectedPlan.total}`,
            },
          ],
        },
        callBack: {
          redirectionUrl: API_ENTITY.AIRTEL_THANKS_URL,
          cancelUrl: cancelUrl || window.location.href,
        },
        flow: {
          trackingObj: { utm_source: MUSIC_SOURCE },
        },
        result: {
          resultDetails: {
            redirectionUrl: WYNK_HOMNE_DEEPLINK,
            redirectionButtonLabel: WYNK_HOME_CTA_TEXT,
          },
          resultSummary: [
            {
              heading: DATA_PACK_TEXT,
              value: `${CURRENCY[selectedPlan.currency].symbol}${selectedPlan.total}`,
            },
          ],
        },
      };
      const encodedPaylaod = btoa(encodeURIComponent(JSON.stringify(payload)));
      if (openWebView && platform.toLowerCase() === PLATFORM.ANDROID) {
        window.app.gotoOtherPaymentOption(
          `${API_ENTITY.AIRTEL_RECHARGE_URL}?payloadData=${encodedPaylaod}`,
        );
        return;
      }
      window.location = `${API_ENTITY.AIRTEL_RECHARGE_URL}?payloadData=${encodedPaylaod}`;
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
      'x-bsy-utkn': uid,
    };
    const payload = {
      sid: sid,
      planId: selectedPlan.planId,
      uid: uid,
      deviceId: deviceId,
      hash: decodeURIComponent(hash),
      tms: tms,
      os: platform,
    };
    if (platform.toLowerCase() === PLATFORM.IOS && selectedPlan.sku) {
      if (buildNo && Number(buildNo) >= BUILD_NUMBER.MUSIC_IOS) {
        window.webkit.messageHandlers.payAction.postMessage({
          paymentMetaMap: {
            sid,
            platform,
            skuId: selectedPlan.sku.itunes,
            planId: selectedPlan.planId,
          },
        });
      } else {
        dispatch(postMusicPaymentDataRequirement(headers, payload))
          .then((res) => {
            window.webkit.messageHandlers.payAction.postMessage(selectedPlan.sku.itunes);
          })
          .catch((err) => {
            updateToast({ type: 'failure', message: err.message });
          });
      }
    } else if (platform.toLowerCase() === PLATFORM.ANDROID) {
      const skuId = selectedPlan?.sku?.google_iap;
      // if non indian user
      if (countryCode !== DEFAULT_COUNTRY_CODE) {
        const paymentInfo = {
          sid,
          planId: selectedPlan.planId,
          uid,
          sub_type: selectedPlan.subType,
          sku_id: skuId,
        };
        payViaGooglePlay(paymentInfo, service);
      } else if (openWebView) {
        const url = getPaymentsUrl(sid, platform, {
          planId: selectedPlan.planId,
          buildNo,
          service,
          uid,
          circle,
          did: deviceId,
          musicCallback: true,
          paymentFlow,
          skuId,
          ingressIntent,
        });
        window.app.gotoOtherPaymentOption(`${getFEBaseUrl()}/#${url}`);
      } else {
        const url = getPaymentsUrl(sid, platform, {
          planId: selectedPlan.planId,
          buildNo,
          service,
          uid,
          circle,
          did: deviceId,
          paymentFlow,
          skuId,
          ingressIntent,
        });
        history.push(url);
      }
    } else if (
      platform.toLowerCase() === PLATFORM.WEB ||
      platform.toLowerCase() === PLATFORM.MWEBOS
    ) {
      history.push(
        getPaymentsUrl(sid, platform, {
          planId: selectedPlan.planId,
          buildNo,
          service,
          uid,
          circle,
          did: deviceId,
          paymentFlow,
          ingressIntent,
        }),
      );
    } else {
      const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}`;
      history.push(rediectedTo);
    }
  };
};
