import React from 'react';
import PropTypes from 'prop-types';
import './icons.scss';

export const Chevron = ({ width, height, color, rotate }) => {
  return (
    <span className={rotate ? `chevron${rotate}` : 'chevron'}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 5.00049L1.333 10.0005L0.309501 9.00999L4.453 
         5.00049L0.310001 0.990988L1.333 0.00048783L6.5 5.00049Z"
          fill={color}
        />
      </svg>
    </span>
  );
};

Chevron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  rotate: PropTypes.number,
};

Chevron.defaultProps = {
  width: '7',
  height: '10',
  color: '#EEEEEE',
};
