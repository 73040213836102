/* eslint-disable max-len */
import React, { useEffect } from 'react';
import check from '../../assets/icons/check_toast.png';
import wrong from '../../assets/icons/wrong_toast.png';
import { useSelector, useDispatch } from 'react-redux';
import { storeToastData } from '../../actions';
import PropTypes from 'prop-types';
import './index.scss';

export const ToastContainer = (props) => {
  const toasts = useSelector((state) => state.toastData);
  const disapacth = useDispatch();

  useEffect(() => {
    if (toasts.info.length) {
      const useTime = toasts.info.length > 1 ? toasts.info.length * 500 : 1000;
      const timeoutID = setTimeout(() => {
        disapacth(storeToastData(toasts.info.shift()));
      }, useTime);
      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [toasts.info.length]);

  return toasts.info.length
    ? toasts.info.map((item, i) => (
        <div key={i} className="toast_wrapper">
          <div className="toast_wrapper__icon">
            <img src={item.type === 'success' ? check : wrong} />
          </div>
          <p className="toast_wrapper__content">{item.message}</p>
        </div>
      ))
    : null;
};

ToastContainer.propTypes = {
  item: PropTypes.object,
};
