/* eslint-disable max-len */

import { useActivityTracker } from './useActivityTracker';
import { usePayViaITunes } from './usePayViaITunes';
import { useHistory } from 'react-router-dom';
import { PLATFORM, SOURCE, EVENT } from '../constants';
import { getPaymentsUrl } from '../utils';

export const useClientContinueToPay = () => {
  const { updateActivity } = useActivityTracker();
  const payViaITunes = usePayViaITunes();
  const history = useHistory();

  return ({ selectedPlan, buildNo, sid, platform, hash, uid, did, service, circle, version }) => {
    
    updateActivity({
      planId: selectedPlan.planId,
      sid: sid,
      event: EVENT.CONTINUE_TO_PAY,
      source: SOURCE.SUBSCRIPTION_PAGE,
      pageName: SOURCE.SUBSCRIPTION_PAGE,
      service: service,
      platform,
    });

    if (platform.toLowerCase() === PLATFORM.IOS && selectedPlan.sku) {
      window.webkit.messageHandlers.payActions.postMessage({
        payActions: {
          sid,
          platform,
          planId: selectedPlan.planId,
          skuId: selectedPlan.sku.itunes,
          hash,
          uid,
          deviceId: did,
        }
      });
    } else if ( [PLATFORM.ANDROID, PLATFORM.WEB, PLATFORM.MWEBOS].includes(platform.toLowerCase())) {
      history.push(
        getPaymentsUrl(sid,platform,{planId: selectedPlan.planId,buildNo,service,uid,circle,did, version})
      );
    } else {
      const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}${version ? `&version=${version}` : ''}`;
      history.push(rediectedTo);
    }
  };
};
