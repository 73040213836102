import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import benefits from '../../../../../assets/music-screens/benefits_img.png';
import { useQueryString } from '../../../../../hooks';
import { Plan } from './plan';
import { PLATFORM, BUILD_NUMBER } from '../../../../../constants';
import { setDocumentInfo } from '../../../../../utils';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ActivePlanWrapper } from '../MusicActivePlans/style';
import { darkTheme } from '../../Styles/theme';
import premium_icon from '../../../../../assets/music-screens/premium.png';

import './index.scss';

export const RegisterPage = () => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const query = useQueryString();
  const platform = query.get('os');
  const buildNo = query.get('buildNo');
  const service = query.get('service');
  const countryCode = query.get('countryCode');

  const plans = {
    android: {
      id: 1,
      validityUnit: 'Monthly',
      total: 49,
      displayAmount: 99,
      subText: '',
    },
    ios: {
      id: 1,
      validityUnit: 'Monthly',
      total: 49,
      displayAmount: 99,
      subText: 'Subscription will auto-renew unless canceled 24 hours before renewal.',
    },
  };

  useEffect(() => {
    setDocumentInfo(service);
  }, []);

  useEffect(() => {
    checkPlatform();
  }, [platform]);

  const checkPlatform = () => {
    if (platform && platform.toLowerCase() === PLATFORM.ANDROID) {
      setSelectedPlan(plans.android);
    } else if (platform && platform.toLowerCase() === PLATFORM.IOS) {
      setSelectedPlan(plans.ios);
    } else {
      return;
    }
  };

  const registerUser = () => {
    try {
      if (platform.toLowerCase() === PLATFORM.ANDROID) {
        window.app.registerNumber();
      } else if (platform.toLowerCase() === PLATFORM.IOS) {
        window.webkit.messageHandlers.registration.postMessage('ios');
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const getBackgroundColor = () => {
    let bgColor = darkTheme.palette.active_plan_page_bg;
    if (
      (platform.toLowerCase() === PLATFORM.ANDROID &&
        buildNo >= BUILD_NUMBER.MUSIC_ANDROID_BG_COLOR) ||
      (platform.toLowerCase() === PLATFORM.IOS && buildNo >= BUILD_NUMBER.MUSIC_IOS_BG_COLOR)
    ) {
      bgColor = darkTheme.palette.active_plan_page_bg2;
    }
    return bgColor;
  };

  const getPremiumTemplate = (plan) => (
    <HelmetProvider>
      <Helmet>
        <body style={`background-color: ${getBackgroundColor()}`} />
      </Helmet>
      <ActivePlanWrapper theme={darkTheme} os={platform} bgColorPage={getBackgroundColor()}>
        <div className="active-plan-card-wrapper">
          <div className="active-plan-header">
            <img src={plan.icon || premium_icon} alt="icon" />
            <div className="description">
              <div className="title">{plan.title}</div>
              <div className="subtitle">
                {plan.benefits &&
                  plan.benefits.map((benefit, i) => (
                    <span key={i}>
                      <span>{benefit}</span>
                      <span> • </span>
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div className="active-plan-footer">
            <div className="footer-content">
              <span>{plan.footerTitle}</span>
              <button onClick={registerUser}>{plan.button || 'Proceed'}</button>
            </div>
          </div>
        </div>
      </ActivePlanWrapper>
    </HelmetProvider>
  );

  const getPlanTemplate = () => {
    switch (countryCode) {
      case 'SG':
        return getPremiumTemplate({
          title: 'Get Wynk Premium',
          footerTitle: '30 day Free Trial. Billed at S$6.98 per month after that.',
          benefits: ['No Ads', 'Unlimited Music Downloads', 'Unlimited Music Streaming'],
        });
      default:
        return (
          <div className="palns-wrapper">
            <h2>Wynk Premium {selectedPlan.validityUnit} </h2>
            <div className="offer-benefits">
              <img src={benefits} alt="" />
            </div>
            <div className="plans">
              <Plan plan={selectedPlan} />
            </div>
            <button className="register-btn" onClick={registerUser}>
              Register Now and Pay
            </button>
            {selectedPlan.subText && (
              <p className="auto-renew-txt">
                Subscription will auto-renew unless canceled 24 hours before renewal.
              </p>
            )}
          </div>
        );
    }
  };

  return <>{getPlanTemplate()}</>;
};

RegisterPage.propTypes = {
  sid: PropTypes.string,
  platform: PropTypes.string,
};
