import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import benefits from '../../../../../../../assets/music-screens/benefits_img.png';
import { useSubscription, useDefaultPlanSelection } from '../../../../../common/hooks';
import { useSessionStore, useRedirectMusicContinueToPay } from '../../../../../../../hooks';
import { useHistory } from 'react-router-dom';
import { Plan } from '../Plan';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlan } from '../../../../../common/actions';
import { PLATFORM } from '../../../../../../../constants';
import './index.scss';

export const PopupPlanView = (props) => {
  const { sid, platform, buildNo, hash, uid, deviceId, service, tms } = props;
  const fetchSubscription = useSubscription();
  const storeSession = useSessionStore();
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [offer, setOffer] = useState({});
  const selectDefaultPlan = useDefaultPlanSelection();
  const dispatch = useDispatch();
  const onContinueToPay = useRedirectMusicContinueToPay();
  const selectedPlan = useSelector((state) => state.selectedPlan.plan);
  const userDetails = useSelector((state) => state.userDetails);
  const { circle } = userDetails;

  useEffect(() => {
    const fetchData = async () => {
      storeSession(sid, platform);
      const res = await fetchSubscription(sid);
      if (res.error) {
        const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}`;
        history.push(rediectedTo);
      } else {
        if (res.subscriptionData.eligibleBenefits.length) {
          setOffer(res.subscriptionData.eligibleBenefits[0]);
          if (res.subscriptionData.eligibleBenefits[0].plans.length) {
            setPlans(res.subscriptionData.eligibleBenefits[0].plans);
          }
        }
        selectDefaultPlan(res.subscriptionData, service, sid, platform);
      }
    };
    fetchData();
  }, [sid]);

  const continueToPay = () => {
    onContinueToPay({
      selectedPlan,
      buildNo,
      sid,
      platform,
      hash,
      uid,
      deviceId,
      service,
      tms,
      circle,
    });
  };

  const onSelectPlan = (plan) => {
    dispatch(
      selectPlan(
        { ...plan, partnerName: offer.title, partnerIcon: offer.partnerIcon },
        plan.planId,
      ),
    );
  };

  return (
    <>
      {!!plans.length && (
        <div className="palns__wrapper">
          <h2>Wynk Premium Annual</h2>
          <div className="offer-benefits">
            <img src={benefits} alt="" />
          </div>
          <div className="plans">
            {plans.map((plan, i) => (
              <Plan key={i} plan={plan} onSelectPlan={onSelectPlan} />
            ))}
          </div>
          {selectedPlan.planId && (
            <button className="register-btn" onClick={continueToPay}>
              Continue to pay
            </button>
          )}
          {platform && platform.toLowerCase() === PLATFORM.IOS && (
            <p className="auto-renew-txt">
              Subscription will auto-renew unless canceled 24 hours before renewal.
            </p>
          )}
        </div>
      )}
    </>
  );
};

PopupPlanView.propTypes = {
  sid: PropTypes.string,
  platform: PropTypes.string,
  buildNo: PropTypes.string,
  hash: PropTypes.string,
  uid: PropTypes.string,
  deviceId: PropTypes.string,
  service: PropTypes.string,
  tms: PropTypes.number,
};
