export const rg_variables = {
  'font_family': {
    'default': 'Roboto',
    'regular': 'Roboto',
    'medium': 'Roboto',
    'light': 'Roboto',
    'bold': 'Roboto',
    'black': 'Roboto',
  },
  'theme_black': '#212121',
  'theme_red': '#d8272c',
  'grey': '#333333',
  'red_1': '#e10000',
  'grey_0': '#333333',
  'light_grey_1': '#666666',
  'light_grey_2': '#9e9e9e',
  'outline_white': '#eeeeee',
  'white': '#ffffff',
  'textbox_color': '#313131',
  'plan_border': '#cccc',
  'plan_theme_color': '#852492',
  'black_1': '#191919',
  'white_filter': 'invert(100%) sepia(4%) saturate(0%) hue-rotate(246deg) brightness(105%) contrast(104%)',
  'font': {
    'medium': '14px',
    'large': '16px',
    'small': '12px',
    'medium_large': '32px',
    'extra_large': '40px',
    'extra_small': '9px',
    'very_small': '10px',
    'more_large': '20px',
  },
  /* position variables */
  'position': {
    'abs': 'absolute',
    'fix': 'fixed',
    'rel': 'relative',
  },
  /* rajtv variables */
  'rajtv': {
    'theme': '#FFD566',
    'theme_black': '#191919',
    'btn_text': '#191919',
    'plan_card_btn': '#1354AF',
    'green_color': '#16B27E',
  },
  'red_gradient': 'linear-gradient(180deg, rgba(227, 46, 45, 0.2) 0%, rgba(25, 25, 25, 0) 100%)',
  'enterr10': {
    'theme': '#FFD01E',
    'theme_black': '#191919',
    'btn_text': '#191919',
    'plan_card_btn': '#1354AF',
    'green_color': '#16B27E',
  },
}
