/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ExclaimationIconRed from '../../assets/common/Frame_3022.png';
import PendingIconRed from '../../assets/common/Frame_3024.png';
import ExclaimationIconGrey from '../../assets/failure-screens/exclaimation_grey.png';
import PendingIcon from '../../assets/failure-screens/pending_grey.png';
import { BottomModal } from '../../common';
import PropTypes from 'prop-types';
import { PLATFORM, MOBILE_STATUS, BUILD_NUMBER, SERVICE } from '../../constants';
import { useActivityTracker, useFailureBack } from '../../hooks';
import { storeLoaderData } from '../../actions';
import '../../styles/index.scss';
import { useSubscriptionStatus } from '../Subscription/common/hooks';
import * as spinnerLottieImage from '../../assets/lottie/lf20_N3BXE6.json';
import * as lottieSmiley from '../../assets/lottie/lf20_LpFnsP.json';
import { SESSION_OUT, PAYMENT_FAILED, PAYMENT_PENDING, THEME_DARK } from './constants';
import Lottie from 'react-lottie';
import { isServicePartner, isXtreamService, setDocumentInfo } from '../../utils';

const spinnerConfig = {
  loop: true,
  autoplay: true,
  animationData: spinnerLottieImage.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Failure = (props) => {
  const { state, platform, sid } = props.match.params;
  const search = props.location.search;
  const [pageDetails, setPageDetails] = useState({});
  const subscriptionRef = useRef({});
  const failureBack = useFailureBack();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const service = query.get('service');
  const appId = query.get('appId');
  const buildNo = query.get('buildNo');
  const appTheme = query.get('theme');
  const version = query.get('version');
  const [lottiesOptions] = useState(spinnerConfig);
  const fetchSubscriptionStatus = useSubscriptionStatus();
  const [usePlanId, setPlanId] = useState();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { updateActivity } = useActivityTracker();

  const theme = THEME_DARK;

  const onButtonClick = (planId) => {
    const {tid, transactionStatus, failureType} = subscriptionRef.current;
    updateActivity({
      planId: planId,
      sid: sid,
      tid: tid || '',
      service: service,
      platform,
      transactionStatus: transactionStatus || '',
      failureType: failureType || '',
      clickTime: new Date(),
      event: 'failed_try_again_cta'
    });
    
    failureBack({
      platform,
      status: MOBILE_STATUS.FAILURE,
      service,
      buildNo,
      sid,
      state,
      planId,
      appId,
      theme: appTheme,
      version,
    });
  };

  const getIconElement = (animationData) => {
    return (
      <Lottie
        options={{ ...lottiesOptions, animationData }}
        width={90}
        height={90}
        style={{ marginBottom: '28px' }}
      />
    );
  };

  const STATUS_INFO = {
    [SESSION_OUT]: {
      title: 'Unauthenticate user',
      subtitle: 'We could not try user details',
      description: 'Looks like your subscription could not be completed.',
      buttonText: 'LET’S TRY AGAIN',
      iconUrl: theme === THEME_DARK ? ExclaimationIconGrey : ExclaimationIconRed,
      buttonArrow: true,
      theme,
      onButtonClick,
    },
    [PAYMENT_PENDING]: {
      iconUrl: theme === THEME_DARK ? PendingIcon : PendingIconRed,
    },
    [PAYMENT_FAILED]: {
      iconUrl: theme === THEME_DARK ? getIconElement(lottieSmiley.default) : ExclaimationIconRed,
    },
  };

  useEffect(() => {
    setDocumentInfo(service);
    dispatch(
      storeLoaderData({
        isLoaderVisible: true,
        options: lottiesOptions,
      }),
    );
  }, []);

  useEffect(() => {
    if (state === SESSION_OUT) {
      setIsLoading(false);
      dispatch(
        storeLoaderData({
          isLoaderVisible: false,
          options: lottiesOptions,
        }),
      );
      setError(null);
      setPageDetails({ ...STATUS_INFO[state] });
    } else {
      const fetchData = async () => {
        const res = await fetchSubscriptionStatus(sid);
        if (res.error) {
          redirectToSessionOut();
        } else {
          const { subscription } = res;
          setIsLoading(res.isLoading);
          dispatch(
            storeLoaderData({
              isLoaderVisible: res.isLoading,
              options: lottiesOptions,
            }),
          );
          setError(res.error);
          if (subscription && subscription.data) {
            const { title, subtitle, description, buttonText, buttonArrow, packDetails, planId } =
              subscription.data;
            subscriptionRef.current = subscription.data;
            setPageDetails({
              title,
              subtitle,
              description,
              buttonText,
              buttonArrow,
              theme,
              onButtonClick,
              iconUrl: STATUS_INFO[state].iconUrl,
              header: packDetails && theme === THEME_DARK ? true : false,
              plan: packDetails,
              hidePayBar:
                platform.toLowerCase() === PLATFORM.ANDROID && 
                (isXtreamService(service) || isServicePartner(service)),
              planId,
            });
          } else {
            redirectToSessionOut();
          }
        }
      };
      fetchData();
    }
  }, [state]);

  useEffect(() => {
    if (platform.toLowerCase() === PLATFORM.ANDROID && window.AirtelTVClient) {
      if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_ANDROID) {
        //  window.AirtelTVClient.navigateToHome('homepage');
      } else {
        window.AirtelTVClient.onSubscriptionComplete(MOBILE_STATUS.FAILURE);
      }
    } else if (platform.toLowerCase() === PLATFORM.IOS && window.webkit) {
      if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_IOS) {
        // window.webkit.messageHandlers.navigateToHome.postMessage({
        //   navigateToHome: 'homepage',
        // });
      } else {
        // window.webkit.messageHandlers.onSubscriptionComplete.postMessage({
        //   onSubscriptionComplete: MOBILE_STATUS.FAILURE,
        // });
      }
    }
  }, [platform, buildNo]);

  const redirectToSessionOut = () => {
    const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}${version ? `&version=${version}` : ''}`;
    history.push(rediectedTo);
  };

  return <>{!isLoading && !error && <BottomModal {...pageDetails} service={service} />}</>;
};

Failure.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      state: PropTypes.string,
      platform: PropTypes.string,
      sid: PropTypes.string,
    }),
  }),
};
