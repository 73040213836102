import React from 'react';
import PropTypes from 'prop-types';
import './icons.scss';

export const Ruppe = ({ width, height, color }) => {
  return (
    <span className="rupee">
      <svg
        width={width}
        height={height}
        viewBox="0 0 6 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99219 2.05469H5.28906C5.55469 2.44531 
        5.72656 2.90365 5.80469 3.42969H7.38281L6.99219 4.85156H5.8125C5.69792 
        5.71094 5.35417 6.36979 4.78125 6.82812C4.20833 7.28125 3.39844 
        7.55208 2.35156 7.64062L5.99219 11.8984V12H3.75L-0.226562 7.36719L-0.234375 
        6.17188H1.74219C2.32552 6.17188 2.78646 6.05208 3.125 5.8125C3.46875 5.56771 
        3.69792 5.2474 3.8125 4.85156H-0.390625L0.0078125 3.42969H3.78125C3.52604 2.6224 2.83594 
        2.21875 1.71094 2.21875H-0.390625L0.03125 0.625H7.39062L6.99219 2.05469Z"
          fill={color}
        />
      </svg>
    </span>
  );
};

Ruppe.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
