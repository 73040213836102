import { PLATFORM, SERVICE } from '../constants';

export const useWebviewBackOrExit = () => {
  return ({ platform, service, preferBrowserBack }) => {
    console.info('useWebviewBackOrExit :: ', { platform, service, preferBrowserBack });
    try {
      if (preferBrowserBack && window.history.length > 1) window.history.back();
      if (platform?.toLowerCase() === PLATFORM.ANDROID) {
        if (service?.toLowerCase() === SERVICE.MUSIC) {
          window.app.exit();
        } else {
          window.AirtelTVClient.onExitWebView();
        }
      }
      // todo: handle back on ios devices for atv as well
      if (platform?.toLowerCase() === PLATFORM.IOS) {
        if (service?.toLowerCase() === SERVICE.MUSIC) {
          window.webkit.messageHandlers.exit.postMessage('ios');
        }
      }
    } catch (e) {
      console.error('ERROR :: useWebviewBackOrExit :: ', e);
      return;
    }
  };
};
