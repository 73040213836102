import {rg_variables} from './variables';
export const subscription_theme = {
  default: {
    theme_color: rg_variables.theme_red,
    btnActiveTxt: rg_variables.white,
    checkbox_tick_filter: rg_variables.white_filter,
    error_input_color: rg_variables.theme_red,
    input_autofill_color: rg_variables.theme_red,
    bottom_modal: {
      dark: {
        button_border: rg_variables.theme_red,
        button_txt: rg_variables.outline_white,
        chevron_color: rg_variables.outline_white,
      },
      light: {
        button_bg: rg_variables.theme_red,
        button_txt: rg_variables.outline_white,
        chevron_color: rg_variables.outline_white,
      },
    },
    tick_bg: rg_variables.theme_red,
    wallet: {
      popup:{
        ok_btn: rg_variables.theme_red,
      }
    },
    gradient: 'linear-gradient(180deg, rgba(227, 46, 45, 0.2) 0%, rgba(25, 25, 25, 0) 100%)',
  },
  rajtv:{
    error_input_color: rg_variables.white,
    theme_color: rg_variables.rajtv.theme,
    btnActiveTxt: rg_variables.rajtv.btn_text,
    checkbox_tick_filter: 'invert(0%) sepia(1%) saturate(1647%) hue-rotate(337deg) brightness(104%) contrast(80%)',
    input_autofill_color: rg_variables.rajtv.theme_black,
    bottom_modal: {
      dark: {
        button_border: rg_variables.outline_white,
        button_txt: rg_variables.outline_white,
        chevron_color: rg_variables.outline_white,
      },
      light: {
        button_bg: rg_variables.rajtv.theme,
        button_txt: rg_variables.rajtv.btn_text,
        chevron_color: rg_variables.rajtv.btn_text,
      },
    },
    tick_bg: rg_variables.rajtv.green_color,
    wallet: {
      popup:{
        ok_btn: rg_variables.rajtv.btn_text,
      }
    },
    gradient: 'linear-gradient(180deg, rgba(255, 213, 102, 0.2) 0%, rgba(25, 25, 25, 0) 100%)',
    themeBlack: rg_variables.rajtv.theme_black,
    btnTxtColor: rg_variables.rajtv.btn_text,
    planCardBtn: rg_variables.rajtv.plan_card_btn,
    greenColor: rg_variables.rajtv.green_color,
  },
  enterr10: {
    error_input_color: rg_variables.white,
    theme_color: rg_variables.enterr10.theme,
    btnActiveTxt: rg_variables.enterr10.btn_text,
    checkbox_tick_filter:
      'invert(0%) sepia(1%) saturate(1647%) hue-rotate(337deg) brightness(104%) contrast(80%)',
    input_autofill_color: rg_variables.enterr10.theme_black,
    bottom_modal: {
      dark: {
        button_border: rg_variables.outline_white,
        button_txt: rg_variables.outline_white,
        chevron_color: rg_variables.outline_white,
      },
      light: {
        button_bg: rg_variables.enterr10.theme,
        button_txt: rg_variables.enterr10.btn_text,
        chevron_color: rg_variables.enterr10.btn_text,
      },
    },
    tick_bg: rg_variables.enterr10.green_color,
    wallet: {
      popup: {
        ok_btn: rg_variables.enterr10.btn_text,
      },
    },
    gradient:
      'linear-gradient(180deg, rgba(255, 213, 102, 0.2) 0%, rgba(25, 25, 25, 0) 100%)',

    themeBlack: rg_variables.enterr10.theme_black,
    btnTxtColor: rg_variables.enterr10.btn_text,
    planCardBtn: rg_variables.enterr10.plan_card_btn,
    greenColor: rg_variables.enterr10.green_color,
  },
};
