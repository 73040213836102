export const NEW_VERIFY_CARD_NUMBER = 'NEW_VERIFY_CARD_NUMBER';
export const NEW_VERIFY_CARD_NUMBER_SUCCESS = 'NEW_VERIFY_CARD_NUMBER_SUCCESS';
export const NEW_VERIFY_CARD_NUMBER_FAIL = 'NEW_VERIFY_CARD_NUMBER_FAIL';
export const NEW_VERIFY_CARD_NUMBER_DEFAULT = 'NEW_VERIFY_CARD_NUMBER_DEFAULT';
export const CARD_DETAIL_VALID = 'CARD_DETAIL_VALID';
export const VERIFY_UPI = 'VERIFY_UPI';
export const USER_INFO = 'USER_INFO';

export const FETCH_PAYU_INFO = 'FETCH_PAYU_INFO';
export const FETCH_PAYU_INFO_SUCCESS = 'FETCH_PAYU_INFO_SUCCESS';
export const FETCH_PAYU_INFO_FAIL = 'FETCH_PAYU_INFO_FAIL';
export const FETCH_PROMO_CODE_INFO = 'FETCH_PROMO_CODE_INFO';
export const FETCH_PAYMENT_OPTIONS = 'FETCH_PAYMENT_OPTIONS';
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE';
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
export const VERIFY_OTP = 'VERIFY_OTP';
export const SAVED_OPTIONS = 'SAVED_OPTIONS';
export const AFTER_ADD_MONEY_DEBIT = 'AFTER_ADD_MONEY_DEBIT';
export const PHONE_PE_INSTALL = 'PHONE_PE_INSTALL';
export const UNLINK_WALLET = 'UNLINK_WALLET';
export const DO_CHARGING = 'DO_CHARGING';
export const DISCOUNT_APPLIED = 'DISCOUNT_APPLIED';
export const HOLDING_SCREEN_DATA = 'HOLDING_SCREEN_DATA';
export const PAYMENT_PLAN_DETAILS = 'PAYMENT_PLAN_DETAILS';
export const SELECTED_PAYMENT_DETAILS = 'SELECTED_PAYMENT_DETAILS';

export const PAYMENT_OPTIONS_DETAILS = 'PAYMENT_OPTIONS_DETAILS';
export const NEW_SAVED_PAYMENT_DETAILS = 'NEW_SAVED_PAYMENT_DETAILS';
export const NEW_PAYMENT_CHARGING = 'NEW_PAYMENT_CHARGING';
export const SHOW_SPINNER_LOADER = 'SHOW_SPINNER_LOADER';
export const SAVED_RECOMMENDED_OPTIONS = 'SAVED_RECOMMENDED_OPTIONS';
export const SELECTED_PAYMENT_ID = 'SELECTED_PAYMENT_ID';
export const REMOVE_SAVED_INFO = 'REMOVE_SAVED_INFO';
export const NEW_TOAST_INFO = 'NEW_TOAST_INFO';
export const USER_CHOICE_BILLING = 'USER_CHOICE_BILLING';
export const FETCH_USER_INVOICE = 'FETCH_USER_INVOICE';
export const STORE_PAYMENT_FLOW = 'STORE_PAYMENT_FLOW';
export const UPDATE_EXT_TXN_TOKEN = 'UPDATE_EXT_TXN_TOKEN';
