import airtelblack from './svg/airtelblack.svg';
import broadband from './svg/broadband.svg';
import CancelIcon from './svg/CancelIcon.svg';
import CaretDown from './svg/CaretDown.svg';
import ClockClockwise from './svg/ClockClockwise.svg';
import CreditCard from './svg/CreditCard.svg';
import dsl from './svg/dsl.svg';
import Ewallets from './svg/Ewallets.svg';
import Netbanking from './svg/NetBanking.svg';
import postpaid from './svg/postpaid.svg';
import refresh from './svg/refresh.svg';
import ShieldCheck from './svg/ShieldCheck.svg';
import Trash from './svg/Trash.svg';
import UpiTitle from './svg/UPI.svg';
import paytmUpi from './svg/paytmUpi.svg';
import phonepeUpi from './svg/phonepeUpi.svg';
import gPayUpi from './svg/gPayUpi.svg';
import bhimUpi from './svg/bhimUpi.svg';
import zeroOvercharge from './svg/zeroOvercharge.svg';
import removeIcon from './svg/removeIcon.svg';
import tickmark from './svg/tickmark.svg';
import searchIcon from './svg/searchIcon.svg';
import bookmark from './svg/bookmark.svg';
import plusIcon from './svg/plusIcon.svg';
import warningIcon from './svg/warningIcon.svg';
import successIcon from './svg/successIcon.svg';
import info from './svg/info.svg';
import queryIcon from './svg/queryIcon.svg';
import googlePlay from './svg/googlePlay.svg';
import wynkMusic from './svg/wynkMusic.svg';
import download from './svg/download.svg';
import more from './svg/more.svg';
import airtelUpi from './svg/airtelUpi.svg';
import xstream_logo from './svg/xstream_logo.svg';
import celebration from './svg/celebration.svg';
import xtreamOfferBanner from './svg/xstreamOfferBanner.svg';
import fmfMandateBanner from './svg/fmfMandateBanner.svg';
import fmfBanner from './png/fmfBanner.png';


export const svg = {
  airtelblack,
  broadband,
  CancelIcon,
  CaretDown,
  ClockClockwise,
  CreditCard,
  dsl,
  Ewallets,
  Netbanking,
  postpaid,
  refresh,
  ShieldCheck,
  UpiTitle,
  paytmUpi,
  phonepeUpi,
  gPayUpi,
  bhimUpi,
  Trash,
  zeroOvercharge,
  removeIcon,
  tickmark,
  searchIcon,
  bookmark,
  plusIcon,
  warningIcon,
  successIcon,
  info,
  queryIcon,
  googlePlay,
  wynkMusic,
  download,
  more,
  airtelUpi,
  xstream_logo,
  celebration,
  xtreamOfferBanner,
  fmfMandateBanner,
  fmfBanner,
};
