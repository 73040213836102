/* eslint-disable max-len */

import { useActivityTracker } from './useActivityTracker';
import { usePayViaITunes } from './usePayViaITunes';
import { useHistory } from 'react-router-dom';
import { PLATFORM, SOURCE, EVENT } from '../constants';
import { getPaymentsUrl, isWebPlatform } from '../utils';

export const useContinueToPay = () => {
  const { updateActivity } = useActivityTracker();
  const payViaITunes = usePayViaITunes();
  const history = useHistory();

  return ({ selectedPlan, buildNo, sid, platform, hash, uid, did, service, circle }) => {
    if (platform.toLowerCase() === PLATFORM.IOS && selectedPlan.sku) {
      payViaITunes(
        {
          sid,
          platform,
          planId: selectedPlan.planId,
          skuId: selectedPlan.sku.itunes,
          hash,
          uid,
          deviceId: did,
        },
        Number(buildNo),
      );
    } else if (platform.toLowerCase() === PLATFORM.ANDROID) {
      history.push(
        getPaymentsUrl(sid,platform,{planId: selectedPlan.planId,buildNo,service,uid,circle,did})
      );
    } else if (isWebPlatform(platform)) {
      history.push(
        getPaymentsUrl(sid,platform,{planId: selectedPlan.planId,buildNo,service,uid,circle,did})
      );
    } else {
      const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}`;
      history.push(rediectedTo);
    }
    updateActivity({
      planId: selectedPlan.planId,
      sid: sid,
      event: EVENT.CONTINUE_TO_PAY,
      source: SOURCE.SUBSCRIPTION_PAGE,
      pageName: SOURCE.SUBSCRIPTION_PAGE,
      service: service,
      platform,
    });
  };
};
