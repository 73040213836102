import {
  VERIFY_CARD_NUMBER,
  VERIFY_UPI,
  CARD_DETAIL_VALID,
  FETCH_PAYU_INFO,
  FETCH_PROMO_CODE_INFO,
  FETCH_PAYMENT_OPTIONS,
  REMOVE_PROMO_CODE,
  VERIFY_PHONE_NUMBER,
  VERIFY_OTP,
  SAVED_OPTIONS,
  AFTER_ADD_MONEY_DEBIT,
  UNLINK_WALLET,
  DO_CHARGING,
  PHONE_PE_INSTALL,
  DISCOUNT_APPLIED,
  HOLDING_SCREEN_DATA,
  PAYMENT_PLAN_DETAILS,
  USER_INFO,
  SELECTED_PAYMENT_DETAILS,
} from './type';
import { callAPI } from '../../../../actions';
import { PAYMENT_API, PAYU, VERIFY_TYPE, PAYMENT_CLIENT, PRODUCT_TYPE } from '../constant';
import { HTTP_METHOD } from '../../../../constants';

/**
 * To verify card validation whether card is original or fake
 * through api call
 * @param {*} cardNo
 */
export const verifyCard = (cardNo, sessionId) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    VERIFY_CARD_NUMBER,
    PAYMENT_API.VERIFY_CARD + `/${sessionId}`,
    {
      payload: {
        verifyValue: cardNo,
        paymentCode: PAYU,
        verificationType: VERIFY_TYPE.CARD,
      },
    },
  );
};

/**
 * To verify mobile link at the time of link wallet
 * */

export const verifyPhoneNumber = ({ sessionId, paymentCode, walletUserId }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    VERIFY_PHONE_NUMBER,
    PAYMENT_API.VERIFY_WALLET_NUMBER + `${sessionId}`,
    {
      payload: {
        paymentCode,
        walletUserId,
      },
    },
  );
};

/**
 * To verify otp at the time of wallet link
 * */

export const verifyOtp = ({ sessionId, paymentCode, otp, walletUserId, otpToken }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    VERIFY_OTP,
    PAYMENT_API.VERIFY_OTP + `${sessionId}`,
    {
      payload: {
        paymentCode,
        otp,
        walletUserId,
        otpToken,
      },
    },
  );
};

/**
 * Unlink the wallet
 * */

export const unlinkWallet = ({ sessionId, paymentCode }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    UNLINK_WALLET,
    PAYMENT_API.UNLINK_WALLET_API + `${sessionId}`,
    {
      payload: {
        paymentCode,
      },
    },
  );
};

/**
 * Charge the wallet transaction
 * */

export const doChargingPhonepe = ({
  sessionId,
  couponId = null,
  paymentCode,
  planId,
  itemId,
  autoRenew = false,
  version,
  paymentId,
  paymentMode,
  trialOpted = false,
  ...args
}) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    DO_CHARGING,
    PAYMENT_API.CHARGE + `${sessionId}`,
    {
      payload: {
        paymentCode: paymentCode,
        purchaseDetails: {
          paymentDetails: {
            couponId: couponId,
            paymentMode: paymentMode,
            merchantName: '',
            paymentId: paymentId,
            trialOpted: trialOpted,
            autoRenew: autoRenew,
            ...args
          },
          productDetails: {
            planId: planId,
            itemId: itemId,
            type: itemId ? PRODUCT_TYPE.POINT : PRODUCT_TYPE.PLAN,
          },
        },
        phonePeVersionCode: version,
      },
    },
  );
};

/**
 * Direct debit money at the time of phonepe
 * */

export const doCharging = ({
  sessionId,
  couponId = null,
  paymentCode,
  planId,
  itemId,
  autoRenew = false,
  intent,
  paymentId,
  paymentMode,
  merchantName,
  trialOpted = false,
  customObj = {},
  ...args
}) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    DO_CHARGING,
    PAYMENT_API.CHARGE + `${sessionId}`,
    {
      payload: {
        paymentCode: paymentCode,
        purchaseDetails: {
          paymentDetails: {
            couponId: couponId,
            paymentId: paymentId,
            trialOpted: trialOpted,
            autoRenew: autoRenew,
            paymentMode: paymentMode,
            merchantName: merchantName,
            ...args
          },
          productDetails: {
            planId: planId,
            itemId: itemId,
            type: itemId ? PRODUCT_TYPE.POINT : PRODUCT_TYPE.PLAN,
          },
          ...customObj
        },
        intent: intent,
      },
    },
  );
};

/**
 * Direct debit money at the time of paytm
 * */

/**
 * call combined api to get all linked payments
 * */

export const savedOptions = ({ sessionId, planId, codes, couponId }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    SAVED_OPTIONS,
    PAYMENT_API.SAVED_OPTIONS + `${sessionId}`,
    {
      payload: {
        couponId,
        productDetails: { planId, type: planId ? PRODUCT_TYPE.PLAN : PRODUCT_TYPE.POINT },
        paymentGroups: codes,
      },
    },
  );
};

/**
 * call combined api to get all linked payments
 * */

export const afterAddMoneyCallback = ({ sessionId, version }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    AFTER_ADD_MONEY_DEBIT,
    PAYMENT_API.AFTER_ADD_MONEY_CALLBACK + `${sessionId}`,
    {
      payload: {
        phonePeVersionCode: version,
      },
    },
  );
};

/**
 * To update redux store if card is valid
 *  @param {*} info
 */

export const savedOptionsData = (info) => {
  return {
    type: SAVED_OPTIONS,
    payload: { info },
  };
};

/**
 * To update app install data
 *  @param {*} info
 */

export const phonepeAppInstallData = (info) => {
  return {
    type: PHONE_PE_INSTALL,
    payload: { info },
  };
};

/**
 * To update redux store if card is valid
 */
export const cardDetailVaild = (isValid) => {
  return {
    type: CARD_DETAIL_VALID,
    payload: { isValid },
  };
};

/**
 * apply promocode
 */

export const setDiscountData = (data) => {
  return {
    type: DISCOUNT_APPLIED,
    payload: { data },
  };
};

/**
 * To fetch payment payU detail
 * @param {*} planId
 */
export const fetchPayuInfo = ({
  sessionId,
  planId,
  paymentCode = PAYU,
  itemId,
  isAutoRenew = false,
  paymentMode,
  merchantName,
  isTrialOpted = false,
  couponId = null,
  paymentId,
  ...args
}) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    FETCH_PAYU_INFO,
    PAYMENT_API.CHARGE + sessionId,
    {
      payload: {
        paymentCode: paymentCode,
        purchaseDetails: {
          paymentDetails: {
            couponId: couponId,
            paymentMode: paymentMode,
            merchantName: merchantName,
            paymentId: paymentId,
            trialOpted: isTrialOpted,
            autoRenew: isAutoRenew,
            ...args
          },
          productDetails: {
            planId: planId,
            itemId: itemId,
            type: itemId ? PRODUCT_TYPE.POINT : PRODUCT_TYPE.PLAN,
          },
        },
      },
    },
  );
};

/**
 * To verify upi Id
 * @param {*} upiId
 */
export const verifyVPA = (upiId, sessionId) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    VERIFY_UPI,
    PAYMENT_API.VERIFY_CARD + `/${sessionId}`,
    {
      payload: {
        verifyValue: upiId,
        paymentCode: PAYU,
        verificationType: VERIFY_TYPE.UPI,
      },
    },
  );
};

/**
 * To fetch promo code info
 */
export const applyPromoCode = ({ sessionId, promoCode, planId, itemId }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.GET,
    FETCH_PROMO_CODE_INFO,
    `${PAYMENT_API.APPLY_COUPON}${sessionId}`,
    { params: { couponCode: promoCode, planId, itemId } },
  );
};

export const removePromo = ({ sessionId, promoCode }) => {
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.DELETE,
    REMOVE_PROMO_CODE,
    `${PAYMENT_API.REMOVE_COUPON}${sessionId}`,
    { params: { couponCode: promoCode } },
  );
};

/**
 * To fetch payment options
 */
export const getPaymentOptions = ({ sessionId, planId, itemId }) => {
  const payload  = {
    paymentOptionRequest : {
      productDetails: {
        planId: planId,
        itemId: itemId,
        type: itemId ? 'POINT' : 'PLAN',
      },
    }
  }
  return callAPI(
    PAYMENT_CLIENT,
    HTTP_METHOD.POST,
    FETCH_PAYMENT_OPTIONS,
    `${PAYMENT_API.PAYMENT_OPTIONS}${sessionId}`,
    { payload },
  );
};

/**
 * set holdingScreen params
 */

export const setHoldingScreenData = (data) => {
  return {
    type: HOLDING_SCREEN_DATA,
    payload: { data },
  };
};

/**
 * set plan details at payment page
 */

export const setPaymentPlanDetails = (data) => {
  return {
    type: PAYMENT_PLAN_DETAILS,
    payload: { data },
  };
};

/**
 * set basic routes info
 * @param {*} data
 */
export const userInfo = (data) => {
  return {
    type: USER_INFO,
    payload: data,
  };
};

/**
 * set selected payment option details
 * @param {*} data
 */
export const setSelectedPaymentDetails = (data) => {
  return {
    type: SELECTED_PAYMENT_DETAILS,
    payload: data,
  };
};
