import React from 'react';
import PropTypes from 'prop-types';
import default_icon from '../../assets/common/default_icon.png';
import './icons.scss';
import { useInternetStatus } from '../../Pages/Payments/common/hooks/useInternetStatus';

export const Partner = (props) => {
  const { iconUrl, logoUrl, name } = props;
  const {isLoadingError, setIsLoadingError} = useInternetStatus();

  return (
    <div className="partner-icon">
      <div className="img-container">
        <img src={isLoadingError ? default_icon : iconUrl || default_icon} alt="#" onError={()=>setIsLoadingError(true)}/>
      </div>
      {name && <label className="title">{name}</label>}
    </div>
  );
};

Partner.propTypes = {
  iconUrl: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
};
