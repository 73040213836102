import { SERVICE, DOCUMENT_INFO, DEFAULT_API_VERSION, PLATFORM, COUNTRY_CODES, DEFAULT_COUNTRY_CODE } from '../constants';
import { SHOW_ONLY_MONTHLY_PLANS } from '../Pages/Subscription/music/screens/ManageMusicSubscription/constants';
export * from './getDailyPackdata';
export * from './getServiceName';
export * from './getClientConfig';

export const getPaymentsUrl = (sid, platform, params) => {
  let url = `/payment-option/${sid}/${platform}`;
  if(params){
    url += '?';
    for(const key in params){
      if(params[key]){
        url=`${url}${key}=${params[key]}&`;
      }
    }
  }
  return url;
}
export const selectBestPlan = (plans) => {
  const result = {};
  for (let i = 0; i < plans.length; i++) {
    const plan = plans[i];
    if(SHOW_ONLY_MONTHLY_PLANS && plan.validity === 30){
      result.index = i;
      result.plan = plans[i];
      break;
    }
    if (plan.bestValue === true) {
      result.index = i;
      result.plan = plans[i];
    }
    if (i === plans.length - 1) {
      result.plan = plans[0];
    }
  }
  return { index: result.index, ...result.plan };
};

export const isXtreamService = (service) => {
  if(service === SERVICE.AIRTEL_TV || service === SERVICE.RAJ_TV){
    return true;
  }
  return false;
}

export const setDocumentInfo = (service) => {
  if(DOCUMENT_INFO[service]){
    document.title = DOCUMENT_INFO[service].title;
    const favicon = document.getElementById("favicon");
    favicon.href = DOCUMENT_INFO[service].favicon;
  }
}

export const isServicePartner = (service) => {
  const servicePartnerList = [
    SERVICE.RAJ_TV,
    SERVICE.ENTERR10,
    SERVICE.IQ_VIDEO
  ]
  return !!servicePartnerList.includes(service);
}

export const getQueryParams = () => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const url = window.location.href;
  const params = {};
  let match = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }
  return params;
};

export const getApiVersion = () => {
  const query = getQueryParams();
  const version = query.version ? query.version : DEFAULT_API_VERSION;
  return version;
}

export const debounce = (func, timeout=500) => {
  let timer = timeout;
  return (...args) => {
    if(timer == null) return;
    func.apply(this, [...args]);
    setTimeout(()=>{
      timer = timeout;
    },timer);
    timer=null;
  }
}
export const isWebPlatform = (platform) => {
    const { WEB, MWEBOS, TIZENOS, LGOS, ROKUOS, TVOS } = PLATFORM;
    const platformList = [WEB, MWEBOS, TIZENOS, LGOS, ROKUOS, TVOS];
    return platformList.includes(platform?.toLowerCase())
}

export const getCountryInfo = (countryCode) => {
  return COUNTRY_CODES[countryCode] ? COUNTRY_CODES[countryCode] : COUNTRY_CODES[DEFAULT_COUNTRY_CODE];
}

export const randomizeArray = (arr) => {
  const randomized = [...arr];
  for(let i = arr.length - 2; i > 0; i--){
    const j = Math.floor(Math.random() * (i + 1));
    [randomized[i], randomized[j]] = [randomized[j], randomized[i]]
  }
  return randomized
}
