/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import * as spinnerLottieImage from '../../../../../assets/lottie/lf20_N3BXE6.json';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeLoaderData } from '../../../../../actions';
import { useRedirectionalResult } from '../../../common/hooks';
import PropTypes from 'prop-types';
import { Offline, Loader } from '../../../../../common';
import { PLATFORM, BUILD_NUMBER } from '../../../../../constants';
import { PopupPlanView } from './components';
import { useQueryParams } from '../../../../../hooks';
import { setDocumentInfo, getFEBaseUrl } from '../../../../../utils';
import './index.scss';

const spinnerConfig = {
  loop: true,
  autoplay: true,
  animationData: spinnerLottieImage.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const RedirectPage = (props) => {
  const search = props.location.search;
  const fetchRedirectionalResult = useRedirectionalResult();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sid, setSID] = useState('');
  const history = useHistory();
  const [lottiesOptions] = useState(spinnerConfig);

  const query = useQueryParams();
  const hash = query.hash;
  const buildNo = query.buildNo;
  let theme = query.theme;
  theme = theme ? theme.toLowerCase() : theme;
  const uid = query.uid;
  const tms = query.tms;
  const deviceId = query.deviceId;
  const msisdn = query.msisdn;
  const view = query.view;
  const platform = query.os;
  const packGroup = query.packGroup;
  const appVersion = query.appVersion;
  const service = 'music';
  const dispatch = useDispatch();

  useEffect(() => {
    setDocumentInfo(service);
    dispatch(
      storeLoaderData({
        isLoaderVisible: true,
        options: lottiesOptions,
        theme: theme,
      }),
    );
  }, []);

  useEffect(() => {
    const headers = {
      'x-bsy-date': tms,
      'x-bsy-mg': decodeURIComponent(hash),
      'x-bsy-path': '/music/backward/init',
      'x-wynk-uid': uid,
    };

    const payload = {
      appId: 'mobility',
      appVersion: appVersion,
      buildNo: buildNo,
      deviceId: deviceId,
      msisdn: msisdn ? msisdn.trim() : '',
      os: platform,
      service: 'music',
      uid: uid,
      successUrl: '',
      failureUrl: '',
      packGroup: view === 'popup' ? packGroup : '',
      hash: hash,
    };
    const fetchData = async () => {
      const res = await fetchRedirectionalResult(headers, payload);
      if (res.error) {
        const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}`;
        history.push(rediectedTo);
        setError(res.error);
        dispatch(
          storeLoaderData({
            isLoaderVisible: !res.error,
            options: lottiesOptions,
            theme: theme,
          }),
        );
      } else {
        setIsLoading(res.isLoading);
        dispatch(
          storeLoaderData({
            isLoaderVisible: res.isLoading,
            options: lottiesOptions,
            theme: theme,
          }),
        );
        setError(res.error);
        const url = res.response.redirectUrl;
        const sid = res.response.sid;
        setSID(res.response.sid);
        const initialUrl = url.split('?')[0];
        const platform = initialUrl.substring(initialUrl.lastIndexOf('/') + 1);
        if (view.toLowerCase() === 'small') {
          if (platform.toLowerCase() === PLATFORM.IOS) {
            history.push(
              `/music/manage-subscription/${sid}/${platform}?theme=${theme}&uid=${uid}&hash=${hash}&deviceId=${deviceId}&buildNo=${buildNo}&tms=${tms}`,
            );
          } else if (platform.toLowerCase() === PLATFORM.ANDROID) {
            history.push(
              `/music/manage-subscription/${sid}/${platform}?theme=${theme}&uid=${uid}&hash=${hash}&deviceId=${deviceId}&buildNo=${buildNo}&tms=${tms}`,
            );
          } else {
            history.push(`/music/manage-subscription/${sid}/${platform}?theme=${theme}`);
          }
        } else if (view && view.toLowerCase() !== 'popup') {
          if (platform.toLowerCase() === PLATFORM.IOS) {
            if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_IOS) {
              window.webkit.messageHandlers.more.postMessage(
                `${getFEBaseUrl()}/#/music/subscriptions/${sid}/${platform}?theme=${theme}&uid=${uid}&hash=${hash}&deviceId=${deviceId}&buildNo=${buildNo}&tms=${tms}`,
              );
            } else {
              history.push(
                `/music/subscriptions/${sid}/${platform}?theme=${theme}&uid=${uid}&hash=${hash}&deviceId=${deviceId}&buildNo=${buildNo}&tms=${tms}`,
              );
            }
          } else if (platform.toLowerCase() === PLATFORM.ANDROID) {
            if (buildNo && Number(buildNo) < BUILD_NUMBER.MUSIC_ANDROID) {
              window.app.gotoOtherPaymentOption(
                `${getFEBaseUrl()}/#/music/subscriptions/${sid}/${platform}?theme=${theme}&uid=${uid}&hash=${hash}&deviceId=${deviceId}&buildNo=${buildNo}&tms=${tms}`,
              );
            } else {
              history.push(
                `/music/subscriptions/${sid}/${platform}?theme=${theme}&uid=${uid}&hash=${hash}&deviceId=${deviceId}&buildNo=${buildNo}&tms=${tms}`,
              );
            }
          } else {
            history.push(`/music/subscriptions/${sid}/${platform}?theme=${theme}`);
          }
        }
      }
    };
    if (hash) {
      fetchData();
    }
  }, [platform, view, hash, buildNo]);
  return (
    <>
      {error && <Offline />}
      {view && view === 'popup' && sid && !isLoading && !error && (
        <PopupPlanView
          sid={sid}
          platform={platform}
          hash={hash}
          deviceId={deviceId}
          uid={uid}
          buildNo={buildNo}
          service={service}
          tms={tms}
        />
      )}
    </>
  );
};

RedirectPage.propTypes = {
  location: PropTypes.object,
};
