export const lightTheme = {
  palette: {
    bg: '#ffffff',
    blue: '#0288d1',
    footer_bg: '#F9F9F9',
    header_bg: '#282F36',
    card_bg: '#ffffff',
    plan_card_bg: '#ffffff',
    theme_red: '#EB1D22',
    selected_plan_card_txt: '#EB1D22',
    offer_title: '#EB1D22',
    offer_desc: '#767676',
    active_plan_page_bg: '#ffffff',
    active_plan_card_bg: '#F9F9F9',
    active_plan_font_clr: '#282F36',
    active_plan_img_bg: '#1f272b',
    active_plan_footer_bg: '#E3E3E3',
    no_plan_bg: '#ffffff',
  },
  greyScale: {
    white: '#ffffff',
    gray_1: '#E3E3E3', // light_grey
    gray_2: '#767676', // dark_gray
    gray_3: '#282f36', // very_dark_grey
    gray_4: '#9DA0A3', // medium
  },
  fontWeight: ['300', '400', '500', '600', '700'],
  fontSize: {
    font_medium: '14px',
    font_large: '16px',
    font_small: '12px',
    font_extra_large: '40px',
    font_extra_small: '9px',
    font_very_small: '10px',
    font_more_large: '20px',
  },
};

export const darkTheme = {
  palette: {
    bg: '#151A1F',
    blue: '#0288d1',
    footer_bg: '#1F272B',
    header_bg: '#1F272B',
    card_bg: '#151A1F',
    plan_card_bg: '#151A1F',
    theme_red: '#EB1D22',
    selected_plan_card_txt: '#DEDEDE',
    offer_title: '#DEDEDE',
    offer_desc: '#818C94',
    active_plan_page_bg: '#0c0f12',
    active_plan_page_bg2: '#181926',
    active_plan_page_bg3: '#0D0D0D',
    active_plan_card_bg: '#151a1f',
    active_plan_card_bg2: '#1C1C1C',
    active_plan_font_clr: '#ffffff',
    active_plan_img_bg: '#1f272b',
    active_plan_footer_bg: '#1F272B',
    no_plan_bg: '#000000',
    cta: '#F5F5F5',
    ctaText: '#212121',
    newFooterBg: '#131313',
  },
  greyScale: {
    white: '#ffffff',
    gray_1: '#1F272B',
    gray_2: '#BEC1C2',
    gray_3: '#818C94',
    gray_4: '#9DA0A3',
  },
  fontWeight: ['300', '400', '500', '600', '700'],
  fontSize: {
    font_medium: '14px',
    font_large: '16px',
    font_small: '12px',
    font_extra_large: '40px',
    font_extra_small: '9px',
    font_very_small: '10px',
    font_more_large: '20px',
  },
};
