import {
  SELECTED_PLAN,
  ACTIVE_ACCORDIAN,
  ELIGIBLE_BUNDLES,
  ACTIVE_PLANS,
  ACTIVE_TAB,
  SELECTED_OFFER,
  ROUTE_INFO,
  HAS_FREE_TRIAL,
  SET_DEFAULT_PLAN,
  SET_DEFAULT_OFFER,
} from '../common/constants';
import {
  defaultActiveAccordian,
  defaultSelectedPlan,
  defaultEligibleBundles,
  defaultActivePlans,
  defaultActiveTab,
  defaultSelectedOffer,
  defaultRouteInfo,
  // defaultUserInfo,
  defaultFreeTrialJourney,
  defaultPurchasePlan,
} from './defaultState';

/**
 * To store a selected plan by user.
 * @param {*} state
 * @param {*} action
 */
export function selectedPlan(state = defaultSelectedPlan, action) {
  switch (action.type) {
    case SELECTED_PLAN: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store a selected offer by user.
 * @param {*} state
 * @param {*} action
 */
export function selectedOffer(state = defaultSelectedOffer, action) {
  switch (action.type) {
    case SELECTED_OFFER: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To save which accordian is active for a particular plan
 * @param {*} state
 * @param {*} action
 */
export function activeAccordian(state = defaultActiveAccordian, action) {
  switch (action.type) {
    case ACTIVE_ACCORDIAN: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * eligible Plans for the user
 * @param {*} state
 * @param {*} action
 */
export function eligibleBenefits(state = defaultEligibleBundles, action) {
  switch (action.type) {
    case ELIGIBLE_BUNDLES: {
      return action.payload;
    }
    default:
      return state;
  }
}

/**
 * active user Plans
 * @param {*} state
 * @param {*} action
 */
export function activePlans(state = defaultActivePlans, action) {
  switch (action.type) {
    case ACTIVE_PLANS: {
      return action.payload;
    }
    default:
      return state;
  }
}

/**
 * active tab on manage subscription
 * @param {*} state
 * @param {*} action
 */
export function activeTab(state = defaultActiveTab, action) {
  switch (action.type) {
    case ACTIVE_TAB: {
      return action.payload;
    }
    default:
      return state;
  }
}

/**
 * To store a route information.
 * @param {*} state
 * @param {*} action
 */
export function routeInfo(state = defaultRouteInfo, action) {
  switch (action.type) {
    case ROUTE_INFO: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store a route information.
 * @param {*} state
 * @param {*} action
 */
export function freeTrialFlow(state = defaultFreeTrialJourney, action) {
  switch (action.type) {
    case HAS_FREE_TRIAL: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store a plan information.
 * @param {*} state
 * @param {*} action
 */
export function purchaseDefaultPlan(state = defaultPurchasePlan, action) {
  switch (action.type) {
    case SET_DEFAULT_PLAN: {
      return Object.assign({}, state, action.payload);
    }
    case SET_DEFAULT_OFFER: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}
