import React from 'react';
import './icons.scss';

export const CheckMark = () => {
  return (
    <span className="chevron">
      <svg
        className="checked"
        id="Capa_1"
        enableBackground="new 0 0 515.556 515.556"
        height="512"
        viewBox="0 0 515.556 515.556"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
      </svg>
    </span>
  );
};
