import React from 'react';
import PropTypes from 'prop-types';
import selected_tick from '../../../../../../../assets/music-screens/selected_tick.png';
import unselected_tick from '../../../../../../../assets/music-screens/unselected_tick.png';
import { useSelector } from 'react-redux';
import './index.scss';

export const Plan = (props) => {
  const { plan, onSelectPlan } = props;
  const selectedPlan = useSelector((state) => state.selectedPlan.plan);

  return (
    <div
      onClick={() => onSelectPlan(plan)}
      className={`plan_container ${
        plan.planId === selectedPlan.planId ? 'select-plan-bg' : 'non-select-plan-bg'
      }`}
    >
      <div className="price-container">
        <div className="price-inner-container">
          {plan.planId === selectedPlan.planId ? (
            <img src={selected_tick} alt="" />
          ) : (
            <img src={unselected_tick} alt="" />
          )}

          <p className="origional-price">RS {plan.displayAmount}</p>
          <div className="discount-price">
            <span className="small">RS </span>
            <span className="large">{plan.total}</span>
          </div>
        </div>
        <p className="period">{plan.validityUnit}</p>
      </div>
    </div>
  );
};

Plan.propTypes = {
  plan: PropTypes.object,
  match: PropTypes.object,
  onSelectPlan: PropTypes.func,
};
