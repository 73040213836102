/* eslint-disable max-len */

import styled from 'styled-components';
import {rg_variables} from '../../styles/variables';

export const ConfirmPopupWrapper = styled.div.attrs({
  className: "confirm_popup_wrapper"
})`
  background: #eeeeee;
  padding: 29px;
  .confirm_popup_wrapper__header {
    margin-bottom: 32px;
    .confirm_popup_wrapper__header__logo {
      height: 72px;
      width: 72px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .confirm_popup_wrapper__content {
    margin-bottom: 32px;
    .label {
      font-weight: 500;
      font-size: 18px;

      color: #191919;
    }
    .description {
      font-size: 12px;
      margin-top: 16px;
      color: #666666;
    }
  }
  .confirm_popup_wrapper__footer {
    button{
      outline: none;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      &:first-child{
        margin-bottom: 15px;
      }
      &.forward {
        background: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.theme_red};
        color: ${(props) => props.subscription_theme ? props.subscription_theme.btnActiveTxt : rg_variables.outline_white};
        border: none;
      }
      &.cancel {
        border: 1px solid #212121;
        color: #212121;
      }
    }
  }
`;
