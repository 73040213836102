import styled from 'styled-components';
import { PLATFORM } from '../../../../../constants';
import star from '../../../../../assets/icons/star.svg';
import confetti from '../../../../../assets/icons/confetti.svg';

export const ActivePlanWrapper = styled.div`
  /*background: ${(props) => props.theme.palette.active_plan_page_bg};*/
  background: ${(props) => props?.colorPalette?.body};
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.os.toLowerCase() !== PLATFORM.IOS &&
    ` height: 100vh;
  height: -webkit-fill-available;  min-height:  100vh !important;`}

  .active-plan-card-wrapper {
    background: ${(props) => props?.colorPalette?.card || props.theme.palette.active_plan_card_bg};
    border-radius: 8px;
    margin-bottom: 8px;
    border: 0.5px solid #C9AD53;

    &.trial-plan-card-wrapper{
      padding: 12px;
      color: white;
      border-radius: 16px;
      background-color: linear-gradient(180deg, #161A1D 0%, #101111 100%);
      background-image: url(${confetti});
      background-repeat: repeat-x;
    }

    .trial-title{
      max-height: 50px;
      margin-bottom: 6px;
      h2{
        position: relative;
        text-align: center;
        font-size: 24px;
        font-style: italic;
        font-weight: 800;
        line-height: 36px;
        background: linear-gradient(80deg, #35E4C4 41.33%, #11B1A8 78.44%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        top: -15px;
        &.shadow{
          background: transparent;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 0.1px #C9AD5320;
          line-height: 23.5px;
        }
        &.upper{
          top: 2px;
        }
        &.lower{
          top: -30px;
        }
      }
    }
    .trial-desc{
      margin: auto auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: #E8EAED;
      font-size: 14px;
      justify-content: center;
      margin-bottom: 12px;
      max-width: 420px;
      &__center{
        li{
          text-align: center;
        }
      }
      li {
        list-style-image: url(${star});
        width: 50%;
        color:  #E8EAED;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }
    .trial-cta{
      width: 100%;
      max-width: 420px;
      margin-inline: auto;
      button{
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background: linear-gradient(184deg, #FAF1BA 3.3%, #EFD98E 47.56%, #C9AD53 96.69%);
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        /* reset button default */
        border: none;
      }
    }
  }

  .active-plan-header {
    display: flex;
    padding: 16px;
    box-sizing: border-box;
    img {
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  .description {
    .title {
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSize.font_medium};
      margin-bottom: 6px;
      color: ${(props) => props.theme.palette.active_plan_font_clr};
    }
    .subtitle {
      font-size: ${(props) => props.theme.fontSize.font_very_small};
      color: ${(props) => props.theme.palette.active_plan_font_clr};
      margin-bottom: 12px;
    }
    .validity-wrap {
      font-size: ${(props) => props.theme.fontSize.font_small};
      .validity {
        color: ${(props) => props.theme.greyScale.gray_4};
        margin-right: 8px;
      }
      .date {
        color: ${(props) => props.theme.palette.active_plan_font_clr};
      }
    }
  }

  .active-plan-footer {
    padding: 0px 8px 8px;
    .footer-content {
      padding: 8px;
      box-sizing: border-box;
      background: ${(props) =>
        props?.colorPalette?.footerBg || props.theme.palette.active_plan_footer_bg};
      padding: 16px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: ${(props) => props.theme.fontSize.font_very_small};
        color: ${(props) => props.theme.palette.active_plan_font_clr};
      }
      button {
        background: ${(props) => props?.colorPalette?.cta || props.theme.palette.blue};
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: ${(props) => props.theme.fontSize.font_small};
        color: ${(props) => props?.colorPalette?.ctaText || props.theme.greyScale.white};
        padding: 5px 12px;
        margin-left: 16px;
        min-width: 80px;
      }
    }
  }

  .cross-line {
    text-decoration: line-through;
  }

  .see-other-plan {
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.palette.blue};
    font-size: 14px;
    margin: 10px;
    font-weight: bold;
  }

  .renew-plan {
    font-style: italic;
  }
`;