import { PLATFORM, SERVICE, BUILD_NUMBER, LARGESCREEN } from '../constants';
import { getHomeUrl, isServicePartner, isWebPlatform } from '../utils';

export const useSuccessBack = () => {
  return (platform, status, service, buildNo, appId) => {
    try {
      if (platform.toLowerCase() === PLATFORM.ANDROID) {
        if (service === SERVICE.AIRTEL_TV) {
          //airteltv continue
          if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_ANDROID) {
            window.AirtelTVClient.navigateToHome('homepage');
          } else {
            window.AirtelTVClient.onExitWebView();
          }
        }else if (isServicePartner(service)) {
          //rajtv
          window.AirtelTVClient.onExitWebView();
        } else {
          // music
          window.app.exit();
        }
      } else if (platform.toLowerCase() === PLATFORM.IOS) {
        if (service === SERVICE.AIRTEL_TV) {
          // airteltv
          if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_IOS) {
            try {
              window.webkit.messageHandlers.navigateToHome.postMessage({
                navigateToHome: 'homepage',
              });
            } catch (e) {
              window.webkit.messageHandlers.claimedSubscriptions.postMessage({
                claimedSubscriptions: '',
              });
            }
          } else {
            try {
              window.webkit.messageHandlers.onExitWebView({ onExitWebview: status });
            } catch (e) {
              window.webkit.messageHandlers.back.postMessage({ back: status });
            }
          }
        }else if (isServicePartner(service)) {
          // rajtv
          try {
            window.webkit.messageHandlers.onExitWebView({ onExitWebview: status });
          } catch (e) {
            window.webkit.messageHandlers.back.postMessage({ back: status });
          }
        } else {
          // music
          window.webkit.messageHandlers.exit.postMessage({ exit: 'ios' });
        }
      } else if (isWebPlatform(platform)) {
          if (isServicePartner(service)) {
            window.location.href = getHomeUrl();
          }else if (service === SERVICE.MUSIC) {
            window.location.href = `${process.env.REACT_APP_WYNK_HOME_URL}`;
          }else{
            window.location.href = `${process.env.REACT_APP_XSTREAM_URL}/home`;
          }
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };
};
