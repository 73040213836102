import React, {useEffect, useState} from "react";

export const useInternetStatus = () => {
  const [isLoadingError, setIsLoadingError] = useState(false);
  useEffect(() => {
    window.addEventListener('online', () => {
      setIsLoadingError(false);
    });
    return () => {
      window.removeEventListener('online', ()=>{});
    };
  }, []);

  return { isLoadingError, setIsLoadingError };
};
