/* These build numbers are for new build,
    below these build numbers, build would be considered as old build */

export const BUILD_NUMBER = {
  MUSIC_ANDROID: 430,
  XSTREAM_ANDROID: 12675,
  MUSIC_IOS: 652,
  XSTREAM_IOS: 118,
  MUSIC_ANDROID_511_BG_COLOR: 511,
  MUSIC_IOS_980_BG_COLOR: 980,
  MUSIC_ANDROID_800_BG_COLOR: 800,
  MUSIC_IOS_2300_BG_COLOR: 2300,
  GPB_SUPPORTED_MUSIC: 1082,
  GPB_SUPPORTED_AIRTELTV: 12891,
  INTENT_CHECK_SAFE_MUSIC: 1036,
};
