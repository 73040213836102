import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { CircularTimer } from '../CircularTimer';
import { ConfirmPopup } from '../../../../../common/ConfirmPopup'
import { useHistory } from 'react-router-dom';
import {subscription_theme} from '../../../../../styles/theme';
import { useSelector } from 'react-redux';



export const HoldingScreen = (props) => {
  const { params, sid, platform, service } = props;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  let unblock, urlLocation;
  const unblockRef = useRef(null);
  const paymentDetails = useSelector((state) => state.selectedPaymentDetails);
  const [displayInfo, setDisplayInfo] = useState({
    heading: '',
    subHeading: ''
  })
  const onCancelPayment = () => {
    setModal(true);
  }

  const onCloseModal = () => {
    setModal(false);
  }

  const onCancelModal = () => {
    unblockRef.current();
    history.goBack();
  }

  useEffect(() => {
    if (paymentDetails?.upiApp) {
      const heading = `Please Open your ${paymentDetails.upiApp} App`;
      const subHeading = 'and complete the payment';
      setDisplayInfo({
        heading,
        subHeading,
      });
    }
  }, [paymentDetails]);
  useEffect(() => {
    unblockRef.current = history.block(tx => {
      if(tx.pathname.indexOf('/payment-option/') > -1 && !modal){
        urlLocation = tx;
        onCancelPayment();
        return false;
      }
    });
    return () => {
      unblockRef.current();
    }
  }, []);

  return (
    <>
      <div className="payment-hold-screen">
        <div className="payment-hold-screen__heading">
          {paymentDetails?.upiApp ? displayInfo.heading : (params.heading || "We are verifying your payment status...")}
          {/* {params.heading || displayInfo.heading || "We are verifying your payment status..."} */}
        </div>
        {displayInfo.subHeading && <div className="payment-hold-screen__sub-heading">
          {displayInfo.subHeading}
        </div>}
        <div className="payment-hold-screen__timer">
          <CircularTimer service={service} timerSeconds={params.timer} callback={params.callback}  />
        </div>
        <div className="payment-hold-screen__info">
          Don’t hit the back button
        </div>
        {false && <div className="payment-hold-screen__cancel" onClick={onCancelPayment}>
          Cancel payment
        </div>}
      </div>
      {modal && (
          <ConfirmPopup
            height={392}
            width={'100%'}
            backDropStatic={true}
            heading = {'Are you sure you want to cancel the transaction?'}
            okText = 'Yes'
            cancelText = 'No'
            onOkay = {onCancelModal}
            onCancel = {onCloseModal}
            okClass='cancel'
            cancelClass="forward"
            service={service}
          />
      )}
    </>
  );
};

HoldingScreen.propTypes = {
  params: PropTypes.object.isRequired,
  sid: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  service: PropTypes.string,
};
