import styled from 'styled-components';
export const ErrorScreenWrapper = styled.div`
  .active-plan-footer {
    .footer-content {
      background: ${(props) => props?.colorPalette?.cta || props.theme.palette.blue} !important;
      border-radius: 4px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: ${(props) => props.theme.fontSize.font_small};
      color: ${(props) => props?.colorPalette?.ctaText || props.theme.greyScale.white};
      padding: 5px 12px;
      margin-left: 16px;
      justify-content: center !important;
      padding: 14px !important;
    }
  }
`;

export const RuntimeErrorScreenWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  align-items: center;
  .error-wrapper {
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    .footer{
      margin-top: 16px;
      display: flex;
      gap: 8px;
      button{
        height: 36px;
        width: 100px;
        border: 0;
        
        &.retry{
          background: transparent;
          color: white;
          border: 1px solid;
        }
        &.exit{
          
        }
      }
    }
  }
`;
