import { BUILD_NUMBER, PLATFORM, SERVICE } from '../constants';
import { PAYMENT_FAILED } from '../Pages/Failure/constants';
import { useHistory } from 'react-router-dom';
import { getPaymentsUrl, getHomeUrl, isServicePartner, isWebPlatform } from '../utils';
import { useQueryParams } from './useQueryParams';
// import { SESSION_OUT } from '../Pages/TransactionStatus/constants';
// import { useQueryParams } from '.';

export const useFailureBack = () => {
  const history = useHistory();
  const query = useQueryParams();
  // const urlParams = useParams();
  // const {service, buildNo, planId, appId} = query

  return ({  platform, status, service, buildNo, sid, state, planId, appId, theme = 'light', version }) => {
    // const getSubscriptionRedirectUrl = (service, theme='') => {
    //   const redirectUrl = `/${service}/subscriptions/${sid}/${platform.toLowerCase()}?appId=${appId}&buildNo=${buildNo}${
    //     theme ? `&theme=${theme}` : ''}${version ? `&version=${version}` : ''}`;
    //   return redirectUrl;
    // }
    // const {platform, sid} = urlParams;
    // console.log({platform, status, service, buildNo, sid, state, planId, appId,})
    try {
      if (platform.toLowerCase() === PLATFORM.ANDROID) {
        if (service === SERVICE.AIRTEL_TV) {
          //airteltv
          if (state === PAYMENT_FAILED) {
            history.push(getPaymentsUrl(sid, platform.toLowerCase(), {
              ...query,
              planId,
              buildNo,
              service,
              appId,
              version,
            }));
            // history.push(getSubscriptionRedirectUrl('airteltv'));
          } else if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_ANDROID) {
            window.AirtelTVClient.navigateToHome('homepage');
          } else {
            window.AirtelTVClient.onExitWebView();
          }
        }else if (isServicePartner(service)) {
          //airteltv
          if (state === PAYMENT_FAILED) {
            history.push(getPaymentsUrl(sid, platform.toLowerCase(), {
              ...query,
              planId,
              buildNo,
              service,
              appId,
              version,
            }));
            // history.push(getSubscriptionRedirectUrl(service));
          } else {
            window.AirtelTVClient.navigateToHome('homepage');
          }
        } else {
          // music
          if (state === PAYMENT_FAILED) {
            history.push(
              getPaymentsUrl(sid, platform.toLowerCase(), {
                ...query,
                planId,
                buildNo,
                service,
                appId,
                theme,
                version,
              }),
            );
            // history.push(getSubscriptionRedirectUrl('music', theme));
          } else {
            window.app.exit();
          }
        }
      } else if (platform.toLowerCase() === PLATFORM.IOS) {
        try {
          window.webkit.messageHandlers.back.postMessage({ back: status });
        } catch (e) {
          // on fail
          try {
            // for back button
            window.webkit.messageHandlers.retry.postMessage({ retry: 'ios' });
          } catch (e) {
            // new xstream client app bridge back
            window.webkit.messageHandlers.onSubscriptionComplete.postMessage({
              onSubscriptionComplete: status,
            });
          }
        }
      } else if (isWebPlatform(platform)) {
        if (state === PAYMENT_FAILED) {
          history.push(getPaymentsUrl(sid, platform.toLowerCase(), {
            ...query,
            planId,
            buildNo,
            service,
            appId,
            version,
          }));
          // history.push(getSubscriptionRedirectUrl(service));
        } else {
          if (isServicePartner(service)) {
            window.location.href = getHomeUrl();
          }else if (service === SERVICE.MUSIC) {
            window.location.href = `${process.env.REACT_APP_WYNK_HOME_URL}`;
          }else{
            window.location.href = `${process.env.REACT_APP_XSTREAM_URL}/home`;
          }
        }
      } else {
        return;
      }
    } catch (e) {
      return;
    }
  };
};
