export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const SELECTED_PLAN = 'SELECTED_PLAN';
export const ELIGIBLE_BUNDLES = 'ELIGIBLE_BUNDLES';
export const ACTIVE_PLANS = 'ACTIVE_PLANS';
export const ACTIVE_TAB = 'ACTIVE_TAB';
export const ACTIVE_ACCORDIAN = 'ACTIVE_ACCORDIAN';
export const FETCH_SUBSCRIPTION_STATUS = 'FETCH_SUBSCRIPTION_STATUS';

export const SELECTED_OFFER = 'SELECTED_OFFER';
export const UNSUBSCRIBE_PLAN = 'UNSUBSCRIBE_PLAN';

export const TAB_TYPES = {
  SUBSCRIBED_PACK: 'Subscribed Packs',
  RECOMMENDED_PACK: 'Recommended Packs',
};

export const ROUTE_INFO = 'ROUTE_INFO';

export const HAS_FREE_TRIAL = 'HAS_FREE_TRIAL';

export const SET_DEFAULT_PLAN = 'SET_DEFAULT_PLAN';

export const SET_DEFAULT_OFFER = 'SET_DEFAULT_OFFER';