import { useEffect, useState } from 'react';

export const useQueryParams = () => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const url = window.location.href;
  const params = {};
  let match = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }
  return params;
};
