import React from 'react';
import PropTypes from 'prop-types';
import './icons.scss';

export const Cross = ({ width, height, color }) => {
  return (
    <span>
      <svg
        width={width}
        height={height}
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.418 0.259185C12.3355 
    0.179725 12.2374 0.116682 12.1295 0.0736661C12.0215 
    0.0306504 11.9058 0.00850821 11.7889 0.00850821C11.672 0.00850821 11.5562 
    0.0306504 11.4483 0.0736661C11.3403 0.116682 11.2423 0.179725 11.1597 0.259185L6.79721 
    4.43919L2.43471 0.250185C2.26785 0.0899943 2.04153 -1.68788e-09 1.80555 0C1.56956 
    1.68788e-09 1.34325 0.0899943 1.17638 0.250185C1.00952 0.410376 0.915771 0.627641 
    0.915771 0.854185C0.915771 1.08073 1.00952 1.29799 1.17638 1.45819L5.53888 
    5.64618L1.17638 9.83519C1.01509 9.99644 0.926092 10.212 0.928501 10.4355C0.93091 
    10.6591 1.02453 10.8728 1.18927 11.0308C1.354 11.1888 1.57671 11.2785 1.80957 
    11.2806C2.04243 11.2828 2.26688 11.1972 2.43471 11.0422L6.79721 6.85419L11.1597 
    11.0422C11.2423 11.1215 11.3404 11.1844 11.4484 11.2273C11.5563 11.2703 11.672 
    11.2924 11.7889 11.2924C11.9057 11.2924 12.0214 11.2703 12.1294 11.2273C12.2373 
    11.1844 12.3354 11.1215 12.418 11.0422C12.5007 10.9629 12.5662 10.8687 12.6109 
    10.7651C12.6556 10.6614 12.6787 10.5504 12.6787 10.4382C12.6787 10.326 12.6556 
    10.2149 12.6109 10.1113C12.5662 10.0077 12.5007 9.9135 12.418 9.83418L8.05555 
    5.64618L12.418 1.45819C12.5811 1.2979 12.6724 1.08274 12.6724 0.858685C12.6724 
    0.634634 12.5811 0.41947 12.418 0.259185Z"
          fill={color}
        />
      </svg>
    </span>
  );
};

Cross.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

Cross.defaultProps = {
  width: '13',
  height: '12',
  color: '#9E9E9E',
};
