export const CURRENCY = {
  INR: {
    symbol: '₹'
  },
  'Pound Str': {
    symbol: '£'
  },
  SGD: {
    symbol: 'S$ '
  },
  GBP: {
    symbol: '£'
  },
  USD: {
    symbol: 'US$ '
  },
  CAD: {
    symbol: 'C$ '
  },
  PKR: {
    symbol: 'Rs '
  },
  BDT: {
    symbol: '৳'
  },
  MVR: {
    symbol: 'MVR '
  },
}

export const DEFAULT_CURRENCY = 'INR';
