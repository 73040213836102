import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '../../../../common/Carousel';
import { Icons } from '../../../../common';
import { dateInParts } from '../../../../common/utils/DateUtils';
import checkMark from '../../../../assets/icons/checkmark.png';

import './index.scss';

export const ValuePackSuccess = (props) => {
  const { Icon, Chevron } = Icons;
  const { subscriptionStatus, onSuccess } = props;
  const channelImages = [];
  subscriptionStatus.packDetails.benefits.channelsBenefits.forEach((channelBenefit) => {
    if (!channelBenefit.notVisible) channelImages.push(channelBenefit.logo);
  });
  const carouselData = {
    banner: {
      settings: {
        dots: true,
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        infinite: true,
        speed: 1000,
        swipeToSlide: true,
      },
      images: subscriptionStatus.packDetails.benefits.rails.slice(0, 5),
    },
    channel: {
      settings: {
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 500,
        cssEase: 'linear',
        infinite: true,
        speed: 1000,
        swipeToSlide: true,
      },
      images: channelImages,
    },
  };

  if (channelImages.length <= 3) {
    carouselData.channel.settings.infinite = false;
    carouselData.channel.settings.autoplay = false;
    carouselData.channel.settings.slidesToShow = 2;
  }

  const getDateString = (date) => {
    const dateObj = dateInParts(date, { day: '2-digit', month: 'long', year: 'numeric' });
    return `${dateObj.month.value} ${dateObj.day.value}, ${dateObj.year.value}`;
  };

  return (
    <React.Fragment>
      <div className="value_pack_wrapper">
        <div className="value_pack_wrapper__header">
          <Carousel
            settings={carouselData.banner.settings}
            imageData={carouselData.banner.images}
          />
          <div className="bottom_gradient"></div>
        </div>
        <div className="value_pack_wrapper__content">
          <div className="tick_logo">
            <div className="image">
              <Icon imgUrl={checkMark} />
            </div>
          </div>
          <p className="value_pack_wrapper__content__heading">Payment successful</p>
          <p className="value_pack_wrapper__content__subs">
            Your subscription to <b>{subscriptionStatus.packDetails.title}</b> has started.
          </p>
          <p className="value_pack_wrapper__content__channel_head">
            You have unlocked all these channels
          </p>
          <div className="channel_carousel_wrapper">
            <Carousel
              settings={carouselData.channel.settings}
              imageData={carouselData.channel.images}
            />
          </div>
          <p className="validity">Valid till {getDateString(subscriptionStatus.validity)}</p>
          <p className="transaction">Transaction ID {subscriptionStatus.tid}</p>
          <button className="footer_button" onClick={onSuccess}>
            CONTINUE WATCHING
            <Chevron />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

ValuePackSuccess.propTypes = {
  subscriptionStatus: PropTypes.object,
  onSuccess: PropTypes.func,
};
