import { DEFAULT_CURRENCY } from "../../../constants";

export const defaultCardVerification = { valid: true };
export const defaultCardDetail = { isValid: false };
export const defaultPayUInfo = { info: null };
export const defaultSavedOptions = { info: [] };
export const defaultAppInstall = {
  info: {
    resultCode: 2,
  },
};
export const defaultDiscountData = { info: {} };
export const defaultUserInfo = {};

export const defaultPaymentPlanDetails = {
  data: {}
};
