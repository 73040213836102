export const usePayViaGoogleMusic = () => {
  return (paymentMetaMap) => {
    try {
      window.app.googleAlert({
        paymentMetaMap: paymentMetaMap,
      });
    } catch (e) {
      console.log(e);
      return;
    }
  };
};
