import React from 'react';
import PropTypes from 'prop-types';
import icon from '../../assets/common/Frame_3022.png';
import ExclaimationGrey from '../../assets/failure-screens/exclaimation_grey.png';
import back_arrow from '../../assets/failure-screens/backback_arrow.png';
import green_offer from '../../assets/failure-screens/green_offer.png';
import { getPackDurationDisplayData } from '../../utils/getDailyPackdata';
import { Chevron } from '../../common/Icons';
import {CURRENCY} from '../../constants';
import {ButtonWrapper} from './style';
import {subscription_theme} from '../../styles/theme';

export const BottomModal = (props) => {
  const {
    iconUrl,
    title,
    subtitle,
    description,
    buttonText,
    onButtonClick,
    theme,
    header,
    plan,
    buttonArrow,
    hidePayBar,
    planId,
    service,
  } = props;

  const service_theme = subscription_theme[service] || subscription_theme['default'];

  return (
    <ButtonWrapper subscription_theme={subscription_theme[service] || subscription_theme['default']}>
      <div className={`bottom-modal-wrapper ${theme ? theme : ''}`}>
        {header && (
          <div className="header">
            {!hidePayBar && (
              <div className="row">
                <div>
                  <img
                    onClick={() => onButtonClick(planId)}
                    className="back_arrow"
                    src={back_arrow}
                  />
                  <span className="price-text">To pay {`${CURRENCY[plan.currency].symbol}${plan.amount}`}</span>
                </div>
                {false && (
                  <div>
                    <img className="green_offer" src={green_offer} />
                    <span className="offer-text">OFFERS</span>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div>
                <img className="icon" src={plan.combo ? plan.benefits.logo : plan.benefits.icon} />
                <span className="icon-text">{plan.title}</span>
              </div>
              {plan.type !== 'FREE' && (
                <div className="month">
                  <span className="month-text">{getPackDurationDisplayData(plan).heading}</span>
                  <span className="month-price-text">
                    {`${CURRENCY[plan.currency].symbol}${getPackDurationDisplayData(plan).subHeading}`}
                  </span>
                </div>
              )}
              {plan.type === 'FREE' && (
                <div className="month">
                  <span className="month-text">{`${
                    getPackDurationDisplayData(plan).heading
                  } FREE`}</span>
                  <span className="month-price-text">{`${CURRENCY[plan.currency].symbol}${plan.paidPack.amount} after the free trial`}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="contents">
          {!React.isValidElement(iconUrl) && (
            <img src={iconUrl || (theme === 'dark' ? ExclaimationGrey : icon)} alt="" />
          )}
          {React.isValidElement(iconUrl) && iconUrl}
          {title && <div className="title">{title}</div>}
          {subtitle && <div className="subtitle">{subtitle}</div>}
          {description && <div className="description">{description}</div>}
        </div>
        <button onClick={() => onButtonClick(planId)}>
          {buttonArrow ? <Chevron color={service_theme.bottom_modal[theme.toLowerCase()].chevron_color} /> : ''}
          {buttonText}
        </button>
      </div>
    </ButtonWrapper>
  );
};

BottomModal.propTypes = {
  iconUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  theme: PropTypes.string,
  header: PropTypes.bool,
  buttonArrow: PropTypes.bool,
  plan: PropTypes.object,
  hidePayBar: PropTypes.bool, // hack for RG-1833
  service: PropTypes.string,
};
