import React from 'react';
import PropTypes from 'prop-types';
import selected_tick from '../../../../../assets/music-screens/selected_tick.png';

export const Plan = (props) => {
  const { plan } = props;

  return (
    <div className="plan select-plan-bg">
      <div className="price-container">
        <div className="price-inner-container">
          <img src={selected_tick} alt="" />
          <p className="origional-price">RS {plan.displayAmount}</p>
          <div className="discount-price">
            <span className="small">RS </span>
            <span className="large">{plan.total}</span>
          </div>
        </div>
        <p className="period">{plan.validityUnit}</p>
      </div>
    </div>
  );
};

Plan.propTypes = {
  plan: PropTypes.object,
};
