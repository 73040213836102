import { PLATFORM } from '../constants';
import { useHistory } from 'react-router-dom';
import { getFEBaseUrl } from '../utils';

export const useMusicExtendNow = () => {
  const history = useHistory();
  return ({ platform, url }) => {
    const URL = `${getFEBaseUrl()}/#${url}`;
    try {
      if (platform.toLowerCase() === PLATFORM.ANDROID) {
        window.app.gotoOtherPaymentOption(URL);
      } else if (platform.toLowerCase() === PLATFORM.IOS) {
        window.webkit.messageHandlers.more.postMessage(URL);
      } else if (
        platform.toLowerCase() === PLATFORM.WEB ||
        platform.toLowerCase() === PLATFORM.MWEBOS
      ) {
        history.push(url);
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };
};
