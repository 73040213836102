import { useEffect, useState } from 'react';

export const useReplaceMacros = () => {
  return (str, macrosList, values) => {
    for(const key in macrosList){
      str = str.replace(new RegExp(macrosList[key], "g"),values[key] ? values[key] : "")
    }
    return str;
  };
};
