import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './style.scss';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

export const Carousel = (props) => {
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { settings = defaultSettings, imageData } = props;
  return (
    <Slider {...settings}>
      {imageData.length &&
        imageData.map((url) => (
          <div key={url} className="image-box">
            <img src={url} />
          </div>
        ))}
    </Slider>
  );
};

Carousel.propTypes = {
  settings: PropTypes.any,
  imageData: PropTypes.array,
};
