import styled from 'styled-components';

export const PriceWrapper = styled.div`
  display: flex;
  font-weight: ${(props) => props.textWeight};
  color: ${(props) => props.color};

  .rupee {
    font-size: ${(props) => `${props.rupeeSize}px`};
    line-height: ${(props) => `${props.rupeeSize}px`};
    align-self: flex-start;
  }

  .price {
    font-size: ${(props) => `${props.priceSize}px`};
    line-height: 0.75em;
    text-decoration: ${(props) => `${props.isCross ? 'line-through' : 'none'}`};
  }

  .unit {
    font-size: ${(props) => `${props.unitSize}px`};
    line-height: ${(props) => `${props.unitSize}px`};
    align-self: flex-end;
    text-decoration: ${(props) => `${props.isUnitCross ? 'line-through' : 'none'}`};
  }
`;
