/* eslint-disable max-len */
import { useActivityTracker } from './useActivityTracker';
import { usePayViaGoogleMusic } from './usePayViaGoogleMusic';
import { useHistory } from 'react-router-dom';
import { PLATFORM, SOURCE, EVENT, BUILD_NUMBER } from '../constants';
import { useToastDataUpdate } from './useUpdateToast';
import { useDispatch } from 'react-redux';
import { postMusicPaymentDataRequirement } from '../Pages/Subscription/common/actions';
import { getPaymentsUrl, getFEBaseUrl } from '../utils';

export const useRedirectMusicContinueToPay = () => {
  const dispatch = useDispatch();
  const updateToast = useToastDataUpdate();
  const { updateActivity } = useActivityTracker();
  const payViaGoogleMusic = usePayViaGoogleMusic();
  const history = useHistory();

  const getNumberOfDays = (str) => {
    switch (str.toUpperCase()) {
      case 'YEARLY':
        return 365;
      case 'QUARTERLY':
        return 180;
      case 'MONTHLY':
        return 30;
    }
  };

  return ({ selectedPlan, buildNo, uid, deviceId, hash, sid, platform, service, tms, circle }) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    const payload = {
      sid: sid,
      planId: selectedPlan.planId,
      uid: uid,
      deviceId: deviceId,
      hash: decodeURIComponent(hash),
      tms: tms,
      os: platform,
    };
    if (platform.toLowerCase() === PLATFORM.IOS && selectedPlan.sku) {
      dispatch(postMusicPaymentDataRequirement(headers, payload))
        .then((res) => {
          if (buildNo && Number(buildNo) < BUILD_NUMBER.MUSIC_IOS) {
            window.webkit.messageHandlers.payAction.postMessage(selectedPlan.sku.itunes);
          } else {
            window.webkit.messageHandlers.payAction.postMessage({
              paymentMetaMap: {
                sid,
                platform,
                skuId: selectedPlan.sku.itunes,
                planId: selectedPlan.planId,
              },
            });
          }
        })
        .catch((err) => {
          updateToast({ type: 'failure', message: err.message });
        });
    } else if (platform.toLowerCase() === PLATFORM.ANDROID) {
      const feBaseUrl = getFEBaseUrl();
      window.app.gotoOtherPaymentOption(
        `${feBaseUrl}/#${getPaymentsUrl(sid,platform,{planId: selectedPlan.planId,buildNo,service,uid,circle,did: deviceId})}`,
      );
    } else if (
      platform.toLowerCase() === PLATFORM.WEB ||
      platform.toLowerCase() === PLATFORM.MWEBOS
    ) {
      history.push(
        getPaymentsUrl(sid,platform,{planId: selectedPlan.planId,buildNo,service,uid,circle,did: deviceId})
      );
    } else {
      const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}`;
      history.push(rediectedTo);
    }
    updateActivity({
      planId: selectedPlan.planId,
      event: EVENT.CONTINUE_TO_PAY,
      source: SOURCE.SUBSCRIPTION_PAGE,
      pageName: SOURCE.SUBSCRIPTION_PAGE,
      service: service,
      platform,
    });
  };
};
