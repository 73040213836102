import {
  NEW_VERIFY_CARD_NUMBER,
  NEW_VERIFY_CARD_NUMBER_SUCCESS,
  NEW_VERIFY_CARD_NUMBER_FAIL,
  CARD_DETAIL_VALID,
  SAVED_OPTIONS,
  PHONE_PE_INSTALL,
  DISCOUNT_APPLIED,
  HOLDING_SCREEN_DATA,
  PAYMENT_PLAN_DETAILS,
  USER_INFO,
  SELECTED_PAYMENT_DETAILS,
  PAYMENT_OPTIONS_DETAILS,
  SHOW_SPINNER_LOADER,
  SAVED_RECOMMENDED_OPTIONS,
  SELECTED_PAYMENT_ID,
  NEW_SAVED_PAYMENT_DETAILS,
  NEW_VERIFY_CARD_NUMBER_DEFAULT,
  NEW_TOAST_INFO,
  USER_CHOICE_BILLING,
  STORE_PAYMENT_FLOW,
  UPDATE_EXT_TXN_TOKEN,
} from '../actions/type';
import {
  defaultCardDetail,
  defaultCardVerification,
  defaultSavedOptions,
  defaultAppInstall,
  defaultDiscountData,
  defaultUserInfo,
  defaultPaymentPlanDetails,
  defaultNewToastInfo,
  defaultAlternateBillingDetails,
} from './default';

export function newCardVerificationDetail(state = defaultCardVerification, action) {
  switch (action.type) {
    case NEW_VERIFY_CARD_NUMBER: {
      return Object.assign({}, state, action);
    }
    case NEW_VERIFY_CARD_NUMBER_SUCCESS: {
      return Object.assign({}, state, action.payload.data.data);
    }
    case NEW_VERIFY_CARD_NUMBER_FAIL:
      return Object.assign({}, { valid: false, autoRenewSupported: true });
    case NEW_VERIFY_CARD_NUMBER_DEFAULT:
      return Object.assign({}, { valid: false, autoRenewSupported: true });
    default:
      return state;
  }
}

export function cardDetailIsValid(state = defaultCardDetail, action) {
  switch (action.type) {
    case CARD_DETAIL_VALID: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newDiscountApplied(state = defaultDiscountData, action) {
  switch (action.type) {
    case DISCOUNT_APPLIED: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newSavedOptionsData(state = defaultSavedOptions, action) {
  switch (action.type) {
    case SAVED_OPTIONS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newPhonePeInstalled(state = defaultAppInstall, action) {
  switch (action.type) {
    case PHONE_PE_INSTALL: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newHoldingScreenData(state = {}, action) {
  switch (action.type) {
    case HOLDING_SCREEN_DATA: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newPaymentPlanDetails(state = defaultPaymentPlanDetails, action) {
  switch (action.type) {
    case PAYMENT_PLAN_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store a route information.
 * @param {*} state
 * @param {*} action
 */
export function newUserInfo(state = defaultUserInfo, action) {
  switch (action.type) {
    case USER_INFO: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newSelectedPaymentDetails(state = defaultUserInfo, action) {
  switch (action.type) {
    case SELECTED_PAYMENT_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newPaymentOptionsDetails(state = {}, action) {
  switch (action.type) {
    case PAYMENT_OPTIONS_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function newSavedPaymentDetails(state = {}, action) {
  switch (action.type) {
    case NEW_SAVED_PAYMENT_DETAILS: {
      return action.payload;
    }
    default:
      return state;
  }
}

export function newRecommendedOptions(state = [], action) {
  switch (action.type) {
    case SAVED_RECOMMENDED_OPTIONS: {
      return action.payload;
    }
    default:
      return state;
  }
}

export function selectedPaymentId(state = '', action) {
  switch (action.type) {
    case SELECTED_PAYMENT_ID: {
      return action.payload;
    }
    default:
      return state;
  }
}

export function showSpinnerLoader(state = false, action) {
  switch (action.type) {
    case SHOW_SPINNER_LOADER: {
      return !!action.payload;
    }
    default:
      return state;
  }
}

export function newToastInfo(state = defaultNewToastInfo, action) {
  switch (action.type) {
    case NEW_TOAST_INFO: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function selectedUCB(state = '', action) {
  switch (action.type) {
    case USER_CHOICE_BILLING: {
      return action.payload;
    }
    default:
      return state;
  }
}

export function showPaymentFlow(state = '', action) {
  switch (action.type) {
    case STORE_PAYMENT_FLOW: {
      return action.payload;
    }
    default:
      return state;
  }
}

export function AlternateBillingDetails(state = defaultAlternateBillingDetails, action) {
  switch (action.type) {
    case UPDATE_EXT_TXN_TOKEN: {
      return {
        ...state,
        externalTransactionToken: action.payload,
      };
    }
    default:
      return state;
  }
}
