export const COUNTRY_CODES = {
  IN: {
    name: 'India',
    tnc: 'https://www.wynk.in/assets/webview/tos.html/?showBack=true',
    policy: 'https://www.wynk.in/assets/webview/privacyPolicy.html/?showBack=true',
    gst: '18%',
  },
  SG: {
    name: 'Singapore',
    tnc: 'https://www.wynk.in/assets/webview/tos.html/?showBack=true',
    policy: 'https://www.wynk.in/assets/webview/privacyPolicy.html/?showBack=true',
    gst: '',
  },
};

export const DEFAULT_COUNTRY_CODE = 'IN';
