import styled, {css} from 'styled-components';

export const flex_between = () => css`
  display: flex;
  justify-content: space-between;
`

export const flex_align_center = () => css`
  display: flex;
  align-items: center;
`

export const flex_center = () => css`
  display: flex;
  justify-content: center;
`

export const flex_between_center = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const flex_all_center = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const flex_coloumn_center = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const flex_center_end = () => css`
  display: flex;
  align-items: center;
  justify-content: flex_end;
`

export const flex_wrap_center = () => css`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`

export const border_box = () => css`
  box-sizing: border-box;
`

export const ellipsis = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const payments_header = (borderBottomColor) => css`
  display: flex;
  justify-content: space-between;
  background-color: #333333;
  border-bottom: 2px solid ${borderBottomColor};
  height: 54px;
  align-items: center;
  padding: 0 16px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  @media screen and (max-width: 420px) {
    height: 26px;
    padding: 0 8px;
    font-size: 10px;
    border-bottom: 1px solid ${borderBottomColor};
  }
`

export const payments_container = () => css`
  background-color: #212121;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
`
