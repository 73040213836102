import { useDispatch, useSelector } from 'react-redux';
import { selectPlan, selectOffer } from '../actions';
import { SOURCE, EVENT, SERVICE } from '../../../../constants';
import { useActivityTracker } from '../../../../hooks';
import { selectBestPlan, isXtreamService, isServicePartner } from '../../../../utils';

export const useDefaultPlanSelection = () => {
  const dispatch = useDispatch();
  const alreadySelectedPlan = useSelector((state) => state.selectedPlan);
  const alreadySelectedOffer = useSelector((state) => state.selectedOffer);
  const { updateActivity } = useActivityTracker();

  /**
   * To select the best plan from all plans
   * @param {*} plans
   */
  return (subscriptionData, service, sid, platform) => {
    /**
     * check first plan is already selected or not and
     * also check whether we have subscription data or not
     */
    // !TODO: XSTREAM AND PARTNER SERVICES ARE NOT MANAGED BY WCF ANYMORE, NEED TO REVIEW AND REFACTOR THIS CODE.
    if (isXtreamService(service) || isServicePartner(service)) {
      if (
        Object.keys(subscriptionData).length !== 0 &&
        subscriptionData.eligibleBenefits.length === 1
      ) {
        if (
          !alreadySelectedOffer.id ||
          alreadySelectedOffer.id === subscriptionData.eligibleBenefits[0].id
        ) {
          const benefits = subscriptionData.eligibleBenefits[0];
          const bestPlan = selectBestPlan(benefits.plans);
          bestPlan.partnerName = benefits.title ? benefits.title : benefits.partnerName;
          bestPlan.partnerIcon = benefits.partnerIcon ? benefits.partnerIcon : null;
          bestPlan.partnerLogo = benefits.partnerLogo ? benefits.partnerLogo : null;
          bestPlan.offerId = benefits.id;
          bestPlan.trialPlans = benefits.trialPlans;
          dispatch(selectOffer(subscriptionData.eligibleBenefits[0]));
          updateActivity({
            planId: bestPlan.planId,
            offerId: bestPlan.offerId,
            packageName: bestPlan.partnerName,
            sid: sid,
            event: EVENT.SELECT_PLAN,
            source: SOURCE.SUBSCRIPTION_PAGE,
            pageName: SOURCE.SUBSCRIPTION_PAGE,
            service: service,
            isDefaultPlan: true,
            platform,
          });
          if (alreadySelectedPlan.planNumber === '') {
            dispatch(selectPlan(bestPlan, bestPlan.planId));
          }
        }
      } else {
        if (alreadySelectedPlan.planNumber !== '') {
          // find offer which is related to plan
          let validOffer = null;
          subscriptionData.eligibleBenefits.forEach((offer) => {
            const plan = offer.plans.find((plan) => plan.planId === alreadySelectedPlan.planNumber);
            if (plan) {
              validOffer = offer;
            }
          });
          if (validOffer && validOffer.id != alreadySelectedOffer.id) {
            dispatch(selectOffer(validOffer));
          }
        }
      }
    } else {
      if (
        Object.keys(subscriptionData).length !== 0 &&
        alreadySelectedPlan.planNumber === '' &&
        subscriptionData.eligibleBenefits.length
      ) {
        const benefits = subscriptionData.eligibleBenefits[0];
        dispatch(selectOffer(subscriptionData.eligibleBenefits[0]));
        const bestPlan = selectBestPlan(benefits.plans);
        // bestPlan.partnerName = benefits.partnerName ? benefits.partnerName : benefits.title;
        bestPlan.partnerName = benefits.title ? benefits.title : benefits.partnerName;
        bestPlan.partnerIcon = benefits.partnerIcon ? benefits.partnerIcon : null;
        bestPlan.partnerLogo = benefits.partnerLogo ? benefits.partnerLogo : null;
        bestPlan.offerId = benefits.id;
        bestPlan.meta = benefits.meta;
        dispatch(selectPlan(bestPlan, bestPlan.planId));
      }
    }
  };
};
