import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryString = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(new URLSearchParams());
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setSearchParams(search);
  }, [location.search]);

  return searchParams;
};
