import { useDispatch } from 'react-redux';
import { getCombinedSubscriptions } from '../actions';

export const useCombinedSubscription = () => {
  const dispatch = useDispatch();
  return (sessionId) => {
    return dispatch(getCombinedSubscriptions(sessionId))
      .then((res) => {
        return { isLoading: false, error: null, combinedPlans: res.payload.data.data };
      })
      .catch((err) => {
        return { isLoading: false, error: err, combinedPlans: {} };
      });
  };
};
