import { createHashHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { axiosMiddleware } from './axiosMiddleware';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

export const history = createHashHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk, axiosMiddleware, routerMiddleware(history)];

function configureStore(initialState = {}) {
  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
  return store;
}

export default configureStore();
