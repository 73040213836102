import { useDispatch } from 'react-redux';
import { postPaymentDataRequirement } from '../Pages/Subscription/common/actions';
import { BUILD_NUMBER } from '../constants';
import { useToastDataUpdate } from './useUpdateToast';

export const usePayViaITunes = () => {
  const dispatch = useDispatch();
  const updateToast = useToastDataUpdate();
  return (paymentMetaMap, buildNo) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    const payload = {
      sid: paymentMetaMap.sid,
      planId: paymentMetaMap.planId,
      uid: paymentMetaMap.uid,
      deviceId: paymentMetaMap.deviceId,
      os: paymentMetaMap.platform,
    };
    try {
      if (buildNo < BUILD_NUMBER.XSTREAM_IOS) {
        dispatch(postPaymentDataRequirement(headers, payload))
          .then((res) => {
            window.webkit.messageHandlers.payAction.postMessage({
              payAction: paymentMetaMap.skuId,
            });
          })
          .catch((err) => {
            updateToast({ type: 'failure', message: err.message });
          });
      } else {
        window.webkit.messageHandlers.payActions.postMessage({ payActions: paymentMetaMap });
      }
    } catch (e) {
      console.log('getting exception');
      console.log(e);
      return;
    }
  };
};
