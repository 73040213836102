import { PLATFORM, SERVICE } from '../constants';

export const useSendEventToApp = () => {
  return (eventData) => {
    const appMeta = {
      event_type: eventData.event,
      meta: eventData,
    };
    try {
      if (eventData?.service === SERVICE.MUSIC) {
        console.log('SendEventToApp = ', appMeta);
        if (eventData?.platform.toLowerCase() === PLATFORM.ANDROID) {
          window.app.sendEvents(JSON.stringify(appMeta));
        } else if (eventData?.platform.toLowerCase() === PLATFORM.IOS) {
          window.webkit.messageHandlers.sendEvents.postMessage(appMeta);
        } else {
          return;
        }
      } else {
        return;
      }
    } catch (e) {
      console.log('e = ', e);
      return;
    }
  };
};
