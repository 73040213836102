import { PLATFORM, MOBILE_STATUS, SERVICE } from '../constants';
import { useHistory } from 'react-router-dom';

export const usePaymentBack = () => {
  const history = useHistory();
  return ({ platform, status, service, buildNo, musicCallback, ingressIntent, itemId }) => {
    try {
      if (
        (ingressIntent?.includes('quick_plan') || itemId) &&
        platform?.toLowerCase() === PLATFORM.ANDROID &&
        service?.toLowerCase() === SERVICE.MUSIC
      ) {
        window.app.exit();
      } else if (musicCallback) {
        window.app.exit();
      } else {
        window.AirtelTVClient.onSubscriptionComplete(status);
      }
    } catch (e) {
      // commmenting out.... its not required as we are using goBack() in final block
      // if (platform.toLowerCase() === PLATFORM.ANDROID) {
      //   try {
      //     // window.app.showConfirmationDialog();
      //     window.history.back();
      //   } catch (err) {
      //     console.log(err);
      //   }
      // } else if (
      //   platform.toLowerCase() === PLATFORM.WEB ||
      //   platform.toLowerCase() === PLATFORM.MWEBOS
      // ) {
      //   history.goBack();
      // }
    } finally {
      history.goBack();
    }
  };
};
