import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { CheckMark } from '../Icons';
import { CheckboxWrapper } from './style';

export const CheckBox = (props) => {
  const { isActive, onClick, tag, type } = props;
  return (
    <CheckboxWrapper subscription_theme={props.theme}>
      <div className={type === 'default' ? 'check-box-wapper-default' : 'check-box-wapper-custom'}>
        <span onClick={onClick} className={`check-box ${isActive ? 'active' : ''}`}>
          {isActive && <CheckMark />}
        </span>
        {tag && <label className="txt">{tag}</label>}
      </div>
    </CheckboxWrapper>
  );
};

CheckBox.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tag: PropTypes.string,
  type: PropTypes.string,
};
