/**
 * to get current month
 */
export const getCurrentMonth = () => {
  return new Date().getMonth() + 1;
};

/**
 * get current year
 */
export const getCurrentYear = () => {
  return new Date().getFullYear();
};

/**
 * get current date
 */
export const getCurrentDate = () => {
  return new Date();
};

/**
 * format date
 */
export const formatDate = (date, options, lang) => {
  return new Intl.DateTimeFormat(lang || 'en', options).format(date);
};

/**
 * get date as object
 */

export const dateInParts = (date, options, lang) => {
  const dateArr =  new Intl.DateTimeFormat(lang || 'en', options).formatToParts(date);
  const dateObj = {};
  dateArr.forEach((item) => {
    dateObj[item.type] = item
  })
  return dateObj;
};
