import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { HoldingScreen } from '../../common/components/HoldingScreen';
import { AppBar } from '../../../../common';
import { useSubscriptionStatus } from '../../../Subscription/common/hooks';
import { setHoldingScreenData } from '../../common/actions';
import { useToastDataUpdate } from '../../../../hooks';
import {CURRENCY} from '../../../../constants';
import {isServicePartner, setDocumentInfo} from '../../../../utils';

export const PaymentTimer = (props) => {
  const { platform } = props.match.params;
  const { sid } = props.match.params;
  const search = props.location.search;
  const query = new URLSearchParams(search);
  const updateToastData = useToastDataUpdate();
  const subtitle = query.get('subtitle');
  let itemId = query.get('itemId');
  let planId = query.get('planId');
  let freeTrialAvailable = query.get('freeTrialAvailable');
  const service = query.get('service');
  const buildNo = query.get('buildNo');
  const version = query.get('version');
  const dispatch = useDispatch();
  const history = useHistory();
  const holdingScreenData = useSelector((state) => state.holdingScreenData.data);
  const pId = useSelector((state) => state.selectedPlan.plan.planId);
  const planDetails = useSelector((state) => state.paymentPlanDetails.data);
  const discountApplied = useSelector((state) => state.discountApplied);
  const fetchSubscriptionStatus = useSubscriptionStatus();

  const statusTimer = useRef(null);
  const redirectUrlRef = useRef(null);

  if (itemId) {
    planId = 0;
    freeTrialAvailable = false;
  } else {
    planId = planId ? planId : pId;
    planId = Number(planId);
    freeTrialAvailable = planDetails?.freeTrialAvailable;
    itemId = '';
  }

  useEffect(() => {
    setDocumentInfo(service);
    if (!planDetails || !holdingScreenData || !holdingScreenData.show) {
      history.goBack();
    } else {
      fetchStatus();
    }
    return () => {
      clearInterval(statusTimer.current);
      dispatch(setHoldingScreenData(null));
    };
  }, []);

  const redirectToSessionOut = () => {
    const redirectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}${version ? `&version=${version}` : ''}`;
    history.push(redirectedTo);
  };

  const checkStatusAndRedirect = (subscription) => {
    if (subscription) {
      const { transactionStatus, redirectUrl } = subscription;
      if (transactionStatus !== 'INPROGRESS') {
        window.location.href = redirectUrl;
      }
    }
    return false;
  };

  const callbackAfterTimer = async () => {
    clearInterval(statusTimer);
    try {
      const res = await fetchSubscriptionStatus(sid);
      if (res.error) {
        redirectToSessionOut();
      } else {
        if (res?.subscription?.data?.redirectUrl) {
          window.location.href = res.subscription.data.redirectUrl;
        } else {
          redirectToSessionOut();
        }
      }
    } catch (e) {
      console.log('jkdhfhekwh ', e);
      updateToastData({ type: 'failure', message: 'Please try after sometime!' });
    }
    // redirectUrlRef.current
    //   ? (window.location.href = redirectUrlRef.current)
    //   : redirectToSessionOut();
  };

  const fetchStatus = async () => {
    try {
      const res = await fetchSubscriptionStatus(sid);
      if (res.error) {
        redirectToSessionOut();
      } else {
        if (res.subscription) {
          if (!checkStatusAndRedirect(res.subscription?.data)) {
            // polling
            statusTimer.current = setInterval(async (e) => {
              const res = await fetchSubscriptionStatus(sid);
              if (res.error) {
                redirectToSessionOut();
              } else {
                if (res.subscription) {
                  redirectUrlRef.current = res.subscription?.data.redirectUrl;
                  checkStatusAndRedirect(res.subscription?.data);
                } else {
                  redirectToSessionOut();
                }
              }
            }, holdingScreenData.statusPollInterval * 1000);
          }
        } else {
          redirectToSessionOut();
        }
      }
    } catch (e) {
      updateToastData({ type: 'failure', message: 'Please try after sometime!' });
    }
  };

  const getTitle = () => {
    const isTvodClient = isServicePartner(service);
    if (isTvodClient && planDetails.title) {
      return planDetails.title;
    }
    return planDetails.partnerName;
  }
  
  return (
    <div className="subscription__wrapper">
      <div className="header">
        {planDetails && (
          <AppBar
            leftTitle={getTitle()}
            subtitle={subtitle}
            month={planDetails.month}
            planData={planDetails}
            perMonthValue={planDetails.perMonthValue}
            rightTitle={
              planDetails.freeTrialAvailable
                ? '1 MONTH FREE'
                : `To pay ${CURRENCY[planDetails.currency].symbol}${planDetails.discountedPrice}`
            }
            // discount_change
            leftLogo={planDetails.partnerLogo}
            rightSubtitle={
              planDetails.freeTrialAvailable
                ? `${CURRENCY[planDetails.currency].symbol}${planDetails.discountedPrice} after the free trial`
                : null
            }
          />
        )}
      </div>
      {holdingScreenData && (
        <HoldingScreen
          params={{ ...holdingScreenData, callback: callbackAfterTimer }}
          sid={sid}
          platform={platform}
          service={service}
        />
      )}
    </div>
  );
};

PaymentTimer.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sid: PropTypes.string.isRequired,
      platform: PropTypes.string,
      planId: PropTypes.string,
      itemId: PropTypes.string,
    }),
  }),
};
