/* eslint-disable no-unused-vars */
import React from 'react';
import './App.scss';
import { Navigator } from '../routes';
import { ToastContainer, Loader } from '../common';
import { ErrorBoundaryWithTracker } from './ErrorBoundary';
import { RuntimeError } from '../common/ErrorScreen/RuntimeError';

function App() {
  return (
    <div className="App">
      <ErrorBoundaryWithTracker fallback={<RuntimeError />} >
      <div>
        <ToastContainer />
        <Loader />
        <Navigator />
      </div>
      </ErrorBoundaryWithTracker>
    </div>
  );
}
export default App;
