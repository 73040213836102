import React from 'react';
import PropTypes from 'prop-types';
import './icons.scss';

export const Icon = (props) => {
  const { imgUrl, tag } = props;
  return (
    <span className="icon">
      <img src={imgUrl} alt="#" />
      {tag && <span>{tag}</span>}
    </span>
  );
};

Icon.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  tag: PropTypes.string,
};
