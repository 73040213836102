import playIcon from '../../../../../assets/icons/subPremium/play.svg';
import downloadIcon from '../../../../../assets/icons/subPremium/download.svg';
import htIcon from '../../../../../assets/icons/subPremium/ht.svg';
import adIcon from '../../../../../assets/icons/subPremium/ad.svg';
import hdIcon from '../../../../../assets/icons/subPremium/hd.svg';
import verifiedIcon from '../../../../../assets/icons/subPremium/verified.svg';
import crown from '../../../../../assets/icons/subPremium/crown.svg';

const DOWNLOADS = {
  title: 'Free Downloads',
  icon: downloadIcon,
  description: 'Download unlimited songs and listen without internet',
  id: 1,
  trackingId: 'DOWNLOADS',
};
const AD_FREE = {
  title: 'Ad-Free Experience',
  icon: adIcon,
  description: 'Enjoy uninterrupted content with our ad-free experience',
  id: 2,
  trackingId: 'AD_FREE',
};
export const UNLIMITED = {
  title: 'Unlimited Streaming',
  icon: playIcon,
  description: 'Dive into a world of endless music with unlimited streaming',
  id: 3,
  trackingId: 'UNLIMITED',
};
const HD = {
  title: 'HD Quality Music',
  icon: hdIcon,
  description: 'Enjoy High Definition Music for all Songs',
  id: 4,
  trackingId: 'HD',
};
export const HT_SLIDE = {
  title: 'Unlimited Hellotunes',
  icon: htIcon,
  description: 'Set endless melodies with unlimited Hellotunes',
  id: 5,
  trackingId: 'HT_SLIDE',
};
const VERIFIED = {
  title: 'Verified Badge',
  icon: verifiedIcon,
  description: 'Gives more authenticity and visibility to your profile',
  id: 6,
  trackingId: 'VERIFIED',
};
const VIP = {
  title: 'Access Without Limits',
  icon: crown,
  description: 'Unlock all premium features on the app for a seamless experience',
  id: 7,
  trackingId: 'VIP',
};

export const INGRESS_INTENTS = {
  DOWNLOAD: 'download',
  SPECIALHT: 'specialht',
  HELLOTUNE: 'hellotune',
  DEFAULT: 'default',
  GET_VERIFIED: 'get_verified',
  BEST_OF_WYNK: 'bestofwynk',
  _BP: 'bp',
  AD: 'ad',
};

// this is 0-based index, in every map ht is iserted based on whether user is Airtel or not so separate index for ht
export const HT_SLIDE_INDEX = {
  [INGRESS_INTENTS.DOWNLOAD]: 1,
  [INGRESS_INTENTS.SPECIALHT]: 0,
  [INGRESS_INTENTS.HELLOTUNE]: 0,
  [INGRESS_INTENTS.AD]: 1,
  [INGRESS_INTENTS.DEFAULT]: 1,
  [INGRESS_INTENTS.GET_VERIFIED]: 3,
  [INGRESS_INTENTS.BEST_OF_WYNK]: 2,
  [INGRESS_INTENTS._BP]: 2,
};

export const SLIDE_INTENT_MAP = {
  [INGRESS_INTENTS.DOWNLOAD]: [DOWNLOADS, AD_FREE, VERIFIED, HD, VIP, UNLIMITED],
  [INGRESS_INTENTS.SPECIALHT]: [DOWNLOADS, AD_FREE, VERIFIED, HD, VIP, UNLIMITED],
  [INGRESS_INTENTS.HELLOTUNE]: [DOWNLOADS, AD_FREE, VERIFIED, HD, VIP, UNLIMITED],
  [INGRESS_INTENTS.AD]: [AD_FREE, DOWNLOADS, VERIFIED, HD, VIP, UNLIMITED],
  [INGRESS_INTENTS.GET_VERIFIED]: [VERIFIED, DOWNLOADS, AD_FREE, HD, VIP, UNLIMITED],
  [INGRESS_INTENTS.BEST_OF_WYNK]: [VIP, DOWNLOADS, AD_FREE, VERIFIED, HD, UNLIMITED],
  [INGRESS_INTENTS._BP]: [VIP, DOWNLOADS, AD_FREE, VERIFIED, HD, UNLIMITED],
  [INGRESS_INTENTS.DEFAULT]: [DOWNLOADS, AD_FREE, VERIFIED, HD, VIP, UNLIMITED],
};


export const SHOW_ONLY_MONTHLY_PLANS = true;
export const BENEFIT_LIST = [
  AD_FREE, DOWNLOADS, HD, VERIFIED, VIP
]
