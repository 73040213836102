import {CURRENCY} from '../constants';

export const getPackDurationDisplayData = (plan) => {
  let data = {};
  if (plan.freeTrialAvailable && plan.trialPlans && plan.trialPlans.length) {
    let trialData = plan.trialPlans.filter((item) => item.planId === plan.trialPlanId);
    if (trialData[0].day) {
      data['normalText'] = `${trialData[0].day} ${trialData[0].day > 1 ? 'DAYS' : 'DAY'} FREE`;
    } else {
      data['normalText'] = `${trialData[0].month} ${
        trialData[0].month > 1 ? 'MONTHS' : 'MONTH'
      } FREE`;
    }
  }

  if (plan.day) {
    data['combinedText'] = `${plan.day} Day${plan.day > 1 ? 's' : ''} (${CURRENCY[plan.currency].symbol}${plan.dailyAmount}/day)`;
    data['heading'] = `${plan.day} ${plan.day > 1 ? 'DAYS' : 'DAY'}`;
    data['subHeading'] = `${plan.dailyAmount}/day`;
    data['amount'] = `${plan.dailyAmount}`;
    data['textLowerCase'] = `${plan.day} ${plan.day > 1 ? 'Days' : 'Day'}`;
  } else {
    data['combinedText'] = `${plan.month} Month${plan.month > 1 ? 's' : ''} (${CURRENCY[plan.currency].symbol}${
      plan.perMonthValue
    }/month)`;
    data['heading'] = `${plan.month} ${plan.month > 1 ? 'MONTHS' : 'MONTH'}`;
    data['subHeading'] = `${plan.perMonthValue}/mo`;
    data['amount'] = `${plan.perMonthValue}`;
    data['textLowerCase'] = `${plan.month} ${plan.month > 1 ? 'Months' : 'Month'}`;
  }
  return { ...data };
};
