import React from 'react';
import { SERVICE } from '../constants';
import { debounce } from '../utils';

const payViaGooglePlay = (paymentMetaMap, service, callback) => {
  const payload = {
    sid: paymentMetaMap.sid ?? '',
    ...(paymentMetaMap.planId ? {plan_id: paymentMetaMap.planId} : {}),
    ...(paymentMetaMap.itemId ? {item_id: paymentMetaMap.itemId} : {}),
    sku_id: paymentMetaMap.sku_id ?? '',
    sub_type: paymentMetaMap.sub_type ?? '',
    intent: paymentMetaMap?.ingressIntent ?? '',
  };

  if (callback && typeof callback === 'function') {
    callback();
  }

  try {
    const paymentInfo = JSON.stringify(payload);
    if (service === SERVICE.MUSIC) {
      window.app.initiateGPBTransaction(paymentInfo);
    } else {
      window.AirtelTVClient.initiateGPBTransaction(paymentInfo);
    }
  } catch (e) {
    console.log('getting exception');
    console.log(e);
    return;
  }
};
export const usePayViaGooglePlay = () => {
  return debounce(payViaGooglePlay, 500);
};
