import {
  VERIFY_CARD_NUMBER,
  VERIFY_CARD_NUMBER_SUCCESS,
  VERIFY_CARD_NUMBER_FAIL,
  CARD_DETAIL_VALID,
  SAVED_OPTIONS,
  PHONE_PE_INSTALL,
  DISCOUNT_APPLIED,
  HOLDING_SCREEN_DATA,
  PAYMENT_PLAN_DETAILS,
  USER_INFO,
  SELECTED_PAYMENT_DETAILS,
} from '../common/actions/type';
import {
  defaultCardDetail,
  defaultCardVerification,
  defaultSavedOptions,
  defaultAppInstall,
  defaultDiscountData,
  defaultUserInfo,
  defaultPaymentPlanDetails,
} from './default';

export function cardVerificationDetail(state = defaultCardVerification, action) {
  switch (action.type) {
    case VERIFY_CARD_NUMBER: {
      return Object.assign({}, state);
    }
    case VERIFY_CARD_NUMBER_SUCCESS: {
      return Object.assign({}, state, action.payload.data.data);
    }
    case VERIFY_CARD_NUMBER_FAIL:
      return Object.assign({}, state, { valid: false });
    default:
      return state;
  }
}

export function cardDetailIsValid(state = defaultCardDetail, action) {
  switch (action.type) {
    case CARD_DETAIL_VALID: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function discountApplied(state = defaultDiscountData, action) {
  switch (action.type) {
    case DISCOUNT_APPLIED: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function savedOptionsData(state = defaultSavedOptions, action) {
  switch (action.type) {
    case SAVED_OPTIONS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function phonePeInstalled(state = defaultAppInstall, action) {
  switch (action.type) {
    case PHONE_PE_INSTALL: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function holdingScreenData(state = {}, action) {
  switch (action.type) {
    case HOLDING_SCREEN_DATA: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function paymentPlanDetails(state = defaultPaymentPlanDetails, action) {
  switch (action.type) {
    case PAYMENT_PLAN_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store a route information.
 * @param {*} state
 * @param {*} action
 */
export function userInfo(state = defaultUserInfo, action) {
  switch (action.type) {
    case USER_INFO: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

export function selectedPaymentDetails(state = defaultUserInfo, action) {
  switch (action.type) {
    case SELECTED_PAYMENT_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}