/* eslint-disable max-len */

import styled from 'styled-components';
import {rg_variables} from '../../styles/variables';

export const ButtonWrapper = styled.div.attrs({
  className: "button-wrapper"
})`
  .btn{
    display: flex;
    justify-content: center;
    border: 1px solid ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.theme_red};
    border-radius: 4px;
    height: 32px;
    cursor: pointer;
    .txt {
      color: ${rg_variables.white};
      font-weight: 500;
      font-size: ${rg_variables.font.small};
      align-items: center;
      display: flex;
      text-transform: uppercase;

      &.hide {
        display: none;
      }
      &.deactive {
        color: ${rg_variables.light_grey_2};
      }
    }
    &.flex-row-reverse {
      flex-direction: row-reverse;
    }
    &.active {
      background-color: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.theme_red};
      .txt {
        color: ${(props) => props.subscription_theme ? props.subscription_theme.btnActiveTxt : rg_variables.white};
      }
    }
    &.deactive {
      background-color: ${rg_variables.light_grey_1};
      border: 1px solid ${rg_variables.light_grey_1};
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;

export const CheckboxWrapper = styled.div.attrs({
  className: "checkbox-wrapper"
})`
  .check-box-wapper-default {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .check-box {
      height: 15px;
      width: 15px;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      border: 1px solid ${rg_variables.white};
      cursor: pointer;
      // -webkit-tap-highlight-color: transparent;
      &.active {
        background-color: ${rg_variables.grey};
        border-color: ${rg_variables.grey};
        // -webkit-tap-highlight-color: transparent;
      }
    }
    .txt {
      color: ${rg_variables.white};
      padding: 0px 8px;
      line-height: 20px;
      font-size: 16px;
    }
  }
  .check-box-wapper-custom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .check-box {
      // -webkit-tap-highlight-color: transparent;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      border: 1px solid ${rg_variables.white};
      // -webkit-tap-highlight-color: transparent;
      &.active {
        background-color: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.theme_red};
        border-color: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.theme_red};
        // -webkit-tap-highlight-color: transparent;
      }
      .checked{
        filter: ${(props) => props.subscription_theme ? props.subscription_theme.checkbox_tick_filter : rg_variables.white_filter};
      }
    }
    .txt {
      color: ${rg_variables.white};
      padding: 0px 5px;
      line-height: 20px;
      font-size: ${rg_variables.font.small};
    }
  }
  @media only screen and (max-width: 420px) {
    .check-box-wapper-default {
      .txt {
        font-size: ${rg_variables.font.small};
      }
    }
  }
`;

export const RadioButtonWrapper = styled.div.attrs({
  className: "radio-button-wrapper"
})`
  float: left;
  margin-top: 4px;
  .radio-button {
    position: relative;
    float: right;
    cursor: pointer;
    // -webkit-tap-highlight-color: transparent;
    .circle {
      height: 19px;
      width: 18px;
      border-radius: 50%;
      top: -3px;
      position: absolute;
      &.active {
        right: 0;
        background-color: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.red_1};
      }
      &.deactive {
        left: 0;
        background-color: ${rg_variables.light_grey_2};
      }
    }
    .slide {
      opacity: 0.5;
      width: 31px;
      height: 13px;
      border-radius: 8px;
      &.active {
        background-color: ${(props) => props.subscription_theme ? props.subscription_theme.theme_color : rg_variables.red_1};
      }
      &.deactive {
        background-color: ${rg_variables.light_grey_2};
      }
    }
  }
  .txt {
    color: ${rg_variables.outline_white};
    font-size: 16px;
    float: left;
    line-height: 13px;
    padding: 0px 9px;
  }
  @media only screen and (max-width: 420px) {
    .txt {
        font-size: ${rg_variables.font.small};
    }
  }
`;

export const CircularButtonWrapper = styled.div.attrs({
  className: "circular-button-wrapper"
})`
  .add-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23.5px;
    .add-btn {
      width: 24px;
      height: 24px;
      background: ${rg_variables.grey_0};
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
    label {
      font-weight: 500;
      font-size: 20px;
      color: #ffffff;
      display: flex;
      align-self: center;
      padding: 2px 8px;
      font-family: ${rg_variables.font_family.medium};
    }
  }
  .add-btn-wrapper-upi {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .add-btn {
      width: 16px;
      height: 16px;
      background: ${rg_variables.grey_0};
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 8px;
        height: 8px;
      }
    }
    label {
      font-weight: 500;
      font-size: 16px;
      color: #666666;
      display: flex;
      align-self: center;
      padding: 2px 8px;
      font-family: ${rg_variables.font_family.medium};
    }
  }
  @media only screen and (max-width: 420px) {
    .add-btn-wrapper {
      .add-btn {
        width: 16px;
        height: 16px;
        img {
          width: 8px;
          height: 8px;
        }
      }
      label {
        font-size: 12px;
      }
    }

    .add-btn-wrapper-upi {
      label {
        font-size: 12px;
      }
    }
  }
`;
