import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icons } from '../../common';
import tick from '../../assets/icons/tick.svg';
import {
  PLATFORM,
  MOBILE_STATUS,
  SOURCE,
  EVENT,
  BUILD_NUMBER,
  PACK_TYPE,
  SERVICE,
} from '../../constants';
import PropTypes from 'prop-types';
import { useSubscriptionStatus } from '../Subscription/common/hooks';
import { Loader } from '../../common/Loader';
import { Offline } from '../../common/Offline';
import { useActivityTracker, useSuccessBack, usePRLaunchEvent } from '../../hooks';
import * as spinnerLottieImage from '../../assets/lottie/lf20_N3BXE6.json';
import { useDispatch } from 'react-redux';
import { storeLoaderData } from '../../actions';
import { ValuePackSuccess } from './components/valuePack';
import { ChannelSuccess } from './components/channel';
import './success.scss';
import { isServicePartner, isWebPlatform, isXtreamService, setDocumentInfo } from '../../utils';
import { subscription_theme } from '../../styles/theme';

const spinnerConfig = {
  loop: true,
  autoplay: true,
  animationData: spinnerLottieImage.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Success = (props) => {
  const { Icon } = Icons;
  const { platform, sid } = props.match.params;
  const search = props.location.search;
  const [lottiesOptions] = useState(spinnerConfig);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isChannel, setIsChannel] = useState(false);
  const history = useHistory();
  const successBack = useSuccessBack();
  const fetchSubscriptionStatus = useSubscriptionStatus();
  const dispatch = useDispatch();
  const prEvent = usePRLaunchEvent();
  const { updateActivity } = useActivityTracker();
  const query = new URLSearchParams(search);
  const service = query.get('service');
  const buildNo = query.get('buildNo');
  const appId = query.get('appId');
  const planId = query.get('planId');
  const version = query.get('version');
  const unblockRef = useRef(null);

  const onSuccess = () => {
    successBack(platform, MOBILE_STATUS.SUCCESS, service, buildNo, appId);
  };

  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  useEffect(() => {
    setDocumentInfo(service);
    const fetchData = async () => {
      dispatch(
        storeLoaderData({
          isLoaderVisible: true,
          options: lottiesOptions,
          theme: 'dark',
        }),
      );

      const res = await fetchSubscriptionStatus(sid);
      if (res.error) {
        const rediectedTo = `/txn-state/session-out/${sid}/${platform}?service=${service}${
          version ? `&version=${version}` : ''
        }`;
        history.push(rediectedTo);
      } else {
        const subscription = res.subscription && res.subscription.data;
        if (subscription && subscription.transactionStatus === 'FAILURE') {
          const rediectedTo = `/txn-state/payment-failed/${sid}/${platform}?service=${service}&buildNo=${buildNo}&appId=${appId}${
            version ? `&version=${version}` : ''
          }`;
          history.push(rediectedTo);
        } else {
          setIsLoading(res.isLoading);
          dispatch(
            storeLoaderData({
              isLoaderVisible: res.isLoading,
              options: lottiesOptions,
              theme: 'dark',
            }),
          );
          setError(res.error);
          updateActivity({
            sid: sid,
            transactionID: subscription.tid,
            planId: subscription.planId,
            event: EVENT.PAYMENT_SUCCESS,
            source: SOURCE.SUCCESS_PAGE,
            pageName: SOURCE.SUCCESS_PAGE,
            service: service,
            platform,
          });
          // set channel
          if (
            subscription.packDetails &&
            subscription.packDetails.benefits.type === PACK_TYPE.CHANNEL
          ) {
            setIsChannel(true);
          }
          setSubscriptionStatus(subscription);

          // Ref https://airteldigital.atlassian.net/browse/RG-2912
          if (isWebPlatform(platform) && service.toLowerCase() === 'airteltv') {
            const megaPlans = [6488, 6588];
            if (megaPlans.includes(subscription.planId)) {
              prEvent();
              const gtagSrc = document.createElement('script');
              gtagSrc.type = 'text/javascript';
              gtagSrc.async = true;
              gtagSrc.innerHTML =
                "gtag('event', 'conversion', {'send_to': 'AW-934825417/vOVUCKzFhpUDEMmb4b0D'});";
              document.body.appendChild(gtagSrc);
            }
          }
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (platform.toLowerCase() === PLATFORM.ANDROID && window.AirtelTVClient) {
      if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_ANDROID && !isServicePartner(service)) {
        //  window.AirtelTVClient.navigateToHome('homepage');
      } else {
        window.AirtelTVClient.onSubscriptionComplete(MOBILE_STATUS.SUCCESS);
      }
    } else if (platform.toLowerCase() === PLATFORM.IOS && window.webkit) {
      if (buildNo && Number(buildNo) < BUILD_NUMBER.XSTREAM_IOS && !isServicePartner(service)) {
        // window.webkit.messageHandlers.navigateToHome.postMessage({
        //   navigateToHome: 'homepage',
        // });
      } else {
        window.webkit.messageHandlers.onSubscriptionComplete.postMessage({
          onSubscriptionComplete: MOBILE_STATUS.SUCCESS,
        });
      }
    }
  }, [platform, buildNo]);

  const onBackButtonClick = (e) => {
    e.preventDefault();
    if (service?.toLowerCase() === SERVICE.MUSIC && window.location.href.includes('success')) {
      // window.history.back();
      onSuccess();
      return false;
    }
    if (window.location.href.includes('payment-option')) {
      window.history.forward();
      onSuccess();
      return false;
    }
  };

  useEffect(() => {
    if (service?.toLowerCase() === SERVICE.MUSIC) {
      window.history.pushState(null, null, window.location.href);
    }

    window.addEventListener('popstate', onBackButtonClick);

    return () => {
      window.removeEventListener('popstate', onBackButtonClick);
    };
  }, []);

  return (
    <div>
      {error && <Offline />}
      {!isLoading && !error && subscriptionStatus && (
        <>
          {(platform.toLowerCase() === PLATFORM.WEB ||
            !(isXtreamService(service) || isServicePartner(service))) && (
            <div className="screen-center-text">
              <React.Fragment>
                <div className="success-sign">
                  <Icon imgUrl={tick} />
                </div>
                <div className="success-content">
                  <p className="header">Payment successful</p>
                  {planId && <p className="title">Your subscription has started</p>}
                  {planId && subscriptionStatus?.validity && (
                    <div className="subtitle">
                      Valid till {dateTimeFormat.format(subscriptionStatus.validity)}
                    </div>
                  )}
                  <div className="subtitle" style={{ display: 'none' }}>
                    Transacrtion ID : {subscriptionStatus.tid}
                  </div>
                </div>
                <Button
                  text="Continue"
                  onClick={onSuccess}
                  theme={
                    subscription_theme[service]
                      ? service === 'rajtv'
                        ? (subscription_theme[service].theme_color = '#eeeeee')
                        : subscription_theme[service]
                      : subscription_theme.default
                  }
                />
              </React.Fragment>
            </div>
          )}

          {platform.toLowerCase() !== PLATFORM.WEB &&
            (isXtreamService(service) || isServicePartner(service)) && (
              <React.Fragment>
                {isChannel && (
                  <ChannelSuccess
                    service={service}
                    onSuccess={onSuccess}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
                {!isChannel && (
                  <ValuePackSuccess
                    service={service}
                    onSuccess={onSuccess}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              </React.Fragment>
            )}
        </>
      )}
    </div>
  );
};

Success.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      platform: PropTypes.string,
      sid: PropTypes.string,
    }),
  }),
};
