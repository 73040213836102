import React from 'react';
import PropTypes from 'prop-types';
import { getPackDurationDisplayData } from '../../utils/getDailyPackdata';
import './AppBar.scss';

export const AppBar = (props) => {
  const {
    leftTitle,
    subtitle,
    rightTitle,
    rightSubtitle,
    leftIcon,
    rightIcon,
    leftLogo,
    onLeftIconClick,
    onRightIconClick,
    shadow,
    planData,
  } = props;
  return (
    <div className={shadow ? 'app__bar box_shadow' : 'app__bar'}>
      <div onClick={onLeftIconClick} className="app__bar-left-heading">
        {leftIcon && <img className="left-icon" src={leftIcon} alt="#" />}
        <div className="title">
          {leftLogo && (
            <div className="logo">
              <img src={leftLogo} alt="#" />
            </div>
          )}
          <div className="text">
            <p className="label">{leftTitle}</p>
            {planData?.month && (
              <p className="description">{getPackDurationDisplayData(planData).combinedText}</p>
            )}
          </div>
        </div>
      </div>

      <div onClick={onRightIconClick} className="app__bar-right-heading">
        <p>{rightTitle}</p>
        <div className="app__bar-cancel">
          {rightIcon && <img src={rightIcon} alt="#" />}
          {rightSubtitle && <small>{rightSubtitle}</small>}
        </div>
      </div>
    </div>
  );
};

AppBar.defaultProps = {
  shadow: true,
};

AppBar.propTypes = {
  leftTitle: PropTypes.string,
  subtitle: PropTypes.string,
  rightTitle: PropTypes.string,
  rightSubtitle: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  leftLogo: PropTypes.string,
  onLeftIconClick: PropTypes.func,
  onRightIconClick: PropTypes.func,
  shadow: PropTypes.bool,
  planData: PropTypes.object,
};
