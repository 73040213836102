/* eslint-disable max-len */

import styled from 'styled-components';
import {rg_variables} from '../../styles/variables';

export const ButtonWrapper = styled.div.attrs({
  className: "button-wrapper"
})`
  .bottom-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: ${rg_variables.outline_white};
    position: relative;
    flex-direction: column;
    .header{
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      background: ${rg_variables.theme_black};
      color: ${rg_variables.outline_white};
      padding: 20px 16px 15px 16px;
      box-sizing: border-box;
      justify-content: space-between;
      .row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;
        font-size: ${rg_variables.font.medium};
        height: 45px; //RG-1833
        align-items: center; //RG-1833
        > div {
          display: flex;
          align-items: center;
        }
        .back_arrow{
          width: 18px;
          height: 20px;
          margin-right: 13px;
        }
        .green_offer{
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        .icon{
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        .price-text{
          font-size: 18px;
          color: ${rg_variables.outline_white};
        }
        .offer-text{
          text-align: right;
          color: ${rg_variables.outline_white};
          font-weight: normal;
        }
        .icon-text, .month-text, .month-price-text{
          color: ${rg_variables.white};
        }
        .month-price-text{
          font-size: ${rg_variables.font.extra_small};
        }
        .month{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
    .contents {
      width: calc(100% - 64px);
      text-align: center;
      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        margin-bottom: 28px;
      }

      .title {
        font-weight: 500;
        font-size: 18px;
        color: $black-1;
      }

      .subtitle {
        font-size: ${rg_variables.font.medium};
        color: ${rg_variables.black_1};
        margin-top: 8px;
      }

      .description {
        font-size: ${rg_variables.font.small};
        color: ${rg_variables.light_grey_1};
        margin-top: 16px;
      }
    }
    button {
      padding: 8px 16px;
      width: calc(100% - 64px);
      height: 48px;
      background: ${(props) => props.subscription_theme ? props.subscription_theme.bottom_modal.light.button_bg : rg_variables.theme_red};
      border-radius: 4px;
      border: none;
      outline: none;
      margin-top: 32px;
      font-weight: 500;
      font-size: ${rg_variables.font.medium};
      color: ${(props) => props.subscription_theme ? props.subscription_theme.bottom_modal.light.button_txt : rg_variables.outline_white};
      display: flex;
      align-items: center;
      justify-content: center;
      .chevron{
        transform: rotate(180deg);
      }
    }
    &.dark{
      background: ${rg_variables.black_1};
      .contents {
        width: calc(100% - 88px);
        .title {
          font-size: ${rg_variables.font.more_large};
          color: ${rg_variables.outline_white};
        }
        .subtitle {
          font-size: ${rg_variables.font.medium};
          color: ${rg_variables.white};
          margin-top: 16px;
        }
        .description {
          font-size: ${rg_variables.font.small};
          color: ${rg_variables.light_grey_2};
          margin-top: 32px;
        }
      }
      button {
        background: none;
        border: 1.5px solid ${(props) => props.subscription_theme ? props.subscription_theme.bottom_modal.dark.button_border : rg_variables.theme_red};
        position: ${rg_variables.position.abs};
        bottom: 32px;
        margin: 0;
        left: 32px;
        width: calc(100% - 64px);
        color: ${(props) => props.subscription_theme ? props.subscription_theme.bottom_modal.dark.button_txt : rg_variables.outline_white};
      }
    }
  }
`;
