import { useEffect } from 'react';

export const usePRLaunchEvent = () => {
  return () => {
        const gtagScript = document.createElement('script');
        gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-934825417";
        gtagScript.async = true;
        document.head.appendChild(gtagScript)
        const gtagSupport = document.createElement('script');
        gtagSupport.type = 'text/javascript';
        gtagSupport.async = true;
        gtagSupport.innerHTML = "window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());  gtag('config', 'AW-934825417');"
        document.head.appendChild(gtagSupport)
      return () => {
      }
    }
  };
  