import { CALL_API, STORE_SESSION, TOAST_DATA, LOADER_DATA, USER_DETAILS } from './type';
import { HTTP_METHOD, SESSION_OUT, INVALID_SESSION, SERVICE } from '../constants';
import { ACTIVE_PLANS, ACTIVE_TAB, ELIGIBLE_BUNDLES } from '../Pages/Subscription/common/constants';
import { useQueryParams } from '../hooks/useQueryParams';

/**
 * To call api's
 * @param {*} client
 * @param {*} method
 * @param {*} type
 * @param {*} url
 * @param {*} params
 */

export function callAPI(client, method, type, url, params = {}, includeResponseHeaders) {
  return {
    type: type || CALL_API,
    payload: {
      client,
      request: {
        method: method || HTTP_METHOD.GET,
        url: url,
        params: params.params || undefined,
        data: params.payload || {},
        headers: params.headers || null,
        transformResponse: [
          function (data, headers) {
            // Do whatever you want to transform the data
            if (
              data.error &&
              (data.errorCode === SESSION_OUT || data.errorCode === INVALID_SESSION)
            ) {
              const queryParams = useQueryParams();
              let queryString = '';
              for (const key in queryParams) {
                queryString += `${key}=${queryParams[key]}&`;
              }
              let hashLocation = window.location.hash.split('?')[0];
              if (hashLocation.endsWith('/')) {
                hashLocation = hashLocation.slice(0, -1);
              }
              const pathParams = hashLocation.split('/');
              const pathParamsLength = pathParams.length;
              const sid = pathParams[pathParamsLength - 2];
              const platform = pathParams[pathParamsLength - 1];
              const service = pathParams[1];
              const page = pathParams[2];
              if(service === SERVICE.MUSIC && page === 'manage-subscription' ) {
                throw new Error(data);
              }
              console.log('error in call api')
              window.location.href = `/#/txn-state/${params?.sessionOutVersion ? 'v2/': ''}session-out/${sid}/${platform}?${queryString}`;
            }
            if (includeResponseHeaders) {
              return { data, headers };
            }
            return data;
          },
        ],
      },
    },
  };
}

/**
 * To store user session
 * @param {*} session
 */
export const storeSession = (session, platform) => {
  return {
    type: STORE_SESSION,
    payload: { id: session, platform },
  };
};

/**
 * To store user Eligible Bundles
 * @param {*} session
 */
export const storeEligibleBundles = (benefits) => {
  return {
    type: ELIGIBLE_BUNDLES,
    payload: benefits,
  };
};

/**
 * To store user Active Plans
 * @param {*} session
 */
export const storeActivePlans = (plans) => {
  return {
    type: ACTIVE_PLANS,
    payload: plans,
  };
};

/**
 * To store Active Tab
 * @param {*} tab
 */
export const storeActiveTab = (tab) => {
  return {
    type: ACTIVE_TAB,
    payload: tab,
  };
};

/**
 * To store toast data
 * @param {*} data
 */

export const storeToastData = (data) => {
  return {
    type: TOAST_DATA,
    payload: data,
  };
};

/**
 * To store loader data
 * @param {*} info
 */
export const storeLoaderData = (info) => {
  return {
    type: LOADER_DATA,
    payload: info,
  };
};

/**
 * To store user details
 * @param {*} data
 */
export const storeUserDetails = (data) => {
  return {
    type: USER_DETAILS,
    payload: data,
  };
};
