/* while making a route, include :sid, :platform as last two parameters only,
if these params are required bcoz url pattern matching is used at some places wrt to sid & platform */

import { Switch, Route } from 'react-router-dom';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  Failure,
  Success,
  // ManageMusicSubscription,
  // MusicActivePlans,
  RegisterPage,
  RedirectPage,
  // BuySubscription,
  // ManageSubscription,
  PaymentTimer,
  // ManageRajtvSubscription,
  // BuyRajtvSubscription,
} from '../Pages';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store/configure';
// import ButtonLoader from '../Pages/NewPayments/common/components/ButtonLoader';
// import PaymentTimerScreen from '../Pages/NewPayments/PaymentTimerScreen';
// import TransactionStatus from '../Pages/NewPayments/TransactionStatus';
// import PaymentsWrapper from '../Pages/PaymentsWrapper';


const PaymentsWrapper = lazy(()=>import('../Pages/PaymentsWrapper'));
const TransactionStatus = lazy(()=>import('../Pages/NewPayments/TransactionStatus'));
const PaymentTimerScreen = lazy(()=>import('../Pages/NewPayments/PaymentTimerScreen'));

const BuySubscription = lazy(() => import('../Pages/Subscription/airteltv/screens/BuySubscription'));
const ManageSubscription = lazy(() => import('../Pages/Subscription/airteltv/screens/ManageSubscription'));
const MusicActivePlans = lazy(() => import('../Pages/Subscription/music/screens/MusicActivePlans'));
const ManageMusicSubscription = lazy(() => import('../Pages/Subscription/music/screens/ManageMusicSubscription'));
const ManageRajtvSubscription = lazy(() => import('../Pages/Subscription/rajtv/screens/ManageRajtvSubscription'));
const BuyRajtvSubscription = lazy(() => import('../Pages/Subscription/rajtv/screens/BuyRajtvSubscription'));
const PlansPage = lazy(() => import('../Pages/Subscription/music/screens/PlansPage'));


const GenericNotFound = () => <div style={{ color: '#fff' }}>Page not found</div>;

export const Navigator = () => {
  const [isActive, setIsActive] = useState(false);

  // useEffect(() => {
  //   window.addEventListener('offline', () => setIsActive(true));
  //   window.addEventListener('online', () => setIsActive(false));
  //   return () => {
  //     window.removeEventListener('offline', () => {});
  //     window.removeEventListener('online', () => {});
  //   };
  // }, []);
  return (
    <>
      <div>
        <ConnectedRouter history={history}>
          {/* <Suspense fallback={<ButtonLoader isFullScreenLoader />}>   */}
          <Suspense fallback={<div />}>  

          <Switch>
            <Route
              exact
              path="/airteltv/subscriptions/:sid/:platform"
              component={BuySubscription}
            />
            <Route exact path="/payment-option/:sid/:platform" component={PaymentsWrapper} />
            {/* <Route exact path="/payment-option/:sid/:platform" component={NewPaymentOption} /> */}
            {/* <Route exact path="/payment-option/:sid/:platform" component={PaymentOption} /> */}
            <Route exact path="/payment-timer/:sid/:platform" component={PaymentTimer} />
            <Route exact path="/payment-timer/:routeVersion/:sid/:platform" component={PaymentTimerScreen} />
            {/* <Route exact path="/payment-timer/v2/:sid/:platform" component={NewPaymentTimer} /> */}
            <Route
              exact
              path="/airteltv/manage-subscription/:sid/:platform"
              component={ManageSubscription}
            />
            <Route
              exact
              path="/music/manage-subscription/:sid/:platform"
              component={MusicActivePlans}
            />
            <Route
              exact
              path="/music/subscriptions/:sid/:platform"
              component={ManageMusicSubscription}
            />
            <Route
              exact
              path="/:servicePartner/subscriptions/:sid/:platform"
              component={BuyRajtvSubscription}
            />
            <Route
              exact
              path="/:servicePartner/manage-subscription/:sid/:platform"
              component={ManageRajtvSubscription}
            />
            {/* <Route
              exact
              path="/rajtv/manage-subscription/:sid/:platform"
              component={ManageRajtvSubscription}
            />
            <Route
              exact
              path="/rajtv/subscriptions/:sid/:platform"
              component={BuyRajtvSubscription}
            /> */}
            <Route exact path="/subscription/success/:sid/:platform" component={Success} />
            <Route exact path="/txn-state/:state/:sid/:platform" component={Failure} />
            <Route exact path="/txn-state/:routeVersion/:state/:sid/:platform" component={TransactionStatus} />
            <Route exact path="/music/backward/init" component={RedirectPage} />
            <Route exact path="/music/register" component={RegisterPage} />
            {false && <Route component={GenericNotFound} />}
          </Switch>
          </Suspense>
        </ConnectedRouter>
      </div>
      {/* {isActive && <NetConnectionOverlay closePopup={() => setIsActive(false)} />} */}
    </>
  );
};
