import { getActivityBaseUrl, getApiVersion } from "../../../../utils";

const version = getApiVersion();
export const API_ENTITY = {
  SUBSCRIPTION_LIST: `/wynk/${version}/plans/eligiblePlans/`,
  ACTIVE_SUBSCRIPTION_LIST: '/wynk/v1/plans/activePlans/',
  COMBINED_SUBSCRIPTION_LIST: `/wynk/${version}/plans/combinedPlans/`,
  SUBSCRIPTION_STATUS: '/wynk/v1/payment/status/',
  TRACK_ACTIVITY: `${getActivityBaseUrl()}/wynk/v1/track`,
  REDIRECTION_URL: '/wynk/c2s/v1/plans/manage',
  PAYU_MERCHANT_URL: `${process.env.REACT_APP_PAYU_MERCHANT_URL}/_payment`,
  PAYMENT_DATA_REQUIREMENT_MUSIC_URL: `${process.env.REACT_APP_MUSIC_PAYMENT_DATA_URL}/music/wcf/postPaymentData`,
  PAYMENT_DATA_REQUIREMENT_URL: `${process.env.REACT_APP_XSTREAM_PAYMENT_DATA_URL}/v2/user/payment/callback/session`,
  UNSUBSCRIBE_PLAN: `/wynk/v1/plan/unsubscribe/`,
  AIRTEL_RECHARGE_URL: `${process.env.REACT_APP_AIRTEL_HOME}/pay/`,
  AIRTEL_THANKS_URL: `${process.env.REACT_APP_AIRTEL_HOME}/pay/status`,
};
