import { useDispatch } from 'react-redux';
import { patchUnsubscribePlan } from '../actions';

export const useUnsubscribePlan = () => {
  const dispatch = useDispatch();
  return (obj) => {
    return dispatch(patchUnsubscribePlan(obj))
      .then((res) => {
        return { isLoading: false, error: null, unsubscribe: res.payload.data };
      })
      .catch((err) => {
        return { isLoading: false, error: err, unsubscribe: {} };
      });
  };
};
