import styled from 'styled-components';
import { rg_variables } from '../../../../styles/variables';
import { ellipsis } from '../../../../styles/mixins';

export const ChannelPackWrapper = styled.div.attrs({
  className: '',
})`
  .channel_pack_wrapper {
    &__header {
      position: relative;
      .bottom_gradient {
        background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, #2b2b2b 98.35%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 93px;
      }
      .slick-track {
        height: calc(100vh - 414px);
        @media only screen and (max-width: 768px) and (min-width: 430px) {
          height: calc(100vh - 59vh);
        }
      }
      .slick-dots {
        z-index: 4;
        bottom: 16px;
        display: flex !important;
        justify-content: flex-start;
        li {
          width: 4px;
        }
        li button::before {
          color: ${rg_variables.outline_white};
        }
        .slick-active button::before {
          color: #ffffff;
          opacity: 1;
        }
      }
      .image-box {
        height: calc(100vh - 414px);
        @media only screen and (max-width: 768px) and (min-width: 430px) {
          height: calc(100vh - 59vh);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__content {
      padding: 0 32px;
      background: linear-gradient(180deg, #2b2b2b 0.69%, #131313 100%);
      position: relative;
      .logo_box {
        position: ${rg_variables.position.abs};
        top: -36px;
        display: flex;
        left: 0;
        width: 100%;
        justify-content: center;
      }
      .tick_logo {
        display: flex;
        justify-content: center;
        padding: 8px 0;
        .image {
          height: 40px;
          width: 40px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .circular-logo {
        display: flex;
        justify-content: center;

        .logo-image {
          height: 72px;
          width: 72px;
          border-radius: 36px;
        }
        .double-tick {
          height: 24px;
          width: 24px;
          position: ${rg_variables.position.abs};
          right: calc(50% - 36px);
        }
      }

      &__heading {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        padding-bottom: 31px;
        padding-top: 50px;
        color: #ffffff;
      }
      &__heading2 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        padding-bottom: 20px;
        color: #ffffff;
        &.free {
          padding-bottom: 14px;
        }
      }
      &__subs {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        padding-bottom: 31px;
        color: ${rg_variables.outline_white};
      }
      &__channel_head {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 126.1%;
        color: ${rg_variables.outline_white};
        padding-bottom: 15px;
      }
      .validity {
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        color: #9e9e9e;
        padding-bottom: 12px;
        ${ellipsis()};
        &.free {
          font-size: 12px;
          width: 50%;
          // float: left;
        }
      }
      .transaction {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 126.1%;
        color: #9e9e9e;
        padding-bottom: 42px;
        ${ellipsis()};
      }
      button {
        display: flex;
        justify-content: center;
        border-radius: 8px;
        height: 48px;
        color: ${(props) => props.subs_theme.btnActiveTxt};
        cursor: pointer;
        background-color: ${(props) => props.subs_theme.theme_color};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        width: 100%;
        border: none;
        outline: none;
        align-items: center;
      }
      .btn-chevron {
        margin-left: 5px;
      }
    }
  }
`;
