module.exports = {
  wynk: {
    SUBSCRIPTION_BASE_URL: process.env.REACT_APP_SUBSCRIPTION_BASE_URL,
    PAYMENT_BASE_URL: process.env.REACT_APP_PAYMENT_BASE_URL,
    FE_BASE_URL: process.env.REACT_APP_FE_BASE_URL,
    ACTIVITY_TRACK: process.env.REACT_APP_ACTIVITY_TRACK,
  },
  iqvideo: {
    SUBSCRIPTION_BASE_URL: process.env.REACT_APP_IQVIDEO_SUBSCRIPTION_BASE_URL,
    PAYMENT_BASE_URL: process.env.REACT_APP_IQVIDEO_PAYMENT_BASE_URL,
    FE_BASE_URL: process.env.REACT_APP_IQVIDEO_FE_BASE_URL,
    ACTIVITY_TRACK: process.env.REACT_APP_IQVIDEO_ACTIVITY_TRACK,
    HOME_URL: process.env.REACT_APP_IQVIDEO_HOME_URL,
  },
  rajtv: {
    SUBSCRIPTION_BASE_URL: process.env.REACT_APP_RAJTV_SUBSCRIPTION_BASE_URL,
    PAYMENT_BASE_URL: process.env.REACT_APP_RAJTV_PAYMENT_BASE_URL,
    FE_BASE_URL: process.env.REACT_APP_RAJTV_FE_BASE_URL,
    ACTIVITY_TRACK: process.env.REACT_APP_RAJTV_ACTIVITY_TRACK,
    HOME_URL: process.env.REACT_APP_RAJTV_HOME_URL,
  },
  enterr10: {
    SUBSCRIPTION_BASE_URL: process.env.REACT_APP_ENTERR10_SUBSCRIPTION_BASE_URL,
    PAYMENT_BASE_URL: process.env.REACT_APP_ENTERR10_PAYMENT_BASE_URL,
    FE_BASE_URL: process.env.REACT_APP_ENTERR10_FE_BASE_URL,
    ACTIVITY_TRACK: process.env.REACT_APP_ENTERR10_ACTIVITY_TRACK,
    HOME_URL: process.env.REACT_APP_ENTERR10_HOME_URL,
  },
};