import { combineReducers } from 'redux';
import {
  selectedPlan,
  activeAccordian,
  activePlans,
  eligibleBenefits,
  activeTab,
  selectedOffer,
  routeInfo,
  freeTrialFlow,
  purchaseDefaultPlan,
} from '../Pages/Subscription/reducers';
import {
  savedOptionsData,
  phonePeInstalled,
  discountApplied,
  holdingScreenData,
  paymentPlanDetails,
  userInfo,
  selectedPaymentDetails,
} from '../Pages/Payments/reducer';
import {
  newSavedOptionsData,
  newPhonePeInstalled,
  newDiscountApplied,
  newHoldingScreenData,
  newPaymentPlanDetails,
  newUserInfo,
  newSelectedPaymentDetails,
  newPaymentOptionsDetails,
  newSavedPaymentDetails,
  newRecommendedOptions,
  selectedPaymentId,
  showSpinnerLoader,
  newCardVerificationDetail,
  newToastInfo,
  selectedUCB,
  showPaymentFlow,
  AlternateBillingDetails,
} from '../Pages/NewPayments/store/reducer';
import { userSession, toastData, loaderData, userDetails } from './global';
import { cardVerificationDetail } from '../Pages/Payments/reducer';
import { connectRouter } from 'connected-react-router';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    selectedPlan,
    activeAccordian,
    cardVerificationDetail,
    userSession,
    activePlans,
    eligibleBenefits,
    activeTab,
    selectedOffer,
    routeInfo,
    savedOptionsData,
    phonePeInstalled,
    userInfo,
    toastData,
    discountApplied,
    holdingScreenData,
    paymentPlanDetails,
    loaderData,
    userDetails,
    selectedPaymentDetails,
    // to be refactored
    newSavedOptionsData,
    newPhonePeInstalled,
    newDiscountApplied,
    newHoldingScreenData,
    newPaymentPlanDetails,
    newUserInfo,
    newSelectedPaymentDetails,
    newPaymentOptionsDetails,
    newSavedPaymentDetails,
    newRecommendedOptions,
    selectedPaymentId,
    showSpinnerLoader,
    newCardVerificationDetail,
    newToastInfo,
    selectedUCB,
    showPaymentFlow,
    freeTrialFlow,
    AlternateBillingDetails,
    purchaseDefaultPlan,
  });

export default createRootReducer;
