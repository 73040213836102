import { multiClientMiddleware } from 'redux-axios-middleware';
import axios from 'axios';
import { getPaymentBaseUrl, getSubscriptionBaseUrl } from '../utils';

const clients = {
  default: {
    client: axios.create({
      baseURL: getSubscriptionBaseUrl(),
      responseType: 'json',
    }),
  },
  payments: {
    client: axios.create({
      baseURL: getPaymentBaseUrl(),
      responseType: 'json',
    }),
  },
  invoice: {
    client: axios.create({
      baseURL: getPaymentBaseUrl(),
      responseType: 'blob',
    }),
  },
};

//to do rename api middleware
export const axiosMiddleware = multiClientMiddleware(clients, {});
