import {
  FETCH_PACKAGES,
  API_ENTITY,
  SELECTED_PLAN,
  ACTIVE_ACCORDIAN,
  FETCH_SUBSCRIPTION_STATUS,
  SELECTED_OFFER,
  UNSUBSCRIBE_PLAN,
  ROUTE_INFO,
  HAS_FREE_TRIAL,
  SET_DEFAULT_PLAN,
  SET_DEFAULT_OFFER,
} from '../constants';
import { callAPI } from '../../../../actions';
import { HTTP_METHOD } from '../../../../constants';

/**
 * To fetch subscription plans
 */
export const getSubscription = (sessionId) => {
  return callAPI(
    'default',
    HTTP_METHOD.GET,
    FETCH_PACKAGES,
    `${API_ENTITY.SUBSCRIPTION_LIST}${sessionId}`,
  );
};

/**
 * To fetch active subscriptions
 */
export const getActiveSubscriptions = (sessionId) => {
  return callAPI(
    'default',
    HTTP_METHOD.GET,
    FETCH_PACKAGES,
    `${API_ENTITY.ACTIVE_SUBSCRIPTION_LIST}${sessionId}`,
  );
};

/**
 * To fetch active subscriptions
 */
export const getCombinedSubscriptions = (sessionId) => {
  return callAPI(
    'default',
    HTTP_METHOD.GET,
    FETCH_PACKAGES,
    `${API_ENTITY.COMBINED_SUBSCRIPTION_LIST}${sessionId}`,
  );
};

/**
 * To fetch redirectional result
 */
export const getRedirectionResult = (headers, payload) => {
  return callAPI('default', HTTP_METHOD.POST, FETCH_PACKAGES, `${API_ENTITY.REDIRECTION_URL}`, {
    headers: headers,
    payload: payload,
  });
};

/**
 * To post required payment data
 */
export const postPaymentDataRequirement = (headers, payload) => {
  return callAPI(
    'default',
    HTTP_METHOD.POST,
    FETCH_PACKAGES,
    `${API_ENTITY.PAYMENT_DATA_REQUIREMENT_URL}`,
    {
      headers: headers,
      payload: payload,
    },
  );
};

/**
 * To post required payment data
 */
export const postMusicPaymentDataRequirement = (headers, payload) => {
  return callAPI(
    'default',
    HTTP_METHOD.POST,
    FETCH_PACKAGES,
    `${API_ENTITY.PAYMENT_DATA_REQUIREMENT_MUSIC_URL}`,
    {
      headers: headers,
      payload: payload,
    },
  );
};

/**
 * To fetch transaction status
 */
export const getSubscriptionStatus = (sessionId) => {
  return callAPI(
    'payments',
    HTTP_METHOD.GET,
    FETCH_SUBSCRIPTION_STATUS,
    `${API_ENTITY.SUBSCRIPTION_STATUS}${sessionId}`,
  );
};

/**
 * to select plan with plan number
 * @param {*} plan
 * @param {*} planNumber
 */
export const selectPlan = (plan, planNumber) => {
  return {
    type: SELECTED_PLAN,
    payload: { plan, planNumber },
  };
};

/**
 * to select offer
 * @param {*} offer
 */
export const selectOffer = (offer) => {
  return {
    type: SELECTED_OFFER,
    payload: offer,
  };
};

/**
 * To mark acordian active
 * @param {*} accordianNumber
 */
export const activeAccordian = (accordianNumber) => {
  return {
    type: ACTIVE_ACCORDIAN,
    payload: { accordianNumber },
  };
};

/**
 * To post the updated activity
 * @param {*} payload
 */
export const postActivity = (payload) => {
  return callAPI('default', HTTP_METHOD.POST, '', API_ENTITY.TRACK_ACTIVITY, {
    payload: payload,
  });
};

/**
 * To get the unsubscribe plan data
 */
export const patchUnsubscribePlan = ({ sessionId, planId }) => {
  return callAPI(
    'default',
    HTTP_METHOD.PATCH,
    UNSUBSCRIBE_PLAN,
    `${API_ENTITY.UNSUBSCRIBE_PLAN}${sessionId}`,
    { params: { planId } },
  );
};

/**
 * set basic routes info
 * @param {*} data
 */
export const routeInfo = (data) => {
  return {
    type: ROUTE_INFO,
    payload: data,
  };
};


/**
 * set free trial journey
 * @param {*} hasFreeTrial
 */
export const setFreeTrialFlow = ({hasFreeTrial}) => {
  return {
    type: HAS_FREE_TRIAL,
    payload: { hasFreeTrial },
  };
};

export const setDefaultPurchasePlan = (plan) => {
  return {
    type: SET_DEFAULT_PLAN,
    payload: {plan}
  }
}

export const setDefaultPurchaseOffer = (offer) => {
  return {
    type: SET_DEFAULT_OFFER,
    payload: {offer}
  }
}