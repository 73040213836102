export const DEFAULT = {
  PLACEHOLDER: {
    MONTH: 'MM',
    YEAR: 'YY',
    CARD_NUMBER: 'CARD NUMBER',
    CARD_NAME: 'CARD HOLDER NAME',
  },
};
export const CARD_SEPRATOR = 4;
export const CARD_VERIFY_AT = 6;

export const VERIFY_TYPE = {
  CARD: 'BIN',
  UPI: 'VPA',
};

export const PAYU = 'PAYU';
export const AIRTEL_PAYMENT_CODE = 'APB_GATEWAY';
export const AIRTEL_BANK = 'AIRNB';
export const PAYMENT_CLIENT = 'payments';
export const PAYU_CLIENT = 'payU';

export const PRODUCT_TYPE = {
  PLAN: 'PLAN',
  POINT: 'POINT',
};

export const IAP_CODES = {
  // GOOGLE_IAP: 'google_iap',
  ITUNES: 'ITUNES',
  GOOGLEPLAY: 'GOOGLEPLAY',
  AMAZONIAP: 'AMAZONIAP',
};

export const EXCLUDED_IAP_CODES = {
  GOOGLEPLAY: 'GOOGLEPLAY',
};
