export const PAYMENT_API = {
  VERIFY_CARD: '/wynk/v2/payment/verify',
  CHARGE: '/wynk/v1/payment/charge/',
  APPLY_COUPON: '/wynk/v1/coupon/apply/',
  PAYMENT_OPTIONS: '/wynk/v1/payment/options/',
  REMOVE_COUPON: '/wynk/v1/coupon/remove/',
  VERIFY_WALLET_NUMBER: '/wynk/v1/wallet/link/request/',
  VERIFY_OTP: '/wynk/v1/wallet/link/validate/',
  ADD_MONEY: '/wynk/v1/wallet/addMoney/',
  SAVED_OPTIONS: '/wynk/v1/payment/saved/details/',
  AFTER_ADD_MONEY_CALLBACK: '/wynk/v1/payment/callback/',
  UNLINK_WALLET_API: '/wynk/v1/wallet/unlink/request/',
};
