import React from 'react';
import { useActivityTracker, useQueryParams, useWebviewBackOrExit } from '../../hooks';
import './style.js';
import { RuntimeErrorScreenWrapper } from './style.js';
import { darkTheme } from '../../Pages/Subscription/music/Styles/theme';
import { EVENT } from '../../constants';
import PropTypes from 'prop-types';
import alert from '../../assets/icons/subPremium/alert.svg';

export const RuntimeError = () => {
  const { updateActivity } = useActivityTracker();
  const webViewBackOrExit = useWebviewBackOrExit();
  const query = useQueryParams();
  let theme = query.theme || 'dark';

  const onTryAgain = () => {
    updateActivity({
      event: EVENT.ERROR_RELOAD,
    });
    window.location.reload();
  };
  const onExit = () => {
    updateActivity({
      event: EVENT.ERROR_EXIT,
    });
    webViewBackOrExit({ preferBrowserBack: true });
  };

  return (
    <RuntimeErrorScreenWrapper theme={darkTheme}>
      <div className="h-screen w-screen justify-center items-center px-7 bg-bgSomething">
        <div className="flex w-full md:w-80 md:mx-auto h-full flex-col justify-center items-center">
          <img className="mb-4" src={alert} />
          <h3 className="text-lg font-bold leading-7 mb-1 text-white">Something went wrong</h3>
          <p className="text-base font-normal leading-6 text-white/50 mb-4">
            Please click on try again to refresh
          </p>
          <button
            onClick={onTryAgain}
            className="bg-btnWhiteGradient text-sm font-semibold py-3 text-center w-full rounded-lg mb-4 hover:opacity-80"
          >
            Try again
          </button>
          <button
            onClick={onExit}
            className="border border-white/30 rounded-lg bg-transparent text-white1 text-sm font-semibold py-3 text-center w-full mb-4 hover:opacity-80"
          >
            Exit
          </button>
        </div>
      </div>
    </RuntimeErrorScreenWrapper>
  );
};

RuntimeError.propTypes = {
  eventPayload: PropTypes.object,
  colorPalette: PropTypes.object,
};
