import { PLATFORM } from '../constants';
import { isWebPlatform } from '../utils';

export const useCancelBack = () => {
  return (platform) => {
    try {
      if (isWebPlatform(platform)) {
        window.history.back();
      }
    } catch (e) {
      return;
    }
  };
};
