import { TAB_TYPES } from '../common/constants';
export const defaultSelectedPlan = {
  plan: {},
  planNumber: '',
};

export const defaultSelectedOffer = {};
export const defaultActiveAccordian = { accordianNumber: 0 };
export const defaultEligibleBundles = [];
export const defaultActivePlans = { plans: [], comboPlans: [] };
export const defaultActiveTab = '';
export const defaultRouteInfo = {};
export const defaultFreeTrialJourney = { hasFreeTrial: false };
export const defaultPurchasePlan = { plan: null, offer: null };
