import React from 'react';
import { WithActivityTracker } from '../hoc/WithActivityTracker';
import { EVENT } from '../constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.props.updateActivity({
      event: EVENT.ERROR,
      errorMessage: error?.message,
      errorStack: error?.stack,
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export const ErrorBoundaryWithTracker = WithActivityTracker(ErrorBoundary);

