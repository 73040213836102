import { STORE_SESSION, TOAST_DATA, LOADER_DATA, USER_DETAILS } from '../actions/type';
import { defaultUserSession, defaultToastData, defaultLoaderState, defaultUserDetails } from './defaultState';
/**
 * To store user session into redux store
 * @param {*} state
 * @param {*} action
 */
export function userSession(state = defaultUserSession, action) {
  switch (action.type) {
    case STORE_SESSION: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store toast data into redux store
 * @param {*} state
 * @param {*} action
 */

export function toastData(state = defaultToastData, action) {
  switch (action.type) {
    case TOAST_DATA: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store loader data into redux store
 * @param {*} state
 * @param {*} action
 */

export function loaderData(state = defaultLoaderState, action) {
  switch (action.type) {
    case LOADER_DATA: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

/**
 * To store user details into redux store
 * @param {*} state
 * @param {*} action
 */

export function userDetails(state = defaultUserDetails, action) {
  switch (action.type) {
    case USER_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}
